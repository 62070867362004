import React, { Fragment, useState, useEffect, useContext, useRef } from "react";
import classes from './NumberInput.module.css'
import PrettyTextInput from "./PrettyTextInput"
import { formatToPoundNoRounding } from "../../../shared/utilities/Formatters";
import InfoModal from "../../../components/results/switches/InfoModal"

const NumberInput = (props) => {
    const [value, set_value] = useState(props.inputsRef.current[props.tracker])
    const [valid, set_valid] = useState('NA')
    const [show_modal, set_show_modal] = useState(false)

    const min_value = props.min_value ? props.min_value : 0

    function removeCommasAndPounds(inputString) {
        return inputString.replace(/[,£]/g, '');
    }
    
    const check_validity = (x) => {
        const num = Number(removeCommasAndPounds(x))
        if(num > 200000) {
            return [
                'error', 
                'This is too high'
            ]
        }
        if(num < min_value) {
            return(
                [
                    'error',
                    'This is too low'
                ]
            )
        }
        return [
            'valid', 
            ''
        ]
    }
    return(
        <div className = {classes.main_container}>
            <div className = {classes.title_container}> 
                <h2> {props.question_text}</h2>
            </div>
            <div className = {classes.input_container}> 
                <PrettyTextInput
                type = {props.need_currency ? 'currency' : 'number'}
                label = {props.label}
                value ={value}
                onChange = {
                    (e) => {
                        const resp = e.target.value
                        const is_valid = check_validity(resp)
                        set_valid(is_valid)
                        set_value(resp)

                        props.responsesRef.current = [props.tracker, is_valid[0]]

                        props.current_answer.current = props.need_currency ? formatToPoundNoRounding(resp || '') : (resp || '')
                        props.set_currently_being_answered(props.tracker)
                    }
                }
                showError = {valid[0] === 'error'}
                error = {valid[1]}
                left_margin = {'10px'}
                />
            </div>
            <div className = {classes.explain_container}> 
            {props.modal_content && <button
            onClick = {() => {set_show_modal(true)}}
            > Explain what this question means
            </button>
            }
            {show_modal && 
            <InfoModal
            modalHandler = {() => set_show_modal(false)}
            infoMessage = {props.modal_content}
            />
            }
            
            </div>
            {valid[0] === 'error' && <p className = {classes.error}> {valid[1]} </p>}
        </div>
    )
}

export default NumberInput