import React, {Fragment} from "react";
import { Link } from "react-router-dom";
import params from "../../shared/utilities/global_parameters";

const FrontPageQuestionSelectorText = [
  {
    id: "FAQs-Q0",
    question: 'Am I eligible for partial retirement?',
    answer: <Fragment> 
          <p>To be eligible for partial retirement you must:</p>
          <ul>
            <li>Have reached your minimum pension age.</li>
            <li>Be an active member of the Scheme.</li>
            <li>Reduce your actual pensionable pay by at least 10% for the 12 months starting from the date you take partial retirement. This can be achieved by:
              <ul>
                <li>A 10% reduction in pensionable pay based on your pensionable pay for the 12 months before you take partial retirement, or</li>
                <li>A change in your terms and conditions of employment to reflect your reduction in pensionable pay, with the rest of your terms and conditions staying the same. GP’s, Dental Practitioners, Ophthalmic and non-GP Providers need to reduce their commitment by 10%.</li>
              </ul>
            </li>
            <li>Expect your new level of pensionable pay to last for at least 12 months from the date you take partial retirement.</li>
            <li>Not have already claimed partial retirement on two occasions.</li>
          </ul>

          </Fragment>, 
        },
  {
    id: "FAQs-Q1",
    question: `What does this ${params.app_name_short} do?`,
    answer: (
      <p>The NHS (Scotland) Partial Retirement Calculator gives a basic illustration of projected pension benefits at partial retirement, including projected salary income and remaining pension available after partial retirement. This includes any future pension that you may build up whilst working for the NHS following your partial retirement.</p>
    ),
  },
  {
    id: "FAQs-Q2",
    question: `What information do I need to complete the ${params.app_name_short}?`,
    answer: (
      <div>
        <p>
        You need to know:
        </p>
        <ul>
        <li>the date you joined,</li>	 
        <li>the date you want to take partial retirement - within the next 12 months,</li>
        <li>any period(s) when you worked part time, </li> 
        <li>your current pensionable pay (the annual amount before tax), </li>	
        </ul>
        <p>
        You can find most of this information on your latest annual benefit 
        statement, so have this on hand. Your payslip will also be helpful
        to refer to. In most cases you can use an estimate if you don’t
        have all the figures asked for.
        </p>
      </div>
    ),
  },

  {
    id: "FAQs-Q4",
    question: "What is the McCloud judgement and how does it impact my partial retirement?",
    answer: (
      <Fragment> 
      <p>You can check if you’re affected by the McCloud ruling by visiting  <a href="https://pensions.gov.scot/2015-remedy">https://pensions.gov.scot/2015-remedy.</a></p>

      <p>Part of the McCloud remedy is that all affected members will be given a choice between taking their benefits for the remedy period from the 1995/2008 Section, or taking 2015 Scheme benefits instead. This calculator illustrates the comparison of these benefits over the remedy period based on a number of assumptions. The remedy period is from 1 April  2015 to 31 March 2022.</p>
      
      <p>Your McCloud choice means there are other factors to take into account when deciding what percentage of your pension to take at partial retirement, as it could affect your tax position, or your final salary link if you have one. Having a final salary link means that when you retire your 1995 Section benefits are based on your pensionable pay on or near retirement and not at the point you transitioned to the 2015 Scheme. If you later choose to take 2015 Scheme benefits for the remedy period, you may have overpayments to pay back.</p>
      
      <p>This calculator will show you an approximation of the ‘safe percentage’ – the percentage of pension you can take to potentially avoid having overpayments to repay when you make your McCloud choice later on.</p>
      </Fragment>
    ),
  },
  {
    id: "FAQs-Q5",
    question: "How do I apply for partial retirement?",
    answer: (
      <Fragment> 
        <p>If you wish to take partial retirement, you need to agree a new working arrangement that lets you reduce your pensionable pay by at least 10%. For example, you could change your level of responsibility, work fewer hours, or move to a new role. Speak with a member of your HR team to agree an arrangement that works for both you and your employer. </p>
    <p>When you are ready to apply, you’ll need to complete a retirement form
      called <a href = "https://pensions.gov.scot/nhs/employers/how-complete-retirement-application">(NHS:RET)</a>. 
        When you’ve completed the relevant sections of the forms you should give these to your employer to finalise their sections 
        and they’ll send your application to SPPA.</p>

      </Fragment>
    ),
  },

];

export default FrontPageQuestionSelectorText;
