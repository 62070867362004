import React, { useContext,  useEffect } from "react";
import { useNavigate } from "react-router-dom";

import { InputDataContext } from "../../shared/contexts/InputDataContext"; 
import { OutputDataContext } from "../../App";
import classes from "./CalculateButton.module.css";
import { processAddedYears } from "../../shared/utilities/Formatters";


const CalculateButton = () => {


  const navigate = useNavigate();
  const { inputsRef, from_backend, current_answer} = useContext(InputDataContext);

  const transformData = (data) => {
    const result = {};

    const retDates = from_backend.current['all_ret_dates'];


    // Loop through the object keys
    for (const date of ['2015-04-01','2022-04-01']) {
        
        result[date] = {};

        // Loop through the desired keys found in 'all_ret_dates'
        for (const key of retDates) {
            if (data[date][key] && data[date][key]['retirement_factor']) {
                result[date][key] = {
                    'retirement_factor': data[date][key]['retirement_factor'],
                    'salary' : data[date][key]['salary'],
                    'fs pension' : data[date][key]['fs pension'],
                    'care pension' : data[date][key]['care pension']
                };
                
            }
            
        }
        
    }
    return result;
};



  const handleSubmit = async (setting) => {
    let ret_factors = transformData(from_backend.current['all results'])
    console.log('all results going to addedstuff', from_backend.current['all results'])
    
    let address = (window.LOCAL_SETTING ? window.LOCAL_URL : window.HOST_URL) + "api/addedstuff" 
    let prd = from_backend.current.prd
    console.log('inputs ref going to backend', inputsRef.current)

    const ben_with = inputsRef.current.BenefitsWithdrawn.replace('%', '')
    const requestOptions = {
      method: "Post",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(
        { 
          
          LP : {
            '2015-04-01' : from_backend.current['all results']['2015-04-01'][prd]['fs pension'],
            '2022-04-01' : from_backend.current['all results']['2022-04-01'][prd]['fs pension']
          },
          CP : {
            '2015-04-01' : from_backend.current['all results']['2015-04-01'][prd]['care pension'],
            '2022-04-01' : from_backend.current['all results']['2022-04-01'][prd]['care pension']
          },
          'partial_retirement_percentage' : inputsRef.current.SafePercentage === 'No' ?
          //if ben_with is '' and we are not choosing the safe percentage, it must be
          //that onlylegacy is 'Yes', in which case ben_with won't be used, but we need to make
          //sure it does not cause an error when converted to a float 
          (ben_with === '' ? '0' : ben_with) : 
          from_backend.current['true safe percentage'] * 100, 
          'take_safe_percentage' : inputsRef.current.SafePercentage, 
          'salary_2015' : from_backend.current['all results']['salary_2015'], 
          'only_take_legacy' : inputsRef.current.OnlyLegacy
        }
      )
    }

    await fetch(
        address, requestOptions
    )
    .then((response) => response.json())
    .then((response) => {from_backend.current.lumpsum = response; 
      from_backend.current['results populated'] = false
    })

    const lumpsum = from_backend.current.lumpsum
    
    const secondAddress = (window.LOCAL_SETTING ? window.LOCAL_URL : window.HOST_URL) + "api/lumpsum" 

    const real_added_years = inputsRef.current.AddedYears === 'None' ? [0,0] : processAddedYears(inputsRef.current.AddedYears)
    var real_added_pension = inputsRef.current.AddedPension.replace('£', '').replace(',', '')
    real_added_pension = real_added_pension === 'None' ? 0 : Number(real_added_pension)

    const secondRequestOptions = {
      method: "Post",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(
        { 
          'partial_retirement_date' : prd,
          'added years' : real_added_years[0] + real_added_years[1]/365,
          'added pension' : real_added_pension,
          'all_ret_dates' : from_backend.current.all_ret_dates, 
          'chosen lump sum' : {'2015-04-01' : 0, '2022-04-01' : 0}, //default values
          '2015-04-01' : {
            ...ret_factors['2015-04-01'],
            'user wants added years' : false, 
            'user wants added pension' : false, //default values
            'fs pension' : from_backend.current['all results']['2015-04-01'][prd]['fs pension'],
            'care pension' : from_backend.current['all results']['2015-04-01'][prd]['care pension'],
            'LP perc' : lumpsum['2015-04-01']['LP perc'],
            'CP perc' : lumpsum['2015-04-01']['CP perc']
          }, 
          '2022-04-01' : {
            ...ret_factors['2022-04-01'],
            'user wants added years' : false, 
            'user wants added pension' : false, //default values
            'fs pension' : from_backend.current['all results']['2022-04-01'][prd]['fs pension'],
            'care pension' : from_backend.current['all results']['2022-04-01'][prd]['care pension'],
            'LP perc' : lumpsum['2022-04-01']['LP perc'],
            'CP perc' : lumpsum['2022-04-01']['CP perc'],
            
          }, 
          'salary at pr' : from_backend.current['all results']['2015-04-01'][prd]['salary'], //will be the same regardless of scenario
          'scheme' : inputsRef.current.Status, 
          'salary_2015' : from_backend.current['all results']['salary_2015']

        }
      )
    }

    await fetch(
      secondAddress, secondRequestOptions
  )
  .then((response) => response.json())
  .then((response) => {from_backend.current.final_outputs = response})
    
  if(setting === 'navigate') {

    navigate("/results")
    
  }
  };

 from_backend.current.handle_submit_function = handleSubmit

  return (
    <button className={classes.pagebutton} onClick={
      () => {
            //if we subsequently navigate back to the inputs page, 
            //we need to know not to update inputsRef the first time we click
            //'Submit answer' without having edited the response
            current_answer.current = 'incomplete'
            if (from_backend.current['results populated']) {
              handleSubmit('navigate'); // execute function immediately
          } else {
              setTimeout(() => {
                handleSubmit('navigate'); // execute the function after waiting for 500ms
              }, 450);
          }
        }
      }>
      Calculate Benefits
    </button>
  );
};

export default CalculateButton;
