import React, { Fragment, useContext, useEffect, useState, useLayoutEffect, useRef } from "react";

import HSTable from "../components/results/HSTable";
import FancyToggle from "../components/results/switches/FancyToggle"


import classes from "./3-Results.module.css";
import classes_comp_table from "../components/results/ComparisonTable.module.css"

import { InputDataContext } from "../shared/contexts/InputDataContext";

import {formatDate, processAddedYears} from "../shared/utilities/Formatters"
import poundHand from "../assets/images/pound_hand.png"
import poundLogo from "../assets/images/pound_circle.png"
import salaryImg from "../assets/images/salary_image.png"


import {
  BarElement,
  Title,
  Tooltip,
  Legend,
  SubTitle,
} from "chart.js"; 

import { Bar } from 'react-chartjs-2';
import { Chart, BarController, LineController, CategoryScale, LinearScale, PointElement, LineElement, ScatterController } from 'chart.js';
import annotationPlugin from 'chartjs-plugin-annotation';



import IncomeChart from "../components/results/switches/IncomeChart"
import ChartModal from "../components/results/switches/ChartModal"
import InfoModal from "../components/results/switches/InfoModal"
import ChartModalScenarioView from "../components/results/switches/ChartModalScenarioView"
import ChartModalIncomeView from "../components/results/switches/ChartModalIncomeView"



Chart.register(BarController, LineController, CategoryScale, LinearScale, PointElement, LineElement, ScatterController, annotationPlugin, BarElement, Title, Tooltip, Legend, SubTitle);



function ResultsPage() {
  const show_original_hs_table = false
  const show_summary_table = false
  const show_charts = false
  const include_images = false
  const show_calculations = false
  const [show_modal, set_show_modal] = useState('')
  const [info_modal, set_info_modal] = useState('')
  const [chart_hovered, set_chart_hovered] = useState(false)
  const [scenario, set_scenario] = useState('2015-04-01')
  const [variable_view, set_variable_view] = useState('income view')
  const { inputsRef, from_backend, currently_being_answered_ref} = useContext(InputDataContext);
  const [chosen_year, set_chosen_year] = useState(1)
  const [force_rerender, set_force_rerender] = useState(false)
  const [chosen_date, set_chosen_date] = useState(from_backend.current['all_ret_dates'][chosen_year])
  const care_slider_ref = useRef(from_backend.current.final_outputs['pr stuff']['2015-04-01']['actual LS'])
  const legacy_slider_ref = useRef(from_backend.current.final_outputs['pr stuff']['2022-04-01']['actual LS'])
  const use_added_years_ref = useRef({
    'care': Boolean(from_backend.current.final_outputs['pr stuff']['2015-04-01']['added years used']),
    'legacy' : Boolean(from_backend.current.final_outputs['pr stuff']['2022-04-01']['added years used'])
  })
  const use_added_pension_ref = useRef({
    'care': Boolean(from_backend.current.final_outputs['pr stuff']['2015-04-01']['added pension used']),
    'legacy' : Boolean(from_backend.current.final_outputs['pr stuff']['2022-04-01']['added pension used'])
  })
  const allowed_added_years_ref = useRef({
    'care' : Boolean(from_backend.current.lumpsum['2015-04-01']['added years']),
    'legacy' : Boolean(from_backend.current.lumpsum['2022-04-01']['added years'])
  })

  const allowed_added_pen_ref = useRef({
    'care' : Boolean(from_backend.current.lumpsum['2015-04-01']['added pension']),
    'legacy' : Boolean(from_backend.current.lumpsum['2022-04-01']['added pension'])
  })

  const [wobble_fr, set_wobble_fr] = useState(false)

  useEffect(
    () => set_wobble_fr(!wobble_fr),
    [chosen_year]
  )

  useEffect(() => {
    // Set the body margin to 0px when the ResultsPage component mounts
    document.body.style.width = '80%';

    // Cleanup function to reset the body margin when the component unmounts or user navigates away
    return () => {
      document.body.style.width = '75%'; // Reset to the default margin or desired value
    };
  }, []);

  
  const initialMountActual = useRef(true)

  //when we go back from the results page to the your details page, 
  //we don't want anything to have the 'editing' status
  currently_being_answered_ref.current = ''

  const real_added_years = inputsRef.current.AddedYears === 'None' ? [0,0] : processAddedYears(inputsRef.current.AddedYears)

  var real_added_pension = inputsRef.current.AddedPension.replace('£', '').replace(',', '')
  real_added_pension = real_added_pension === 'None' ? 0 : Number(real_added_pension)
  const inputs = {
    AddedYears : real_added_years[0],
    'AddedDays' : real_added_years[1], 
    'AddedPen' : real_added_pension, 
    'schemeName' : inputsRef.current.Status, 
    take_safe_percentage : inputsRef.current.SafePercentage, 
    '2015Pen' : Number(inputsRef.current.Salary2015.replace('£', '').replace(',', '')), 
    BenefitWithdrawn : inputsRef.current.BenefitsWithdrawn.replace('%', ''), 
    NewSal : Number(inputsRef.current.FutureSalary.replace('£', '').replace(',', '')), 
    postReshapingPT : Number(inputsRef.current.PostPT.replace('%', '')), 
    CurrentPen : Number(inputsRef.current.CurrentSalary.replace('£', '').replace(',', '')), 
    HasPT : (inputsRef.current.PT === "None" || inputsRef.current.PT.length === 0) ? "No" : "Yes",
    partTimeService : from_backend.current.cleaned_pt, 
    only_take_legacy : inputsRef.current.OnlyLegacy,
    take_safe_percentage : inputsRef.current.SafePercentage


  } 
  


const modalHandler = () => set_show_modal('')

const handleSubmit = async (choose_reformed_lump_sum,choose_legacy_lump_sum, use_added_pension_care, use_added_pension_legacy, 
  use_added_years_care, use_added_years_legacy,
  isMounted) => {
  let address = (window.LOCAL_SETTING ? window.LOCAL_URL : window.HOST_URL) + "api/lumpsum" 
  let prd = from_backend.current.prd
  let lumpsum = from_backend.current.lumpsum

  const transformData = (data) => {
    const result = {};

    const retDates = from_backend.current['all_ret_dates'];

    // Loop through the object keys
    for (const date of ['2015-04-01','2022-04-01']) {
        
        result[date] = {};

        // Loop through the desired keys found in 'all_ret_dates'
        for (const key of retDates) {
            if (data[date][key] && data[date][key]['retirement_factor']) {
                result[date][key] = {
                    'retirement_factor': data[date][key]['retirement_factor'],
                    'salary' : data[date][key]['salary'],
                    'fs pension' : data[date][key]['fs pension'],
                    'care pension' : data[date][key]['care pension']
                };
            }
        }
        
    }
    return result;
};

let ret_factors = transformData(from_backend.current['all results'])

    const RequestOptions = {
      method: "Post",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(
        { 
          'partial_retirement_date' : prd,
          'added years' : Number(inputs.AddedYears) + Number(inputs.AddedDays)/365,
          'added pension' : inputs.AddedPen,
          'all_ret_dates' : from_backend.current.all_ret_dates, 
          'chosen lump sum' : {'2015-04-01' : choose_reformed_lump_sum, '2022-04-01' : choose_legacy_lump_sum}, 
          '2015-04-01' : {
            ...ret_factors['2015-04-01'],
            'user wants added years' : use_added_years_care, 
            'user wants added pension' : use_added_pension_care, //default values
            'fs pension' : from_backend.current['all results']['2015-04-01'][prd]['fs pension'],
            'care pension' : from_backend.current['all results']['2015-04-01'][prd]['care pension'],
            'LP perc' : lumpsum['2015-04-01']['LP perc'],
            'CP perc' : lumpsum['2015-04-01']['CP perc']
          }, 
          '2022-04-01' : {
            ...ret_factors['2022-04-01'],
            'user wants added years' : use_added_years_legacy, 
            'user wants added pension' : use_added_pension_legacy, //default values
            'fs pension' : from_backend.current['all results']['2022-04-01'][prd]['fs pension'],
            'care pension' : from_backend.current['all results']['2022-04-01'][prd]['care pension'],
            'LP perc' : lumpsum['2022-04-01']['LP perc'],
            'CP perc' : lumpsum['2022-04-01']['CP perc'],
            
          }, 
          'salary at pr' : from_backend.current['all results']['2015-04-01'][prd]['salary'], //will be the same regardless of scenario
          'scheme' : inputs.schemeName
          

        }
      )
    }

    await fetch(
      address, RequestOptions
  )
  .then((response) => response.json())
  .then((response) => {
    if (isMounted) {
      
        from_backend.current.final_outputs = response;

        
        //Here, we are getting the standard and maximum lump sum amounts for different scenarios, 
        //as well as the actual lump sum the user is taking. Note that we can't rely on the user's input
        //as the sole source of truth about their lump sum amount, because they could have toggled their
        //added years inputs in a way that makes their previous lump sum input invalid.
        //Moreover, we adjust the actual, standard and max lump sum to constrain them very slightly so
        //that they fit on our slider (i.e., the gap is a multiple of 300)
        const both_pr_stuff = from_backend.current.final_outputs['pr stuff']
        const max_ls_choose_care = both_pr_stuff['2015-04-01']['max LS']
        const max_ls_choose_legacy = both_pr_stuff['2022-04-01']['max LS']
        const standard_ls_choose_care = both_pr_stuff['2015-04-01']['standard LS']
        const standard_ls_choose_legacy = both_pr_stuff['2022-04-01']['standard LS'] 

        const legacy_ls_range = Math.floor(Number(max_ls_choose_legacy)) - Math.ceil(standard_ls_choose_legacy)
        const legacy_ls_step = Math.floor(Number(legacy_ls_range) / 300)
        const adjusted_standard_ls_choose_legacy = Math.ceil(Number(standard_ls_choose_legacy))
        const adjusted_max_ls_choose_legacy = (legacy_ls_step * 300 + adjusted_standard_ls_choose_legacy) 
        const care_ls_range =  Math.floor(Number(max_ls_choose_care)) - Math.ceil(Number(standard_ls_choose_care))
        const care_ls_step = Math.floor(Number(care_ls_range) / 300)

        const adjusted_standard_ls_choose_care = Math.ceil(Number(standard_ls_choose_care))
        const adjusted_max_ls_choose_care = (care_ls_step * 300 + adjusted_standard_ls_choose_care) 

        care_slider_ref.current = Math.min(
          Math.max(Number(response['pr stuff']['2015-04-01']['actual LS']), adjusted_standard_ls_choose_care),
            adjusted_max_ls_choose_care
        )
        legacy_slider_ref.current = Math.min(
          Math.max(response['pr stuff']['2022-04-01']['actual LS'], adjusted_standard_ls_choose_legacy),
          adjusted_max_ls_choose_legacy
        )

        adjusted_ls_ref.current.reformed_standard = adjusted_standard_ls_choose_care
        adjusted_ls_ref.current.reformed_max = adjusted_max_ls_choose_care
        adjusted_ls_ref.current.legacy_standard = adjusted_standard_ls_choose_legacy
        adjusted_ls_ref.current.legacy_max = adjusted_max_ls_choose_legacy


        set_force_rerender((prev) => !prev);
    }
})

  return 'arbitrary'
}


  useEffect(() => {set_chosen_date(from_backend.current['all_ret_dates'][chosen_year])}, [chosen_year])
  const [cssVars, setCssVars] = useState(null);
  useEffect(() => {//this is for the purposes of some of the animations, where we need to no whether or not we are within 3 seconds of the initial mount
    if (cssVars) {  // Only proceed if cssVars is populated
      const timerId2 = setTimeout(() => {
        initialMountActual.current = false; 
        
      }, 3000)
      
      return () => clearTimeout(timerId2);
    } 
  }, [cssVars]); 

  useLayoutEffect(() => {
    const getCSSVariableValue = (variableName) => {
      return getComputedStyle(document.body).getPropertyValue(variableName).trim();
    }

    const body_colours = {
      'main-color': getCSSVariableValue('--main-color'),
      'main-color-light': getCSSVariableValue('--main-color-light'),
      'gad-teal': getCSSVariableValue('--gad-teal'),
      'light-grey': getCSSVariableValue('--light-grey'),
      'full-legacy': getCSSVariableValue('--full-legacy'),
      'full-legacy-light': getCSSVariableValue('--full-legacy-light'),
      'legacy-curtailed': getCSSVariableValue('--legacy-curtailed'),
      'legacy-curtailed-light' : getCSSVariableValue('--legacy-curtailed-light'),
      'CARE': getCSSVariableValue('--CARE'),
      'CARE-light': getCSSVariableValue('--CARE-light'),
      'CARE-super-light': getCSSVariableValue('--CARE-super-light'),
      'main-color-super-light': getCSSVariableValue('--main-color-super-light')
    };

    setCssVars(body_colours);
  }, []);



  function round10(n) {
        return Math.round(n / 10) * 10;
      }

  const n_years = from_backend.current['all_ret_dates'].length
  const possible_years = Array.from({ length: n_years-1 }, (_, i) => i + 1);
  

  const revaluation_factor = Number(from_backend.current.final_outputs['normal pension increase'])
  const salary_increase = Number(from_backend.current.final_outputs['normal salary increase'])
  const rectangle_colour = 'rgba(255, 99, 132, 0.10)'
  const prd_results = from_backend.current['all results'][scenario][from_backend.current['prd']]
  const care_pesnion_at_prd_if_fully_retire_on_prd = prd_results['care pension']
  const future_results = from_backend.current['all results'][scenario][chosen_date]
  const safe_perc = from_backend.current['safe percentage']
  const chosen_pr_percentage = inputs.take_safe_percentage === 'Yes' ? (Number(safe_perc)*100) : Number(inputs.BenefitWithdrawn)
  

  let derivation_of_pt = JSON.parse(prd_results['derivation of pt prop projection'])
  let sum_calendar = derivation_of_pt.reduce((acc, curr) => {
    return acc + curr.relevant_calendar_days;
}, 0);
let sum_worked = derivation_of_pt.reduce((acc, curr) => {
  return acc + curr.relevant_worked_days;
}, 0);
let applicable_prop = JSON.parse(prd_results['pt prop projection for double accrual calc'])

let double_and_single_accrual_table = JSON.parse(prd_results['service_to_retirement check'])
let future_double_and_single_accrual_table = JSON.parse(future_results['service_to_retirement check'])
let single_accrual = prd_results['projected service single']
let double_accrual = prd_results['projected service double']
let future_single_accrual = future_results['projected service single']
let future_double_accrual = future_results['projected service double']
let actual_service = prd_results['actual service']
const actual_service_by_apr_2015 = from_backend.current['all results']['2015-04-01'][from_backend.current['prd']]['actual service']
const actual_service_by_apr_2022 = from_backend.current['all results']['2022-04-01'][from_backend.current['prd']]['actual service']
let future_actual_service = future_results['actual service']
const fs_pension = prd_results['fs pension']

let future_fs_pension = future_results['fs pension']
let care_pension = Number(prd_results['care pension'])
let future_care_pension = future_results['care pension']
let salary = prd_results['salary']
let lp_perc = from_backend.current.lumpsum[scenario]['LP perc']
let care_perc = from_backend.current.lumpsum[scenario]['CP perc']
let care_perc_reformed = from_backend.current.lumpsum['2015-04-01']['CP perc']
let care_perc_legacy = from_backend.current.lumpsum['2022-04-01']['CP perc']


const user_can_use_added_pen_reformed = from_backend.current.lumpsum['2015-04-01']

let adjusted_accrual_rate = inputs.schemeName === 'Mental Health Officer' ? ((single_accrual * (1/80)) + (double_accrual * (1/40)))/(single_accrual + double_accrual) : 1/80
let future_adjusted_accrual_rate = inputs.schemeName === 'Mental Health Officer' ? ((future_single_accrual * (1/80)) + (future_double_accrual * (1/40)))/(future_single_accrual + future_double_accrual) : 1/80

let pr_details = from_backend.current.final_outputs['pr stuff'][scenario]
let annual_details = from_backend.current.final_outputs['annual stuff'][scenario][chosen_date]
let many_annual_details = from_backend.current.final_outputs['annual stuff'][scenario]
let many_annual_details_both_scens = from_backend.current.final_outputs['annual stuff']

const salary_uplift = Number(from_backend.current.final_outputs['salary uplift'])
const pension_uplift = Number(from_backend.current.final_outputs['pension uplift'])
const pension_increase = Number(from_backend.current.final_outputs['normal pension increase'])


let future_salary = annual_details['salary']

let added_pension = pr_details['added pension used']
let added_years = pr_details['added years used']
const available_added_years = Number(inputs.AddedYears) + Number(inputs.AddedDays/365)


let pr_legacy_erf = pr_details['ERF legacy']
let pr_legacy_erf_ls = pr_details['ERF legacy ls']
let pr_care_erf = pr_details['ERF care']
let pr_added_pension_erf = pr_details['ERF added pension']
let fr_legacy_erf = annual_details['ERF legacy']
let fr_legacy_erf_ls = annual_details['ERF legacy ls']
let fr_care_erf = annual_details['ERF care']
let fr_added_pension_erf = annual_details['ERF added pension']
let standard_ls = pr_details['standard LS']
let max_ls = pr_details['max LS']
let both_pr_stuff = from_backend.current.final_outputs['pr stuff']
//the two below both mean 'before ERF and before applying the pr percentage''
const fs_pension_choose_legacy = from_backend.current['all results']['2022-04-01'][from_backend.current['prd']]['fs pension']
const care_pension_choose_legacy = from_backend.current['all results']['2022-04-01'][from_backend.current['prd']]['care pension']
let max_ls_choose_care = both_pr_stuff['2015-04-01']['max LS']
let max_ls_choose_legacy = both_pr_stuff['2022-04-01']['max LS']
let standard_ls_choose_care = both_pr_stuff['2015-04-01']['standard LS']
let standard_ls_choose_legacy = both_pr_stuff['2022-04-01']['standard LS']
let actual_ls = pr_details['actual LS']
let actual_ls_choose_reformed = from_backend.current.final_outputs['pr stuff']['2015-04-01']['actual LS']
let actual_ls_choose_legacy = from_backend.current.final_outputs['pr stuff']['2022-04-01']['actual LS']
let commuted_from_legacy = pr_details['commuted from legacy']
let commuted_from_care = pr_details['commuted from care']
let locally_calculated_standard_ls = ((3 * fs_pension * lp_perc) + (added_years * salary * 0.0125 * 3)) * pr_legacy_erf_ls
let locally_calculated_max_ls = standard_ls + (fs_pension * 0.1964 * 12 * lp_perc * pr_legacy_erf) + (care_pension * pr_care_erf * care_perc *
  0.3571 * 12)
let locally_calculated_commuted_from_legacy = Math.min((actual_ls - standard_ls) / 12, 0.1964 * fs_pension * lp_perc *pr_legacy_erf)
let locally_calculated_commuted_from_care = ((actual_ls - standard_ls) - (commuted_from_legacy * 12)) / 12 



const legacy_ls_range = Math.floor(Number(max_ls_choose_legacy)) - Math.ceil(standard_ls_choose_legacy)
const legacy_ls_step = Math.floor(Number(legacy_ls_range) / 300)
const adjusted_standard_ls_choose_legacy = Math.ceil(Number(standard_ls_choose_legacy))
const adjusted_max_ls_choose_legacy = (legacy_ls_step * 300 + adjusted_standard_ls_choose_legacy) 
const care_ls_range =  Math.floor(Number(max_ls_choose_care)) - Math.ceil(Number(standard_ls_choose_care))
const care_ls_step = Math.floor(Number(care_ls_range) / 300)

const adjusted_standard_ls_choose_care = Math.ceil(Number(standard_ls_choose_care))
const adjusted_max_ls_choose_care = (care_ls_step * 300 + adjusted_standard_ls_choose_care) 

care_slider_ref.current = Math.min(
  Math.max(Number(both_pr_stuff['2015-04-01']['actual LS']), adjusted_standard_ls_choose_care),
    adjusted_max_ls_choose_care
)
legacy_slider_ref.current = Math.min(
  Math.max(both_pr_stuff['2022-04-01']['actual LS'], adjusted_standard_ls_choose_legacy),
  adjusted_max_ls_choose_legacy
)

const adjusted_ls_ref = useRef({
  'reformed_standard' : adjusted_standard_ls_choose_care,
  'reformed_max' : adjusted_max_ls_choose_care,
  'legacy_standard' : adjusted_standard_ls_choose_legacy,
  'legacy_max' : adjusted_max_ls_choose_legacy
})

  // If cssVars is not yet populated, don't render the main content.
  if (!cssVars) {
    return null;  // Or some loading indicator if you wish.
  }

const pr_colour = cssVars['main-color-super-light']
const fr_colour = cssVars['main-color']//'rgba(20,20,255,0.20)'

let lp_at_pr = pr_details['LP at partial retirement']
let cp_at_pr = pr_details['CP at partial retirement']
let lp_at_pr_choose_reformed = from_backend.current.final_outputs['pr stuff']['2015-04-01']['LP at partial retirement']
let lp_at_pr_choose_legacy = from_backend.current.final_outputs['pr stuff']['2022-04-01']['LP at partial retirement']
let cp_at_pr_choose_reformed = from_backend.current.final_outputs['pr stuff']['2015-04-01']['CP at partial retirement']
let cp_at_pr_choose_legacy = from_backend.current.final_outputs['pr stuff']['2022-04-01']['CP at partial retirement']
let actual_lp_at_fr = annual_details['LP']
let actual_ls_at_fr = annual_details['LS']
let actual_cp_at_fr = annual_details['CP']


let locally_calculated_lp_at_pr = (fs_pension * pr_legacy_erf * lp_perc - commuted_from_legacy) + (added_years * salary * 0.0125 * pr_legacy_erf)
let locally_calculated_cp_at_pr = (care_pension * pr_care_erf * care_perc - commuted_from_care) + (added_pension * pr_added_pension_erf)

let added_years_at_full_retirement = Number(available_added_years) - Number(added_years)
let added_pension_at_full_retirement = inputs.AddedPen - added_pension

let locally_calculated_future_lp = (future_fs_pension * 
  (1 - lp_perc) + added_years_at_full_retirement * future_salary * 0.0125) * fr_legacy_erf

let locally_calculated_lump_sum_at_fr = ((actual_lp_at_fr * 3) /fr_legacy_erf) * fr_legacy_erf_ls

const locally_calculated_revalued_pr_care = care_pension * care_perc *  Math.pow(1.015, Number(chosen_year))
const revalued_pr_care = annual_details['revalued pr care']

let locally_calculated_cp_at_fr = (future_care_pension - locally_calculated_revalued_pr_care) * fr_care_erf  + (added_pension_at_full_retirement * fr_added_pension_erf)

let actual_results_table = JSON.parse(from_backend.current['both salaries'][scenario]) 

let first_year_index = from_backend.current['first year indexes'][scenario]
let legacy_first_year_index = from_backend.current['first year indexes']['2022-04-01']
let care_first_year_index = from_backend.current['first year indexes']['2015-04-01']

const both_actual_results_tables = {
  '2015-04-01': JSON.parse(from_backend.current['both salaries']['2015-04-01']).filter((val, index) => {
    return index >= care_first_year_index && index <= care_first_year_index + (n_years - 2);
  }),
  '2022-04-01': JSON.parse(from_backend.current['both salaries']['2022-04-01']).filter((val, index) => {
    return index >= legacy_first_year_index && index <= legacy_first_year_index + (n_years - 2);
  })
}

const scen_key = {
  '2015-04-01' : 'care',
  '2022-04-01' : 'legacy'
}
const added_pension_at_pr_benefit = Number(inputs.AddedPen) * Number(pr_added_pension_erf) * (use_added_pension_ref.current[scen_key[scenario]] ? 1 : 0)
const added_years_at_pr_benefit = salary * 0.0125 * Number(available_added_years) * pr_legacy_erf * (use_added_years_ref.current[scen_key[scenario]] ? 1 : 0)

const used_pr_percentage = inputs.only_take_legacy === 'Yes' ? Math.round((fs_pension_choose_legacy / (care_pension_choose_legacy + fs_pension_choose_legacy))*100,2) : chosen_pr_percentage


function near(a, b, tol = 10) {
  return Math.abs(a - b) <= tol;
}

function formatToPound(number) {
  return new Intl.NumberFormat('en-GB', {
      style: 'currency',
      currency: 'GBP',
      minimumFractionDigits: 0
  }).format(round10(number));
}

function deepClone(obj, hash = new WeakMap()) {
  if (Object(obj) !== obj) return obj; // primitives
  if (hash.has(obj)) return hash.get(obj); // cyclic reference
  let result;
  if (obj instanceof Array) {
      result = [];
      hash.set(obj, result);
      for (let i = 0; i < obj.length; i++) {
          result[i] = deepClone(obj[i], hash);
      }
  } else if (obj instanceof Function) {
      result = obj; // Copy the reference to the function (don't clone)
  } else if (obj instanceof Object) {
      result = {};
      hash.set(obj, result);
      for (let key in obj) {
          if (obj.hasOwnProperty(key)) {
              result[key] = deepClone(obj[key], hash);
          }
      }
  } else {
      result = obj;
  }
  return result;
}


const no_care_choose_legacy = Number(cp_at_pr_choose_legacy) === 0
const no_care_choose_reformed = Number(cp_at_pr_choose_reformed) === 0

const no_legacy_choose_legacy = Number(many_annual_details_both_scens['2022-04-01'][chosen_date]['LP']) === 0

const no_legacy_choose_reformed = Number(many_annual_details_both_scens['2015-04-01'][chosen_date]['LP']) === 0


// Constants for our loop
const SCENARIOS = {
  '2015-04-01': cssVars['legacy-curtailed'],
  '2022-04-01': cssVars['full-legacy']
};


const VARIABLES = ['LS', 'CP', 'LP', 'actual_pay']; // Add more variables as needed
const scenario_variables = ['LS','CP','LP','Full Pension']

const variable_key = {'LS' : 'Lump Sum', 'CP' : 'CARE Pension', 'LP' : 'Legacy Pension',
'LP from partial retirement' : 'Legacy Pension from partial retirement', 'CP from partial retirement' : 'CARE pension from partial retirement',
'LS from partial retirement' : 'Lump Sum from partial retirement',
 'Full Pension' : 'Total pension', 'actual_pay' : 'Actual Pay', '2015-04-01' :'Choose Reformed', '2022-04-01' : 'Choose legacy', 'At partial retirement' : 'At partial retirement', 
 'Pensionable pay at partial retirement' : 'Pensionable pay at partial retirement'}

 const datalabel_key = {'LS' : 'Lump Sum received if you fully retire at start of this year', 'CP' : 'CARE Pension you start receiving if you fully retire this year',
  'LP' : 'Legacy Pension you start receiving if you fully retire this year',
'LP from partial retirement' : 'Legacy Pension received from partial retirement onwards', 'CP from partial retirement' : 'CARE pension received from partial retirement onwards',
'LS from partial retirement' : 'Lump Sum received when you partially retire',
 'Full Pension' : 'CARE and Legacy Pension combined', 'actual_pay' : 'Pay received this year if not yet fully retired'}

 const datalabel_income_key = {'LS' : 'Lump Sum upon full retirement', 'CP' : 'CARE Pension received from full retirement onwards',
 'LP' : 'Legacy Pension received from full retirement onwards',
'LP from partial retirement' : 'Legacy Pension received from partial retirement onwards', 'CP from partial retirement' : 'CARE pension received from partial retirement onwards',
'LS from partial retirement' : 'Lump Sum received when you partially retire',
'Full Pension' : 'CARE and Legacy Pension combined', 'actual_pay' : 'Pay received from continuing employment during partial retirement'}

const variable_colours = {'LS' : cssVars['main-color'], 
'CP' : cssVars['full-legacy'], 'LP' : cssVars['legacy-curtailed'], 'actual_pay' : cssVars['CARE'],  'LS at partial retirement' : cssVars['main-color-light'], 
'CP at partial retirement' : cssVars['full-legacy-light'], 'LP at partial retirement' : cssVars['legacy-curtailed-light'], 
'Pensionable pay at partial retirement' : cssVars['CARE-light']}
const scenario_colours = {
  '2022-04-01' : cssVars['legacy-curtailed'], '2015-04-01' : cssVars['full-legacy'], 'At partial retirement' : rectangle_colour, 
  'choose care light' : cssVars['full-legacy-light'], 'choose legacy light' : cssVars['legacy-curtailed-light']
}
// Extracting dates 
const dates = from_backend.current['all_ret_dates'].slice(1)
let x_axis = Array.from({ length: n_years }, (_, i) => (i).toString())
x_axis = [' ', '0', '', ...x_axis.slice(1)];

var scenarioBasedData = {}
var datasets
for (let variable of scenario_variables) {

  if(variable == 'Full Pension') {
    datasets = [
      {'label' : 'Legacy', 'data' : dates.map(date => many_annual_details_both_scens['2022-04-01'][date]['CP']+many_annual_details_both_scens['2022-04-01'][date]['LP']),
       backgroundColor : scenario_colours['2022-04-01']},
       {'label' : 'CARE', 'data' : dates.map(date => many_annual_details_both_scens['2015-04-01'][date]['CP']+many_annual_details_both_scens['2015-04-01'][date]['LP']),
       backgroundColor : scenario_colours['2015-04-01']}
    ]
  } else {
    datasets = [
      {'label' : 'Legacy', 'data' : dates.map(date => many_annual_details_both_scens['2022-04-01'][date][variable]), backgroundColor : scenario_colours['2022-04-01']},
      {'label' : 'CARE', 'data' : dates.map(date => many_annual_details_both_scens['2015-04-01'][date][variable]), backgroundColor :scenario_colours['2015-04-01']}
    ]
  }

  datasets[0]['data'].unshift(null, null, null)//.unshift(null, actual_ls_choose_legacy, null)
  datasets[1]['data'].unshift(null, null, null)//.unshift(null, actual_ls_choose_reformed, null)

// Structuring data for Scenario-based view
scenarioBasedData[variable] = {
  labels: x_axis,
  datasets: datasets, 
  
};

}

// Structuring data for Variable-based view
const variableBasedData = {
  '2015-04-01' : {
    labels: x_axis,
    datasets: []
  },
  '2022-04-01' : {
    labels: x_axis,
    datasets: []
  }
}

const pr_datapoints  = {
  '2015-04-01' : {
    'LS' : actual_ls_choose_reformed,
    'LP' : lp_at_pr_choose_reformed * pension_uplift,
    'CP' : cp_at_pr_choose_reformed * pension_uplift, 
    'actual_pay' : null,
    'Full Pension' : (cp_at_pr_choose_reformed + lp_at_pr_choose_reformed) * pension_uplift
  },
  '2022-04-01' : {
    'LS' : actual_ls_choose_legacy,
    'LP' : lp_at_pr_choose_legacy * pension_uplift,
    'CP' : cp_at_pr_choose_legacy * pension_uplift,
    'actual_pay' : null,
    'Full Pension' : (cp_at_pr_choose_legacy + lp_at_pr_choose_legacy) * pension_uplift
  }
 }

 const simple_array = Array.from({ length: n_years - 1 }, (_, i) => i)
// Loop to fill in datasets
for (let scen in SCENARIOS) {
  
  VARIABLES.forEach(variable => {
    if(['CP', 'LS', 'LP'].includes(variable)) {
      var this_data = dates.map(date => many_annual_details_both_scens[scen][date][variable])
      this_data.unshift(null, null, null) 

    } else {
      var this_data = both_actual_results_tables[scen].map(val => Number(val[variable]) * salary_uplift)
      this_data.unshift(null, inputs.NewSal * inputs.postReshapingPT * 0.01 * salary_uplift, null) 
    }
   
    
    

    const datasetForScenario = {
      data: this_data,
      backgroundColor: variable_colours[variable],
      stack : ['CP','LP'].includes(variable) ? 'combined' : null
    };

    if (variable !== 'actual_pay') {
      const datasetForPR = {
        label : variable + ' from partial retirement',
        data : (
          variable === 'LS'? 
          [null].concat(pr_datapoints[scen][variable]).concat(new Array(n_years).fill(null)) :
          [null].concat(pr_datapoints[scen][variable]).concat([null]).concat(simple_array.map((_,i) => Math.pow(revaluation_factor, i+1) * pr_datapoints[scen][variable] )) 
          ), 
          backgroundColor : variable_colours[variable + ' at partial retirement'],
          'stack' : variable === 'LS' ? null : 'combined2'
      }
      variableBasedData[scen].datasets.push(datasetForPR);
    }

    if(variable === 'actual_pay') {
      datasetForScenario['stack'] = 'combined2'
    }
  
    
    const datasetForVariable = {
      ...datasetForScenario,
      label: variable
    };

    variableBasedData[scen].datasets.push(datasetForVariable);
  });
  /*
  variableBasedData[scen].datasets.push(
    {
      label : 'Pensionable pay at partial retirement', 
      data : [null].concat(salary).concat(new Array(9).fill(null)),
      backgroundColor: variable_colours['Pensionable pay at partial retirement'],
      stack : 'dont_stack_me'
    }
  )*/
}



function format_x(x) {
  if(x === '0') {
    return 'Year starting at the partial retirement date, i.e., \nFrom ' + formatDate(from_backend.current['prd']) + '\nTo ' + formatDate(from_backend.current['all_ret_dates'][1])
  } else if(Number(x) === 0) {
    return null
  } else if (Number(x) < (n_years - 1)) {
    return 'Year starting ' + x + ' years afer the partial retirement date, i.e., \nFrom: ' + formatDate(from_backend.current['all_ret_dates'][Number(x)]) + '\nTo: ' + formatDate(from_backend.current['all_ret_dates'][Number(x) + 1])
  } else {
    return 'Year starting ' + x + ' years afer the partial retirement date, i.e., \nFrom: ' + from_backend.current['all_ret_dates'][(n_years - 1)] + '\nTo: ' + from_backend.current.final_outputs['last date']
  }
}

function format_colour(colour, type) {
  if(type === 'income') {
    return datalabel_income_key[colour]
  } else {
  return datalabel_key[colour]
  }
}


const chartOptions = {
  
  animation: {
    duration: 0
  },
  plugins: {
    legend: {
      display: false,
    },
    title: {
      display: true,
      text: ['Choose Reformed'],
      align: 'start',
      font: {
        size: 30,
        color: 'black'
      }
    },
    annotation: {
      annotations: [{
          type: 'box',
          xScaleID: 'x',
          yScaleID: 'y',
          xMin: ' ',
          xMax: '',
          yMin: 0,
          backgroundColor: rectangle_colour
      }]
  },
  tooltip: {
    //position: 'bottom',
    callbacks: {
      title: function(context) {
        const xValue = context[0].label;
        return format_x(xValue);
      },
      label: function(context) {
        const dataset = context.dataset;
        const colourLabel = dataset.label;
        const yValue = dataset.data[context.dataIndex];
        return `${format_colour(colourLabel, 'variable')}: ${formatToPound(yValue)}`;
      }
    }, 
    bodyFont: {
      size: 16  // Set the font size for the body of the tooltip
    },
    titleFont: {
      size: 20  // Set the font size for the title of the tooltip
    },
    footerFont: {
      size: 16  // Set the font size for the footer of the tooltip, if any
    }
  }
  },
  scales: {
    y: {
      min: 0,
      id : 'y',
      stacked: true,
      grid: {
        display: false
      },
      title: {  // Use title instead of scaleLabel
        display: false,
        text: 'Value',
        font: {
          size: 24  // Adjust this value for the x-axis title font size
        }, 
        align: 'start'
      },
      ticks: {
        callback: function(value, index, values) {
          return '£' + value.toLocaleString();
        },
        font: {
          size: 20  // Adjust this value for the y-axis tick labels' font size
        }
      },
    },
    x: {
      id : 'x',
      grid: {
        display: false
      },
      title: {  // Use title instead of scaleLabel
        display: true,
        text: 'Years after partial retirement',
        font: {
          size: 24  // Adjust this value for the x-axis title font size
        }, 
        align: 'start'
      },
      ticks: {
        font: {
          size: 20  // Adjust this value for the x-axis tick labels' font size
        }
      }
    }
  },
  maintainAspectRatio: false, 
};


const chartOptions2 = deepClone(chartOptions);


chartOptions2.plugins.title.text[0] = 'Choose Legacy'

const fr_variable_colours = {
  'Lump Sum at full retirement' : variable_colours['LS'], 
  'CARE Pension at full retirement' : variable_colours['CP'],
  'Legacy Pension at full retirement' : variable_colours['LP'],
  'Annual pay while partially retired' : variable_colours['actual_pay']
}

const pr_variable_colours = {
  'Lump Sum at partial retirement' : variable_colours['LS at partial retirement'], 
  'CARE Pension at partial retirement' : variable_colours['CP at partial retirement'],
  'Legacy Pension at partial retirement' : variable_colours['LP at partial retirement'],
  //'FTE pay in partial retirmeent year' : variable_colours['Pensionable pay at partial retirement']
}

const VariableLegend2 = () => {
  return (
    <div className = {classes.variable_legend}>
     <div className = {classes.variable_legend_body}> 
      <div className = {classes.pr_variable_legend} style = {{'--value' : rectangle_colour}}> 
        {Object.entries(pr_variable_colours).map(([key, value]) => (
            <div key={key} style={{ display: 'flex', alignItems: 'center', marginRight: '20px', 
            'transform' : 'translateX(25%) translateY(10px)' }}>
              <div style={{ width: '20px', height: '20px', backgroundColor: value, marginRight: '10px'
              
            }}>
          </div>
          <span>{key}</span>
        </div>
      ))}
        </div>
        <div className = {classes.fr_variable_legend}> 
        {Object.entries(fr_variable_colours).map(([key, value]) => (
        <div key={key} style={{ display: 'flex', alignItems: 'center', marginRight: '20px', 
        'transform' : 'translateX(25%)' }}>
          <div style={{ width: '20px', height: '20px', backgroundColor: value, marginRight: '10px'
        }}></div>
          <span>{key}</span>
        </div>
      ))}
      </div>
        </div>
        
    </div>


      
    
  );
}

const InputChanger = React.memo(() => {
  
  const [legacy_slider_manual_input, set_legacy_slider_manual_input] = useState(legacy_slider_ref.current)
  const [care_slider_manual_input, set_care_slider_manual_input] = useState(care_slider_ref.current)
  const [use_added_pension_care, set_use_added_pension_care] = useState(use_added_pension_ref.current['care'])
  const [use_added_pension_legacy, set_use_added_pension_legacy] = useState(use_added_pension_ref.current['legacy'])
  const [use_added_years_legacy, set_use_added_years_legacy] = useState(use_added_years_ref.current['legacy'])
  const [use_added_years_care, set_use_added_years_care] = useState(use_added_years_ref.current['care'])
  const [choose_reformed_lump_sum, set_choose_reformed_lump_sum] = useState(care_slider_ref.current)
  const [choose_legacy_lump_sum, set_choose_legacy_lump_sum] = useState(legacy_slider_ref.current)
  const legacy_ls_range = Math.floor(Number(max_ls_choose_legacy)) - Math.ceil(standard_ls_choose_legacy)
  const legacy_ls_step = Math.floor(Number(legacy_ls_range) / 300)
  //The max and min lump sum values taken directly from the backend will not fit perfectly on the slider.
  //So we adjust the max down slightly, and adjust the min up slightly. This adjustment needs to be applied in the 
  //ranges shown on the slider, and to the values that we allow the slider state variables to take.
  const adjusted_standard_ls_choose_legacy = Math.ceil(Number(standard_ls_choose_legacy))
  const adjusted_max_ls_choose_legacy = (legacy_ls_step * 300 + adjusted_standard_ls_choose_legacy) 
  const care_ls_range =  Math.floor(Number(max_ls_choose_care)) - Math.ceil(Number(standard_ls_choose_care))
  const care_ls_step = Math.floor(Number(care_ls_range) / 300)
  const adjusted_standard_ls_choose_care = Math.ceil(Number(standard_ls_choose_care))
  const adjusted_max_ls_choose_care = (care_ls_step * 300 + adjusted_standard_ls_choose_care) 
  const allow_backend_call =useRef(false)
  const [input_focus_legacy_slider, set_input_focus_legacy_slider] = useState(false)
  const [input_focus_care_slider, set_input_focus_care_slider] = useState(false)
  const [input_hovered, set_input_hovered] = useState('')
  const [legacy_slider, set_legacy_slider] = useState(
    Math.min(
      Math.max(Number(legacy_slider_ref.current), adjusted_standard_ls_choose_legacy),
      adjusted_max_ls_choose_legacy
    )
    )
  const [care_slider, set_care_slider] = useState(
    Math.min(
      Math.max(Number(care_slider_ref.current), adjusted_standard_ls_choose_care),
        adjusted_max_ls_choose_care
    )
  )
  const formatted_legacy_slider = formatToPound(Number(legacy_slider).toFixed(0))
  const formatted_care_slider = formatToPound(Number(care_slider).toFixed(0))

  useEffect(() => {
    let isMounted = true;  // flag to track component mount status


    if(allow_backend_call.current) {
    const fetchData = async () => {
        await handleSubmit(
          care_slider_ref.current, legacy_slider_ref.current, use_added_pension_ref.current['care'], use_added_pension_ref.current['legacy'],
          use_added_years_ref.current['care'], use_added_years_ref.current['legacy'],
           isMounted
          );
    }
    fetchData();
    allow_backend_call.current = false
    

  }
  
    
  
    return () => {
        isMounted = false;
    };
  }, [choose_reformed_lump_sum, choose_legacy_lump_sum, use_added_pension_care, use_added_pension_legacy, use_added_years_care, use_added_years_legacy]);
 
  return(
  <div className = {classes.input_box}> 
  <div className = {classes.input_titles}> 
  <h2 style = {{'textAlign' : 'center', 'width' : '100%'}}> Settings in the 'choose 1995 Section' scenario </h2>
  <h2 style = {{'textAlign' : 'center', 'width' : '100%'}}> Settings in the 'choose 2015 Scheme' scenario </h2>
  </div>
  <div className = {classes.input_body}>
    <div className = {classes.legacy_inputs}> 
      {(inputs.AddedPen > 0) && <div className = {classes.added_pension}>
        <h3 > Use Added pension? </h3>
        <div className = {classes.added_pension_toggle_container
      + ' ' + (!allowed_added_pen_ref.current['legacy'] ? classes.forbidden_toggle : '')  
      }
      style = {{'position' : 'relative'}}
        >
          {!allowed_added_pen_ref.current['legacy'] && 
            <Fragment> 
            <div style = {{'position' : 'absolute', 'minWidth' : '100%','minHeight' : '100%',
        'background' : 'transparent', zIndex: 1
        }}
          onMouseEnter={() => set_input_hovered('added pension legacy')}
          onMouseLeave={() => {if(input_hovered === 'added pension legacy') {set_input_hovered('')} }}
        >  </div>
        {input_hovered === 'added pension legacy' && <p style = {{'position' : 'absolute', top: '-500px', width: '300px',
        'padding' : '30px', 'zIndex' : '2', 'background' : 'var(--main-color)', 'color' : 'white',
        'borderRadius' : '10px'
      }}
        > You can't choose whether to use added pension at partial retirement because you are projected to withdraw {care_perc_legacy < 0.5 ? 'none of' : 'all of'} your CARE benefits at partial retirement in the
        'choose 1995 Section' scenario. This means that you must take {care_perc_legacy < 0.5 ? 'none of' : 'all of'} your added pension at partial retirement in this scenario. To un-grey this option, 
        you would need to choose a {care_perc_legacy < 0.5 ? 'higher ' : 'lower '} partial retirement percentage on the 'Your Details' page.
          </p> }
        </Fragment>
        }
        <p className = {(!use_added_pension_legacy ? classes.input_toggle_active : '') + ' ' + classes.input_toggle} > No </p>
            <FancyToggle state = {use_added_pension_legacy} 
            handleChange = {
              () => {
                if(!allowed_added_pen_ref.current['legacy']) {
                  return
                }
                use_added_pension_ref.current['legacy'] = !use_added_pension_ref.current['legacy']
                allow_backend_call.current = true
                set_use_added_pension_legacy(prev => !prev)
              }
            }
            active_colour = {
              allowed_added_pen_ref.current['legacy'] ? cssVars['main-color'] : 'lightgrey'
            } 
              inactive_colour = {
                allowed_added_pen_ref.current['legacy'] ? cssVars['main-color-light']: 'lightgrey'
              }
            /> 
            <p className = {(use_added_pension_legacy ? classes.input_toggle_active : '') + ' ' + classes.input_toggle} >Yes</p>
        </div>
      </div>
}
        {(available_added_years > 0) && <div className = {classes.added_pension}>
          <h3 > Use Added Years? </h3>
          <div className = {classes.added_pension_toggle_container
          + ' ' + (!allowed_added_years_ref.current['legacy'] ? classes.forbidden_toggle : '')  
          }
          style = {{'position' : 'relative'}}
          >
            {!allowed_added_years_ref.current['legacy'] && 
            <Fragment> 
            <div style = {{'position' : 'absolute', 'minWidth' : '100%','minHeight' : '100%',
        'background' : 'transparent', zIndex: '1'
        }}
          onMouseEnter={() => set_input_hovered('added years legacy')}
          onMouseLeave={() => {if(input_hovered === 'added years legacy') {set_input_hovered('')} }}
        >  </div>
        {input_hovered === 'added years legacy' && <p style = {{'position' : 'absolute', top: '-500px', width: '300px',
        'padding' : '30px', 'zIndex' : '2', 'background' : 'var(--main-color)', 'color' : 'white',
        'borderRadius' : '10px'
      }}
        > You can't choose whether to use added years at partial retirement because you are projected to withdraw all your legacy benefits at partial retirement in the 'choose legacy' scenario.
          This means that you must take all your added years at partial retirement. To un-grey this option, you would need to choose a lower partial retirement percentage
          on the 'Your Details' page.
          </p> }
        </Fragment>
        }
          <p className = {(!use_added_years_legacy ? classes.input_toggle_active : '') + ' ' + classes.input_toggle} > No </p>
             <FancyToggle state = {use_added_years_legacy} 
             handleChange = {
              () => {
                if(!allowed_added_years_ref.current['legacy']) {
                  return
                }
                use_added_years_ref.current['legacy'] = !use_added_years_ref.current['legacy']
                allow_backend_call.current = true
                set_use_added_years_legacy(prev => !prev)
              }
            }
            active_colour = {
              allowed_added_years_ref.current['legacy'] ? cssVars['main-color'] : 'lightgrey'
            } 
              inactive_colour = {
                allowed_added_years_ref.current['legacy'] ? cssVars['main-color-light'] : 'lightgrey'
              }
            />
             <p className = {(use_added_years_legacy ? classes.input_toggle_active : '') + ' ' + classes.input_toggle} > Yes</p>
           </div>
      </div> }
      <div className = {classes.added_pension} style = {{'flexDirection' : 'column', 'justifyContent' : 'center'}}>
        <div style = {{'display' : 'flex', 'flex' : 2, 'justifyContent' : 'flexStart', 'alignItems' : 'center'
      }}>
        <h2 className = {classes.slider_label} > Lump sum at partial retirement </h2>  
          
        </div>
        <div className = {classes.added_pension_toggle_container}>
            <p> {formatToPound(Number(adjusted_standard_ls_choose_legacy).toFixed(0))} </p>
            <div style={{ position: 'relative' }}>
        <input 
        id = 'legacy_slider'
        value={legacy_slider} 
        type='range' 
        onChange={(e) => {
          
            legacy_slider_ref.current = e.target.value;
            set_legacy_slider(e.target.value);
            set_legacy_slider_manual_input(e.target.value)
        }}
        max={Number(adjusted_max_ls_choose_legacy)} 
        min={Number(adjusted_standard_ls_choose_legacy)}
        step={legacy_ls_step}
        onMouseUp={() => {
          allow_backend_call.current = true
          set_choose_legacy_lump_sum(legacy_slider)
        }
        }
    />
    <input 
        type="text"
        value={input_focus_legacy_slider ? Number(legacy_slider_manual_input).toFixed(0) : formatted_legacy_slider}
        onChange={(e) => {
          let val = Number(e.target.value)
          if(isNaN(val)) {
            return
          }
          val = val.toFixed(0)
            set_legacy_slider_manual_input(val)
            if(val >= adjusted_standard_ls_choose_legacy && val <= adjusted_max_ls_choose_legacy) {
              legacy_slider_ref.current = val
              set_legacy_slider(val)
              allow_backend_call.current = true
              set_choose_legacy_lump_sum(val)
            }
        }}
        onFocus={() => set_input_focus_legacy_slider(true)}
        onBlur={() => 
          {set_input_focus_legacy_slider(false)
          if(legacy_slider_manual_input !== legacy_slider) {
            set_legacy_slider_manual_input(legacy_slider)
          }
          }
        }
        style={{
            position: 'absolute',
            left: String(((Number(legacy_slider) - Number(standard_ls_choose_legacy)) / legacy_ls_step) * 0.94 + (5 - (180 / 2))) + 'px',
            top: '30px',
            //pointerEvents: 'none',
            height: '40px',
            borderRadius: '10px',
            border: '5px solid ' + cssVars['main-color'],
            width: "180px",
            textAlign: 'center',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            fontSize: '20px'
        }}
    />

</div>
             <p> {formatToPound(Number(adjusted_max_ls_choose_legacy).toFixed(0))} </p>
             
          </div>
      </div>
    </div>

    <div className = {classes.care_inputs}> 
      {(inputs.AddedPen > 0) && <div className = {classes.added_pension}>
        <h3 > Use Added pension? </h3>
        <div className = {classes.added_pension_toggle_container
        + ' ' + (!allowed_added_pen_ref.current['care'] ? classes.forbidden_toggle : '')  }
        style = {{'position' : 'relative'}}
        >
          {!allowed_added_pen_ref.current['care'] && 
          <Fragment> 
          <div style = {{'position' : 'absolute', 'minWidth' : '100%','minHeight' : '100%',
          'background' : 'transparent', zIndex: 1
          }}
          onMouseEnter={() => set_input_hovered('added pension care')}
          onMouseLeave={() => {if(input_hovered === 'added pension care') {set_input_hovered('')} }}
          >  
          </div>
          {input_hovered === 'added pension care' && 
          <p style = {{'position' : 'absolute', top: '-500px', width: '300px',
          'padding' : '30px', 'zIndex' : '2', 'background' : 'var(--main-color)', 'color' : 'white',
          'borderRadius' : '10px'}}
          > 
          You can't choose whether to use added pension at partial retirement because you are projected to withdraw {care_perc_reformed > 0.5 ? 'all of' : 'none of'} your CARE benefits at partial retirement in the
          'choose reformed' scenario. This means that you must take {care_perc_reformed > 0.5 ? 'all of' : 'none of'} your added pension at partial retirement in this scenario. To un-grey this option, 
          you would need to choose a {care_perc_reformed < 0.5 ? 'higher ' : 'lower '} partial retirement percentage on the 'Your Details' page.
        </p> }
       </Fragment>
        }

        <p className = {(!use_added_pension_care ? classes.input_toggle_active : '') + ' ' + classes.input_toggle} >No</p>
        <FancyToggle state = {use_added_pension_care} 
            handleChange = {
              () => {
                if(!allowed_added_pen_ref.current['care']) {return}
                use_added_pension_ref.current['care'] = !use_added_pension_ref.current['care']
                allow_backend_call.current = true
                set_use_added_pension_care(prev => !prev)
              }
            }
            active_colour = {
              allowed_added_pen_ref.current['care'] ? cssVars['main-color'] : 'lightgrey'
            } 
              inactive_colour = {
                allowed_added_pen_ref.current['care'] ? cssVars['main-color-light'] : 'lightgrey'
              }
        /> 
            <p className = {(use_added_pension_care ? classes.input_toggle_active : '') + ' ' + classes.input_toggle} >Yes</p>
            <button className = {classes.input_changer_button}
            onClick = {() => set_info_modal('Added Pension')}
            > Help with added pension </button>
      </div>
    </div>}
      {(available_added_years > 0) && <div className = {classes.added_pension}>
          <h3 > Use Added Years? </h3>
          <div className = {classes.added_pension_toggle_container + ' ' + (!allowed_added_years_ref.current['care'] ? classes.forbidden_toggle : '')  
          }
          style = {{'position' : 'relative'}}
          >
            {!allowed_added_years_ref.current['care'] && 
            <Fragment> 
            <div style = {{'position' : 'absolute', 'minWidth' : '100%','minHeight' : '100%',
        'background' : 'transparent', zIndex: 1
        }}
          onMouseEnter={() => set_input_hovered('added years care')}
          onMouseLeave={() => {if(input_hovered === 'added years care') {set_input_hovered('')} }}
        >  </div>
        {input_hovered === 'added years care' && <p style = {{'position' : 'absolute', top: '-500px', width: '300px',
        'padding' : '30px', 'zIndex' : '2', 'background' : 'var(--main-color)', 'color' : 'white',
        'borderRadius' : '10px'
      }}
        > You can't choose whether to use added years at partial retirement because you are projected to withdraw all your legacy benefits at partial retirement in the 'choose reformed' scenario.
          This means that you must take all your added years at partial retirement. To un-grey this option, you would need to choose a lower partial retirement percentage
          on the 'Your Details' page.
          </p> }
        </Fragment>
        }
          <p className = {(!use_added_years_care ? classes.input_toggle_active : '') + ' ' + classes.input_toggle} > No </p>
             <FancyToggle state = {use_added_years_care} 
            handleChange = {
              () => {
                if(!allowed_added_years_ref.current['legacy']) {return}
                use_added_years_ref.current['care'] = !use_added_years_ref.current['care']
                allow_backend_call.current = true
                set_use_added_years_care(prev => !prev)
              }
            }
            active_colour = {
              allowed_added_years_ref.current['care'] ? cssVars['main-color'] : 'lightgrey'
            } 
              inactive_colour = {
                allowed_added_years_ref.current['care'] ? cssVars['main-color-light'] : 'lightgrey'
              }
            /> 
            <p className = {(use_added_years_care ? classes.input_toggle_active : '') + ' ' + classes.input_toggle} >Yes</p>
             <button onClick = {() => set_info_modal('Added Years')}> Help with added years </button>
             
           </div>
      </div> }
        
      <div className = {classes.added_pension} style = {{'flexDirection' : 'column', 'justifyContent' : 'center', 'position' : 'relative'}}>
      <button
          className = {classes.lump_sum_help_button}
           onClick = {() => set_info_modal('Lump Sum')}> Help with lump sum
            </button>
            
        <div style = {{'display' : 'flex', 'flex' : 2, 'justifyContent' : 'flexStart', 'alignItems' : 'center'
      }}>
        <h2 className = {classes.slider_label} > Lump sum at partial retirement </h2>  
          
        </div>
        <div className = {classes.added_pension_toggle_container}>
            <p> {formatToPound(Number(adjusted_standard_ls_choose_care).toFixed(0))} </p>
            <div style={{ position: 'relative' }}>
        <input 
        id = 'care_slider'
        value={care_slider} 
        type='range' 
        onChange={(e) => {
            care_slider_ref.current = e.target.value;
            set_care_slider(e.target.value);
            set_care_slider_manual_input(e.target.value)
        }}
        max={Number(adjusted_max_ls_choose_care)} 
        min={Number(adjusted_standard_ls_choose_care)}
        step={care_ls_step}
        onMouseUp={() => {
          allow_backend_call.current = true
          set_choose_reformed_lump_sum(care_slider)
        }
        }
    />
    <input 
        type="text"
        value={input_focus_care_slider ? Number(care_slider_manual_input).toFixed(0) : formatted_care_slider}
        onChange={(e) => {
          let val = Number(e.target.value)
          if(isNaN(val)) {
            return
          }
          val = val.toFixed(0)
            set_care_slider_manual_input(val)
            if(val >= adjusted_standard_ls_choose_care && val <= adjusted_max_ls_choose_care) {
              care_slider_ref.current = val
              allow_backend_call.current = true
              set_care_slider(val)
              allow_backend_call.current = true
          set_choose_reformed_lump_sum(val)
            }
        }}
        onFocus={() => set_input_focus_care_slider(true)}
        onBlur={() => 
          {set_input_focus_care_slider(false)
          if(care_slider_manual_input !== care_slider) {
            set_care_slider_manual_input(care_slider)
          }
          }
        }
        style={{
            position: 'absolute',
            left: String(((Number(care_slider) - Number(standard_ls_choose_care)) / care_ls_step) * 0.94 + (5 - (180 / 2))) + 'px',
            top: '30px',
            //pointerEvents: 'none',
            height: '40px',
            borderRadius: '10px',
            border: '5px solid ' + cssVars['main-color'],
            width: "180px",
            textAlign: 'center',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            fontSize: '20px'
        }}
    />

</div>
             <p> {formatToPound(Number(adjusted_max_ls_choose_care).toFixed(0))} </p>
             
             
          </div>
      
       
      </div>
    </div>
  </div>
  
  </div>
  )
})



const modal_content = {
  'Lump Sum' : <Fragment> 
    <h2> Adjust Lump Sum received at partial retirement</h2>
    <p> The 1995 section provides an automatic lump sum of three times pension. Members are also able to commute additional pension for a lump sum. You are eligible to commute pension (exchange pension for a one-off lump sum) from both your 1995 Section and 2015 Scheme benefits, subject to certain terms and conditions. This calculator allows you to select an amount of cash between the minimum and maximum amounts available to you. </p> 
    <p> Please note that the figures shown for the lump sum amount received at full retirement assume no additional commutation of pension occurs. </p>
  </Fragment>,
    'Added Pension' : <Fragment> 
                      <h1> Taking added pension at partial retirement </h1>
                      <p> If a member has added benefits (additional pension or added years) they can choose to 
                        claim them at the same time as their partial retirement benefits. 
                      </p>
                      <p> If the amount of benefits the member wants to take is more than the benefits they have in 
                        that Section or Scheme, their added benefits will automatically be payable. Their added 
                        benefits will be paid in full. In this case the option to select or deselect added benefits will be greyed out. 
                        </p>
                        <p>
                      If a member does not use all the benefits in the Section or Scheme when applying to take 
                      partial retirement, they can choose if they want to claim their added benefits at the same 
                      time. If they choose to take their added benefits, the added benefits must be paid in full, 
                      they cannot choose to claim part of the added benefits. You can model this using the selection buttons. 
                      </p>
                      <p>
                      If payments for added benefits are incomplete, the amount paid will be adjusted. If the 
                      member’s added benefits are being paid before the selected end age of the purchase, a 
                      reduction factor for early payment will apply. For additional pension purchased in the 2015 Scheme, late retirement factors will apply if pension benefits are claimed 
                      after Normal Pension Age. 
                      </p>

                        </Fragment>,
'Added Years' : <Fragment> 
              <h1> Taking added years at partial retirement </h1>
              <p> If a member has added benefits (additional pension or added years) they can choose to 
                claim them at the same time as their partial retirement benefits. 
              </p>
              <p> If the amount of benefits the member wants to take is more than the benefits they have in 
                that Section or Scheme, their added benefits will automatically be payable. Their added 
                benefits will be paid in full. In this case the option to select or deselect added benefits will be greyed out. 
                </p>
                <p>
              If a member does not use all the benefits in the Section or Scheme when applying to take 
              partial retirement, they can choose if they want to claim their added benefits at the same 
              time. If they choose to take their added benefits, the added benefits must be paid in full, 
              they cannot choose to claim part of the added benefits. You can model this using the selection buttons. 
              </p>
              <p>
              If payments for added benefits are incomplete, the amount paid will be adjusted. If the 
              member’s added benefits are being paid before the selected end age of the purchase, a 
              reduction factor for early payment will apply. For additional pension purchased in the 2015 Scheme, late retirement factors will apply if pension benefits are claimed 
              after normal pension age. 
              </p>

                </Fragment>,
'which charts to view' : <Fragment>
<h2> What the different charts show</h2>
<p> There are three different chart views: </p>

<ul style = {{'textAlign' : 'left', fontSize : '17px'}}> 
  <li> 
    <span style = {{'fontWeight' : '900'}}> Income view. </span>
    This shows the income you would be expected to receive from your pension plus continuing employment depending on your McCloud remedy choice.
  </li>
  <li> 
    <span style = {{'fontWeight' : '900'}}> Scenario view. </span>
    This shows the pension and lump sum you would be expected to receive from partial retirement onwards,
     as well as earnings from post-partial retirement employment. It also shows the pension and lump sum that 
     you would be expected to receive if you fully retired in a given year.
     These details are shown for the two different McCloud remedy choices.
  </li>
  <li> 
    <span style = {{'fontWeight' : '900'}}> Variable view. </span>
    This allows you to see how the different parts of your pension benefits might vary depending on your McCloud
    remedy choice, at both full and partial retirement.
  </li>
</ul>
<p style = {{marginBottom : '30px'}}> 
  You can click on 'Explain' next to a chart to see more information about what it represents.
</p>
</Fragment>
}

let scenario_legend_colours = {
  'From partial retirement if legacy option is chosen' : cssVars['legacy-curtailed'], 
  'From partial retirement if reformed option is chosen' : cssVars['full-legacy'], 
  'From full retirement if legacy option is chosen' : cssVars['legacy-curtailed-light'], 
  'From full retirement if reformed option is chosen' : cssVars['full-legacy-light'],
}
const ScenarioLegend = () => {
  const mainLegendItems = Object.entries(scenario_legend_colours).slice(0, 4);

  return (
    <div className={classes.LegendContainer}>
      <div className={classes.LegendSubContainer}>
        {mainLegendItems.map(([key, value]) => (
          <div className={classes.legendBox} key={key} style={{ '--value': value }}>
            <div></div>
            <span>{key}</span>
          </div>
        ))}
      </div>

    </div>
  );
}

const IncomeLegend = () => {



  return(
        <div className = {classes.income_container_container}>
            <div className = {classes.income_container}> 
                  <div className = {classes.income_legend_items}>
                    <span style = {{'background' : variable_colours['actual_pay']}}> </span>
                    <p> Earnings during partial retirement</p>
                  </div>
                  
            </div>
            <div className = {classes.income_container}> 
                  <div className = {classes.income_legend_items}>
                    <span style = {{'background' : variable_colours['LP']}}> </span>
                    <p> Legacy pension received from full retirement onwards</p>
                  </div>
                  <div className = {classes.income_legend_items}>
                    <span style = {{'background' : variable_colours['CP']}}> </span>
                    <p> CARE pension received from full retirement onwards</p>
                    </div>
                    <div className = {classes.income_legend_items}>
                    <span style = {{'background' : variable_colours['LP at partial retirement']}}> </span>
                    <p> Legacy pension received from partial retirement onwards</p>
                    </div>
                  <div className = {classes.income_legend_items}>
                    <span style = {{'background' : variable_colours['CP at partial retirement']}}> </span>
                    <p> CARE pension received from partial retirement onwards</p>
                    </div>
                    
                    
                    
            </div>

            <div className = {classes.income_container}>
                <div className = {classes.income_legend_items}>
                    <span style = {{'background' : pr_colour}}> </span>
                    <p> Income while partially retired</p>
                    </div>
                    <div className = {classes.income_legend_items}>
                    <span style = {{'background' : fr_colour}}> </span>
                    <p> Income once fully retired</p>
                  </div>
                  <div className = {classes.income_legend_items}>
                    <span style = {{'background' : variable_colours['LS at partial retirement'], 'borderRadius' : '50%'}}> </span>
                    <p> One off lump sum at partial retirement</p>
                  </div>
                  <div className = {classes.income_legend_items}>
                    <span style = {{'background' : variable_colours['LS'], 'borderRadius' : '50%'}}> </span>
                    <p> One off lump sum at full retirement</p>
                  </div>
             </div>
     </div>
     )
}


// Rendering Variable-based view
const VariableBasedView2015 = () => {
  return <Bar data={variableBasedData['2015-04-01']} options={chartOptions} />;
}

const VariableBasedView2022 = () => {
  return <Bar data={variableBasedData['2022-04-01']} options={chartOptions2} />;
}




const labels =  Array.from({ length: n_years * 2 + 1 }, (_, i) => (i).toString());
let x_values = Array.from({ length: n_years }, (_, i) => (i === 0 ? 0 : i*2 + 1) )
let scenario_data_new = {}
let scenario_chart_options = {}

const customLabels = {
  0: '0',
  3: '1',
  5 : '2',
  7: '3',
  9: '4',
  11: '5', 
  13: '6', 
  15: '7',
  17 : '8'
};

for (let my_variable of scenario_variables) {

 var data_2015
 var data_2022
  if(my_variable === 'Full Pension') {
    data_2015 = dates.map(date => many_annual_details_both_scens['2015-04-01'][date]['CP'] + many_annual_details_both_scens['2015-04-01'][date]['LP'])
    data_2022 = dates.map(date => many_annual_details_both_scens['2022-04-01'][date]['CP'] + many_annual_details_both_scens['2022-04-01'][date]['LP'])
  } else {
    data_2015 = dates.map(date => many_annual_details_both_scens['2015-04-01'][date][my_variable])
    data_2022 = dates.map(date => many_annual_details_both_scens['2022-04-01'][date][my_variable])
  }
  data_2015.unshift(null)
  data_2022.unshift(null)

  let at_pr_2015 = pr_datapoints['2015-04-01'][my_variable]
  let at_pr_2022 = pr_datapoints['2022-04-01'][my_variable]
  


  scenario_data_new[my_variable] = {
    labels: labels,
    datasets: [
          
        {
            type: 'bar',
            label: 'Received from full retirement if you choose the CARE scheme',
            backgroundColor: scenario_colours['choose care light'],
            borderColor: scenario_colours['choose care light'],
            data: data_2015.map((value, index) => {return {x : x_values[index], y : value} } ),
            order: 2
        },
        {
            type: 'bar',
            label: 'received from full retirement if you choose the legacy scheme',
            backgroundColor: scenario_colours['choose legacy light'],
            borderColor: scenario_colours['choose legacy light'],
            data: data_2022.map((value, index) => {return {x : x_values[index], y : value} } ), 
            order: 2
        },
        {
          type: 'scatter',
          label: 'Received from partial retirement if you choose the CARE scheme',
          backgroundColor: scenario_colours['2015-04-01'],
          borderColor: scenario_colours['2015-04-01'],
          data: x_values.map((value, index) => {return {x : value - 0.3, y : ((my_variable === 'LS' & index > 0) ? null : at_pr_2015 * Math.pow(revaluation_factor, index))}}),  // tweaked the offset,  // tweak this offset amount for spacing
          pointRadius: 5,
          showLine: false, 
          order: 1
      },
      {
          type: 'scatter',
          label: 'Received from partial retirement if you choose the legacy scheme',
          backgroundColor: scenario_colours['2022-04-01'],
          borderColor: scenario_colours['2022-04-01'],
          data: x_values.map((value, index) => {return {x : value + 0.3, y : ((my_variable === 'LS' & index > 0) ? null : at_pr_2022 * Math.pow(revaluation_factor, index))}}),  // tweak this offset amount for spacing
          pointRadius: 5,
          showLine: false, 
          order: 1
      },
        
    ]
  };



  scenario_chart_options[my_variable] = {
    
    animation: {
      duration: 0
    },
    plugins: {
      annotation: {
        annotations: [{
            type: 'box',
            xScaleID: 'x',
            yScaleID: 'y',
            xMin: -1,
            xMax: 1,
            yMin: 0,
            backgroundColor: rectangle_colour
        }]
    },
      legend: {
        display: false,
      },
      title: {
        display: true,
        text: [variable_key[my_variable]],
        align: 'start',
        font: {
          size: 30,
          color: 'black'
        }
      },
      tooltip: {
        callbacks: {
          title: function(context) {
            const x_val = context[0].label
            if(Number.isInteger(x_val)) {
              return format_x(customLabels[x_val]);
              
            } else {
            if(x_val < 0) {
              return format_x(customLabels[0])
            } else {
            return format_x(customLabels[Number(x_val).toFixed(0)]);
            }
          }
          },
          label: function(context) {
            const dataset = context.dataset;
            const colourLabel = dataset.label;
            const yValue = dataset.data[context.dataIndex]['y'];
            
            return `${colourLabel}: ${formatToPound(yValue)}`;
          }
        },
    bodyFont: {
      size: 16  // Set the font size for the body of the tooltip
    },
    titleFont: {
      size: 20  // Set the font size for the title of the tooltip
    },
    footerFont: {
      size: 16  // Set the font size for the footer of the tooltip, if any
    }
      }  
    },
    scales: {
      y: {
        min: 0,
        id : 'y',
        stacked: false,
        grid: {
          display: false
        },
        title: {  // Use title instead of scaleLabel
          display: false,
          text: 'Value',
          align: 'start',
          font: {
            size: 24  // Adjust this value for the x-axis title font size
          }
        },
        ticks: {
          callback: function(value, index, values) {
            return '£' + value.toLocaleString();
          },
          font: {
            size: 20  // Adjust this value for the y-axis tick labels' font size
          }
        },
      },
      x: {
        title: {
          display : [2, 3].includes(scenario_variables.indexOf(my_variable)),
          text : 'Years from partial retirement',
          font : {
            size : 24
          }
        },
        type: 'linear',
        position: 'bottom',
        grid: {
            display: false
        },
        barPercentage: 1,    // This makes the bars wider
        categoryPercentage: 1,
        ticks: {
            callback: function(value) {
                return customLabels[value] || null; 
            },
            source: 'data',
            font: {
                size: 20
            },
            min: -1,
            max: n_years * 2,
            stepSize : 1, 
            afterBuildTicks: function(scale) {
              // Explicitly set the tick values
              scale.ticks = Array.from({ length: 19 }, (_, i) => i);
            }    // Set the max to cover all "fake" scale positions
        }
      }
    },
    maintainAspectRatio: false
  };
}

const ScenarioBasedChart= () => {
  
  return (
    <div style={{ display: 'flex', flexDirection: 'column', 'height' : '100%', 'width' : '100%' }}>
      {[0, 2].map(start => (
        <div key={start} style={{ display: 'flex', height: '50%', justifyContent: 'stretch' }}>
          {scenario_variables.slice(start, start + 2).map((variable, index) => {
            

            return (
              <div key={variable} style={{ flex: 1, height: '100%', position: 'relative' }}>
                
                <button className = {classes.chart_button}
                style = {{ position: 'absolute'}}
                onClick = {() => set_show_modal(variable)}
                >
                   Explain
                    </button>
                <Bar data={scenario_data_new[variable]} options={scenario_chart_options[variable]} />
                {show_modal === variable && <ChartModal 
                data = {scenario_data_new[variable]}
                options = {scenario_chart_options[variable]}
                variable = {variable_key[variable]}
                modalHandler = {modalHandler}
                no_ls_reformed = {no_legacy_choose_reformed}
                no_ls_legacy = {no_legacy_choose_legacy}
                />}
              </div>
            );
          })}
        </div>
      ))}
    </div>
  );
};


const IncomeChart2022 = (props) => {
 
  return(<IncomeChart
lump_sum_pr = {actual_ls_choose_legacy}
lump_sum_fr = {many_annual_details_both_scens['2022-04-01'][chosen_date]['LS']}
chosen_year = {Number(chosen_year)}
choice = {props.title}
revaluation_factor = {revaluation_factor}
salary_increase = {Number(salary_increase)}
actual_pay_first_year = {inputs.NewSal * inputs.postReshapingPT * 0.01 * salary_uplift} 
rectangle_colour = {rectangle_colour}
pr_colour = {pr_colour}
fr_colour = {fr_colour}
lp_fr = {many_annual_details_both_scens['2022-04-01'][chosen_date]['LP']}
cp_fr = {many_annual_details_both_scens['2022-04-01'][chosen_date]['CP']}
cp_pr = {cp_at_pr_choose_legacy}
lp_pr = {lp_at_pr_choose_legacy}
high_perc = {props.high_perc}
default_perc = {props.default_perc}
tooltip = {{
  callbacks: {
    title: function(context) {
      const xValue = context[0].label;
      return format_x(xValue);
    },
    label: function(context) {
      const dataset = context.dataset;
      const colourLabel = dataset.label;
      const yValue = dataset.data[context.dataIndex];
      return `${format_colour(colourLabel, 'income')}: ${formatToPound(yValue)}`;
    }
  },
   bodyFont: {
      size: 16  // Set the font size for the body of the tooltip
    },
    titleFont: {
      size: 20  // Set the font size for the title of the tooltip
    },
    footerFont: {
      size: 16  // Set the font size for the footer of the tooltip, if any
    }
}}
n_years = {n_years} 
/>)}

const IncomeChart2015 = (props) => {return(<IncomeChart
  lump_sum_pr = {actual_ls_choose_reformed}
  lump_sum_fr = {many_annual_details_both_scens['2015-04-01'][chosen_date]['LS']}
  chosen_year = {Number(chosen_year)}
  choice = {props.title}
  revaluation_factor = {revaluation_factor}
  salary_increase = {Number(salary_increase)}
  actual_pay_first_year = {inputs.NewSal * inputs.postReshapingPT * 0.01 * salary_uplift}
  rectangle_colour = {rectangle_colour}
  pr_colour = {pr_colour}
  fr_colour = {fr_colour}
  lp_fr = {many_annual_details_both_scens['2015-04-01'][chosen_date]['LP']}
  cp_fr = {many_annual_details_both_scens['2015-04-01'][chosen_date]['CP']}
  cp_pr = {cp_at_pr_choose_reformed}
  lp_pr = {lp_at_pr_choose_reformed}
  high_perc = {props.high_perc}
  default_perc = {props.default_perc}
  tooltip = {{
    callbacks: {
      title: function(context) {
        const xValue = context[0].label;
        return format_x(xValue);
      },
      label: function(context) {
        const dataset = context.dataset;
        const colourLabel = dataset.label;
        const yValue = dataset.data[context.dataIndex];
        return `${format_colour(colourLabel, 'income')}: ${formatToPound(yValue)}`;
      }
    }, 
    bodyFont: {
      size: 16  // Set the font size for the body of the tooltip
    },
    titleFont: {
      size: 20  // Set the font size for the title of the tooltip
    },
    footerFont: {
      size: 16  // Set the font size for the footer of the tooltip, if any
    }
  }}
  n_years = {n_years}
  />)}

  const care_ls_hs_table = round10(many_annual_details_both_scens['2015-04-01'][chosen_date]['LS']);
  const legacy_ls_hs_table = round10(many_annual_details_both_scens['2022-04-01'][chosen_date]['LS']);
  const care_pension_hs_table = round10(Number(many_annual_details_both_scens['2015-04-01'][chosen_date]['LP']) + Number(many_annual_details_both_scens['2015-04-01'][chosen_date]['CP']));
  const legacy_pension_hs_table = round10(Number(many_annual_details_both_scens['2022-04-01'][chosen_date]['LP']) + Number(many_annual_details_both_scens['2022-04-01'][chosen_date]['CP']));
  const care_colour_hs_table = variable_colours['CP'];
  const care_colour_light_hs_table = variable_colours['CP at partial retirement'];
  const legacy_colour_hs_table = variable_colours['LP'];
  const legacy_colour_light_hs_table = variable_colours['LP at partial retirement'];
  const type_hs_table = 'full';

  return (
    <div id="Results_Page">

      <h1 style = {{'textAlign' : 'center', 'marginTop' : '-20px', 'marginBottom' : '-20px'}}> Summary figures </h1>

      
        <div className = {classes.top_explainer}> 
        <div style = {{
          'background' : 'lightgrey',
          'boxShadow' : '0px 0px 5px 5px grey',
          'borderRadius' : '10px',
          'padding' :'10px',
          'marginBottom' : '15px'
          }}>
        <p> 
        The benefits below are based on your chosen percentage of {used_pr_percentage}%. If you wish to pursue this option, please use this percentage when completing the retirement form called <a href = "https://pensions.gov.scot/nhs/employers/how-complete-retirement-application">(NHS:RET)</a>. 
        </p>
        </div>
          <div className = {classes.border_line} style = {{width: '3000%', 'transform' : 'translateX(-50%)'}}> </div>
            <div className = {classes.hs_table_explainer_container}> 
              <p style = {{'textAlign' : 'left'}}> 
                The numbers below show the annual pension and lump sums that you would be expected to start receiving at partial and full
                retirement, assuming you fully retire exactly  <select 
                    style={{
                        background: 'white',
                        border: '5px solid ' + fr_colour,
                        outline: 'none',
                        padding: '0 2px',
                        fontSize: '20px',
                        fontFamily: 'inherit',
                        color: 'inherit'
                    }}
                    value={chosen_year}
                    onChange={
                      (e) => {
                        set_chosen_year(e.target.value)
                      }
                      }
                >
                    {possible_years.map(year => (
                        <option key={year} value={year}>
                            {String(year)}
                        </option>
                    ))}
                </select> {' '}
                years after your partial retirement date. This would mean
                 partial retirement on {formatDate(from_backend.current['prd'])}, with 
                 full retirement on {formatDate(chosen_date)} 
              </p>
              <div> 
                <p> Full retirement date </p>
                <select 
                    value={chosen_year}
                    onChange={(e) => set_chosen_year(e.target.value)}
                >
                    {possible_years.map(year => (
                        <option key={year} value={year}>
                            {formatDate(from_backend.current['all_ret_dates'][Number(year)], 'short')}
                        </option>
                    ))}
                </select>
              </div>
            </div>
          <div className = {classes.border_line} style = {{width: '3000%', 'transform' : 'translateX(-50%)'}}> </div>
        </div>
      {show_original_hs_table &&
      <div style = {{'width' : '100%', display: 'flex'}} className = {classes.both_header_containers} >

          <div style = {{'width' : '50%', background:  pr_colour, paddingBottom: '30px', paddingTop : '30px', 
          'borderRadius' : '20px'}}
          className = {classes.partial_retirement_box}
          >
              <h1> Partial retirement ({formatDate(from_backend.current['prd'])})</h1>
           <HSTable
            care_ls = {formatToPound(round10(care_slider_ref.current))}
            legacy_ls = {formatToPound(round10(legacy_slider_ref.current))}
            care_pension = {formatToPound(round10(cp_at_pr_choose_reformed + lp_at_pr_choose_reformed))}
            legacy_pension = {formatToPound(round10(cp_at_pr_choose_legacy + lp_at_pr_choose_legacy))}
            care_colour = {variable_colours['CP']}
            care_colour_light = {variable_colours['CP at partial retirement']}
            legacy_colour = {variable_colours['LP']}
            legacy_colour_light = {variable_colours['LP at partial retirement']}
            type = {'partial'}
            initialMountParent = {initialMountActual.current}

            />
          </div>
      <div style = {{'width' : '50%'}} 
            key={initialMountActual.current ? 'key' : wobble_fr.toString()} 
            className = {initialMountActual.current ? '' :  (wobble_fr ? classes.full_retirement_box_wobbler1 : classes.full_retirement_box_wobbler2)}
            >
      <div style = {{'width' : '100%', background:  fr_colour, paddingBottom: '30px', paddingTop : '30px', 
    marginLeft: '15px', 'borderRadius' : '20px'}} 
    className = {initialMountActual.current ? classes.full_retirement_box : ''}
    >
      <h1> Full retirement ({formatDate(chosen_date)})</h1>
      <HSTable
      care_ls = {care_ls_hs_table}
      legacy_ls = {legacy_ls_hs_table}
      care_pension = {care_pension_hs_table}
      legacy_pension = {legacy_pension_hs_table}
      care_colour = {care_colour_hs_table}
      care_colour_light = {care_colour_light_hs_table}
      legacy_colour = {legacy_colour_hs_table}
      legacy_colour_light = {legacy_colour_light_hs_table}
      type = {type_hs_table}
      initialMountParent = {initialMountActual.current}
      />
      </div>
      </div>
      </div>
      }

<div className = {classes.first_mock_up}> 
  <div className = {classes.pr_box} style = {{'--col' : legacy_colour_hs_table, '--lighter' : legacy_colour_light_hs_table}}> 
        
        <div className = {classes.pr}>
        <h1> Partial Retirement on {formatDate(from_backend.current.prd)}  </h1>
        <div className = {classes.pr_parts}> 
          <div className = {classes.part}> 
            <h2> You will get a one-off lump sum of:
              </h2> 
            <div className = {classes.part_ls2}>
              <h1> {formatToPound(round10(legacy_slider_ref.current))} </h1>
              {include_images && <img src = {poundHand} alt = {'icon depciting outstretched hand receiving money'} /> }
            </div>
            {false && <button> Tell me more </button>}
          </div>
          <div className = {classes.plus}> <h1> +</h1></div>
          <div className = {classes.part}> 
            <h2 style = {{color : 'white'}}> you will start receiving an annual pension of:</h2> 
            <div className = {classes.part_ls}>
              <h1> {formatToPound(round10(cp_at_pr_choose_legacy + lp_at_pr_choose_legacy))} </h1>
              {include_images && <img src = {poundLogo} alt = {'big pound coin'} /> }
            </div>
            {false && <button> Tell me more </button>}
          </div>
          <div className = {classes.plus}> <h1> +</h1></div>
          <div className = {classes.part}>
            <h2> until you fully retire, you will continue earning an annual salary of:</h2> 
            <div className = {classes.part_ls}>
              <h1>{formatToPound(Number(both_actual_results_tables['2022-04-01'][0]['actual_pay']))} </h1>
              {include_images && <img src = {salaryImg} alt = {'happy workers with cash in their hands'}/> }
            </div>
            
          </div>
        </div>
           </div>
  </div>
  <div className = {classes.fr_box} >
    <div className = {classes.fr}> 
        <div className = {classes.fr_title}> 
          <h1> Full retirement on {formatDate(chosen_date)} </h1>
          <h2> When you fully retire, your salary from employment will stop completely and you will get:</h2>
        </div>
        <div className = {classes.fr_reformed} style = {{'--col' : legacy_colour_hs_table, '--lighter' : legacy_colour_light_hs_table}}> 
          <div className = {classes.if_choose}> 
          <h2> If you choose the 1995 Section: </h2>
          </div>
          <div className = {classes.fr_detail}> 
            <div className = {classes.part}> 
                <h2> Another one-off lump sum of:</h2> 
                <div className = {classes.part_ls2}>
                  <h4> {formatToPound(legacy_ls_hs_table)} </h4>
                  {include_images && <img src = {poundHand} alt = {'icon depciting outstretched hand receiving money'} /> }
                </div>
                {false && <button> Tell me more </button>}
            </div>
          </div>
          <div className = {classes.plus2}> <h1> +</h1></div>
          <div className = {classes.fr_detail}> 
            <div className = {classes.part}> 
                <h2> your annual pension will increase to a total of:</h2> 
                <div className = {classes.part_ls}>
                  <h4> {formatToPound(legacy_pension_hs_table + cp_at_pr_choose_legacy + lp_at_pr_choose_legacy)} </h4>
                  {include_images && <img src ={poundLogo} alt = {'icon depciting outstretched hand receiving money'} /> }
                </div>
                {false && <button> Tell me more </button>}
            </div>
          </div>
        </div>
        <div className = {classes.fr_legacy} style = {{'--col' : care_colour_hs_table, '--lighter' : care_colour_light_hs_table}}>
          <div className = {classes.if_choose}>
            <h2> If you choose the 2015 Scheme: </h2>
          </div>
          <div className = {classes.fr_detail}> 
          <div className = {classes.part}> 
                <h2> Another one-off lump sum of:</h2> 
                <div className = {classes.part_ls2}>
                  <h4> {formatToPound(care_ls_hs_table)}  </h4>
                  {include_images && <img src = {poundHand} alt = {'icon depciting outstretched hand receiving money'} /> }
                </div>
                {false && <button> Tell me more </button>}
            </div>
          </div>
          <div className = {classes.plus2}> <h1> +</h1></div>
          <div className = {classes.fr_detail}> 
          <div className = {classes.part}> 
                <h2> your annual pension will increase to a total of:</h2> 
                <div className = {classes.part_ls}>
                  <h4> {formatToPound(care_pension_hs_table + cp_at_pr_choose_reformed + lp_at_pr_choose_reformed)} </h4>
                  {include_images && <img src ={poundLogo} alt = {'icon depciting outstretched hand receiving money'} /> }
                </div>
                {false && <button> Tell me more </button>}
            </div>
          </div>
           </div>
    </div>
     </div>
</div>

     <h1
     style = {{'marginTop' : '30px', 'marginBottom' : '30px', 'textAlign' : 'center', 'width' : '80%', 'marginLeft' : '10%'}}
     > You can adjust the options below to see how different decisions could affect the pension
      and lump sum you receive at full and partial retirement.</h1> 
     {show_charts && <Fragment>
      <div className = {classes.border_line} style = {{width: '70%', 'transform' : 'translateX(20%)', 'marginTop' : '30px'}}> </div>
      <h1 style = {{'marginTop' : '50px'}}> Charts </h1>

<p style = {{'fontSize' : '18px'}}> The charts below break these numbers down in more detail. Please note that in some cases, your total pension above might not precisely match the sum of the stacked bars due to rounding. </p>
 <p style = {{'fontSize' : '18px'}}> You can use the sliders and buttons below the 
chart to change some of the assumptions being used in the calculations.
</p> </Fragment>}
{show_charts &&
<div className = {classes.chart_and_settings_container}> 
  <div className = {classes.swiper_container}> 
  <div className = {classes.chart_swiper} 
  style = {{
    transform: (variable_view === 'variable view') ? 'translateX(0%)' : ((variable_view === 'income view') ?  'translateX(-66.66666%)' : 'translateX(calc(-33.33333%))')
  }}
  > 
  <div className = {classes.chart_container}> 
        <div  className = {classes.variable_based_view}> 
        {!chart_hovered && <button style = {{ position: 'absolute'}}
        className = {classes.chart_button}
        onClick = {
          () => set_show_modal('variable_view_2022')
        }
        > Explain</button>}
        {show_modal === 'variable_view_2022'&& 
          <ChartModalScenarioView
          data = {variableBasedData['2022-04-01']}
           options = {chartOptions2}
           choice = {'legacy'}
           modalHandler = {modalHandler}
           datalabel_key = {datalabel_income_key}
           no_legacy = {no_legacy_choose_legacy}
           example_year ={Math.min(3, n_years - 1)}

          />}
        <VariableBasedView2022 />
         </div>
         <div  className = {classes.variable_based_view}> 
         {!chart_hovered && <button style = {{ position: 'absolute'}}
        className = {classes.chart_button}
        onClick = {
          () => set_show_modal('variable_view_2015')
        }
        > Explain</button>}
        
          {show_modal === 'variable_view_2015'&& 
          <ChartModalScenarioView
          data = {variableBasedData['2015-04-01']}
           options = {chartOptions}
           choice = {'reformed'}
           modalHandler = {modalHandler}
           datalabel_key = {datalabel_income_key}
           no_legacy = {no_legacy_choose_reformed}
           example_year ={Math.min(3, n_years - 1)}

          />}
        
        <VariableBasedView2015  />
         </div>
  
  
  
  
  </div>

  <div className = {classes.chart_container}>
  <ScenarioBasedChart/>
  </div>

  <div className = {classes.chart_container}>
    
        <div className = {classes.variable_based_view}> 
        {!chart_hovered && <button className = {classes.chart_button}
                style = {{ position: 'absolute'}}
                onClick = {() => set_show_modal('income_2022')}
                >
                   Explain
                    </button>}
         <IncomeChart2022 title = 'Choose Legacy' high_perc  = '' default_perc = {1}/>
        </div>
        <div className = {classes.variable_based_view}> 
        {!chart_hovered && <button className = {classes.chart_button}
                style = {{ position: 'absolute'}}
                onClick = {() => set_show_modal('income_2015')}
                >
                   Explain
                    </button>}
         <IncomeChart2015 title = 'Choose Reformed' high_perc  = '' default_perc = {1}/>
        </div>
        {show_modal === 'income_2022' && 
        <ChartModalIncomeView
        chart = {IncomeChart2022}
        modalHandler = {modalHandler}
        variable_colours = {variable_colours}
        no_care = {no_care_choose_legacy}
        no_legacy = {no_legacy_choose_legacy}
        title = {'Projected income in the "Choose Legacy" scenario'}
        choice = {'Legacy'}
        date_1 = {formatDate(from_backend.current['prd']) + ' to ' + formatDate(from_backend.current['all_ret_dates'][1])}
        date_2 = {formatDate(from_backend.current['all_ret_dates'][Math.min(n_years, 3)]) + ' to ' + formatDate(from_backend.current['all_ret_dates'][Math.min(n_years + 1, 4)])}
        n_years = {n_years}
        />
        //sort out possible invalid keys given no projection to year 4
      } 

{show_modal === 'income_2015' && 
        <ChartModalIncomeView
        chart = {IncomeChart2015}
        modalHandler = {modalHandler}
        variable_colours = {variable_colours}
        no_care = {no_care_choose_reformed}
        no_legacy = {no_legacy_choose_reformed}
        title = {'Projected income in the "Choose Reformed" scenario'}
        choice = {'CARE'}
        date_1 = {formatDate(from_backend.current['prd']) + ' to ' + formatDate(from_backend.current['all_ret_dates'][1])}
        date_2 = {formatDate(from_backend.current['all_ret_dates'][Math.min(n_years, 3)]) + ' to ' + formatDate(from_backend.current['all_ret_dates'][Math.min(n_years + 1, 4)])}
        n_years = {n_years}
        />
        }
    </div>

  </div>
  </div>
  



  <div className = {classes['both_toggles_container']}> 
  <div> 
    <h2 style = {{'width' : '100%', 'textAlign' : 'center'}}> Adjust chart view </h2>
    <p style = {{'width' : '90%', 'textAlign' : 'center', marginLeft: '5%', marginBotton: '5%'}}> You can use these buttons to adjust the appearance of the charts, without affecting the numbers being calculated.</p>
    </div>
    <div className = {classes.first_toggle}>
    <div className = {classes.toggle_title_and_button_container}>
      <h3 style = {{'marginLeft' : '15px', 'marginBottom' : 0, 'width' : '350px'}} > Which charts to view </h3>
      <div style = {{'display' : 'flex', 'justifyContent' : 'end', 'width' : '75%'}}>
           <button style =  {{'marginRight' : '20px'}}
           onClick = {() => {set_info_modal('which charts to view')}}
           > Help </button> </div>
      </div> 


<div className = {classes.view_button_container}> 

  <button onClick = {() => set_variable_view('scenario view')}
  className = {variable_view === 'scenario view' ? classes.selected : ''}
  > Variable View</button> {//variable and scenario view names are mixed up throughout!
  }
  <button onClick =  {() => set_variable_view('variable view')}
  className = {variable_view === 'variable view' ? classes.selected : ''}
  > Scenario View </button>
  <button 
  className = {variable_view === 'income view' ? classes.selected : ''}
  onClick =  {() => set_variable_view('income view')} > Income View </button>
</div>

<div style = {{'minHeight' : '208px'}}> 
 <div 
    style={{ 
    padding : '0 5%', 'textAlign' : 'center', border: 'none', borderRadius : '10px',  
  'marginTop' : '10px' }}
    className = {classes.income_chart_explainer}
    >
      {(variable_view === 'income view') && <Fragment>
    <p  style = {{fontSize : '17px'}}>
        This chart shows projected income assuming full retirement exactly {' '}
        <select 
            style={{
                background: 'white',
                border: '5px solid ' + fr_colour,
                outline: 'none',
                padding: '0 2px',
                fontSize: '20px',
                fontFamily: 'inherit',
                color: 'inherit'
            }}
            value={chosen_year}
            onChange={(e) => set_chosen_year(e.target.value)}
        >
            {possible_years.map(year => (
                <option key={year} value={year}>
                    {String(year)}
                </option>
            ))}
        </select> 
          years after partial retirement, i.e.:
    
        </p> <p  style = {{fontSize : '17px'}}>
        <span style = {{'fontWeight' : '900'}}> partial retirement: {formatDate(from_backend.current['prd'])}</span>
        </p> <p  style = {{fontSize : '17px'}}>
        <span style = {{'fontWeight' : '900'}}> full retirement: {formatDate(chosen_date)}</span>
        </p>
        </Fragment>}
        {variable_view === 'scenario view' && <p> 
          These charts allow you to see lump sums and pension from partial and full retirement in the 
          'choose reformed' and 'choose legacy' scenarios. Click 'explain' for more details.</p>}
          {variable_view === 'variable view' && <p> 
          These charts allow you to see how the choice between receiving reformed or legacy benefits over the 
          remedy period may affect different parts of your pension. Click 'explain' for more details.</p>}
</div>
</div>

    </div>

   
    
  </div>

</div>
}
{show_charts && <Fragment> 
{(variable_view === 'variable view' ) ? 
 <VariableLegend2/>  :
 (variable_view) === 'scenario view' ? 
<div style = {{'display' : 'flex', 'flexDirection' : 'column', 'alignItems' : 'center', 'width': 'calc(100% - 450px'}}>
   <ScenarioLegend/> 
   </div>
   :
   <div style = {{'display' : 'flex', 'flexDirection' : 'column', 'alignItems' : 'center', 'width': 'calc(100% - 300px'}}>
   <IncomeLegend></IncomeLegend>
   </div>
}
</Fragment>}

<InputChanger key = {'inputchanger'}/>


{show_summary_table &&
<Fragment>
<div className = {classes.border_line} style = {{width: '70%', 'transform' : 'translateX(20%)', 'marginTop' : '60px', 'marginBottom' : '60px'}}> </div>
<h1> Summary table </h1>

<div 
className = {classes.table_explanation_container}
style = {{display: 'flex', 'width' : '70%', 'alignItems' : 'center', 'marginLeft' : '15%'}}>
  <div style = {{'width' : '70%'}}> 
    <p>
      The table below shows the lump sum and pension that you are projected to receive from partial retirement onwards, 
      and that you are projected to start receiving from full retirement if you fully retire in the relevant year.
      It contains the same information as the 'scenario view' chart above.
      </p>
      <p> 
        You can use the buttons to change whether the table shows results for the 'choose reformed' or 'choose legacy' 
        scenario.
      </p>
  </div>
  <div 
  className = {classes.table_button_container}
  style = {{width: '30%', display : 'flex', 'flexDirection' : 'column', 'justifyContent' : 'space-evenly'}}>
      <button style = {
        scenario === '2015-04-01' ? {'background' : cssVars['main-color'], color : 'white', 'border' : 'none' }
        : {}
        }
        onClick = {() => set_scenario('2015-04-01')}
        > REFORMED </button> 
      <button
      style = {
        scenario === '2022-04-01' ? {'background' : cssVars['main-color'], color : 'white', 'border' : 'none' }
        : {}
        }
        onClick = {() => set_scenario('2022-04-01')}
      > LEGACY </button>
  </div>
</div>

<div className={classes_comp_table.container}> 
      <table style={{ whiteSpace: 'normal' }} className={classes_comp_table.comp_table}> 
            <thead>
                <tr>
                    <th> Start of year </th>
                    <th> End of year </th>
                    <th> Earnings during the year if not yet fully retired</th>
                    <th>CARE pension from partial retirement received this year</th>
                    <th>Legacy pension from partial retirement received this year</th>
                    <th> Lump sum from partial retirement received this year</th>
                    <th>CARE pension you would start receiving if you fully retired at the start of the year </th>
                    <th>Legacy pension you would start receiving if you fully retired at the start of the year</th>
                    <th>Lump sum you would receive upon full retirement at the start of this year </th>
                </tr>
            </thead>
            <tbody>
                {actual_results_table.map(
        (value, index) => {
          var fyi = Number(first_year_index)
          if ((index >= Number(fyi)) & (index <= (Number(fyi) + (n_years-1)) )) {

            
            const start_ret_date = from_backend.current['all_ret_dates'][index - first_year_index]

            
            const this_year_details = many_annual_details[start_ret_date]
            const end_ret_date = from_backend.current['all_ret_dates_end'][index - first_year_index]


            const CP_table = formatToPound((index === fyi) ? cp_at_pr * pension_uplift  : (Number(this_year_details['CP']) * pension_uplift))
            const LP_table = formatToPound((index === fyi) ? lp_at_pr * pension_uplift : (Number(this_year_details['LP']) * pension_uplift))
            const LS_table = (index === fyi) ? 'Not Applicable' : formatToPound(Number(this_year_details['LS']))

            const CP_pr_table = formatToPound(Number(cp_at_pr) * pension_uplift * Math.pow(pension_increase, (index - first_year_index)))
            const LP_pr_table = formatToPound(Number(lp_at_pr) * pension_uplift * Math.pow(pension_increase, (index - first_year_index)))
          return(
            <tr key = {index}>
              <td> {formatDate(start_ret_date,'short')} </td>
              <td> {formatDate(end_ret_date,'short')} </td>
              <td> {formatToPound((Number(value['actual_pay']) * salary_uplift)) } </td>
              <td> {CP_pr_table} </td>
              <td> {LP_pr_table} </td>
              <td> {index === fyi ? formatToPound(actual_ls) : 'Not Applicable'} </td>
              <td> {index === fyi ? 'Not Applicable' : CP_table} </td>
              <td> {index === fyi ? 'Not Applicable' : LP_table} </td>
              <td> {LS_table} </td>
              </tr>
          )
          } else {
            return(null)
          }
        }
      )}
      </tbody>
    </table>

  </div> 
  </Fragment>
}
  {show_calculations && <Fragment> {//show calculations
}
<div style = {{background: 'rgb(230,230,230)', 'marginTop' : '50px'}}> 



<h1> Checks  (Content from here on will be removed before sending to client)</h1>



</div>







    <p> Choose which scenario to check and to view results for. Checks are currently shown for the 
        {scenario === '2015-04-01' ? " 'Chose reformed'" : " 'Choose legacy'"} scenario</p>

      <div style = {{display : 'flex'}}> 
      <button 
      onClick = {() => set_scenario('2015-04-01')}
      style = {{color : scenario === '2015-04-01' ? 'blue' : 'grey'}}
      > Choose reformed</button>
      <button 
      onClick = {() => set_scenario('2022-04-01')}
      style = {{color : scenario === '2022-04-01' ? 'blue' : 'grey'}}
      > Choose legacy</button>
      </div>

      

      <h2> Salaries</h2>
      <p> The first step is to project FTE and actual pay over the relevant period. </p> 
      
      
      <h3> FTE pay </h3>
      <p> For FTE pay before the partial retirement
        date, we project the current salary (which is {inputs.CurrentPen}) backwards by assuming that:
        </p>
        <ul> 
          <li> Nominal FTE pay rises of 2% occur on 1st April every year</li>
          <li> There are no other pay changes over time</li>
          <li> The current salary is the same as the salary the member had on the most recent 1st April </li>
        </ul>
      
      <p> The above is only true if the member did not provide a value for their 2015 salary. If they
        did provide a value, then the 2015 - present pay is based on linear interpolation between the 2015
        salary and the current salary
         </p>
         <p> 
          In the present case, the member {inputs['2015Pen'] === 0 ? 'HAS NOT ' : 'HAS '}provided a value for 2015 pension, and we 
          therefore {inputs['2015Pen'] === 0 ? 'DO NOT ' : 'DO '}apply linear interpolation for past salary
         </p>
         <p> For salaries after the present year, we take the post-reshaping FTE salary of {inputs.NewSal} and project
         it forwards, again assuming pay increases of 2% every 1 April. </p>
         <h3> Actual pay </h3>
         <p> 
          Usually, actual pay is simply FTE pay x the part-time percentage for the relevant year. However, in some years the 
          calculation is more complicated:
         </p>
          <ul> 
            <li> In the year when the individual retires, we need to do FTE pay x fraction of year worked x pt proportion 
              during fraction of year worked
            </li>
            <li> 
              In the year of partial retirement, we need to do `FTE pay for portion of the year before partial retirement` x
              `part-time percentage for portion of year before partial retirement` x `fraction of year before partial retirement` +
              `FTE pay after reshaping` x `Fraction of year after reshaping` x `part-time proportion in portion of year after reshaping`
            </li>
            <li> A further complication is that a member's part-time percentage can change multiple times over the course of a year.
            </li>
          </ul>
          <p>
          These factors should all be accounted for in the salary projects
         </p>

         <h3> Salary and CARE projections</h3>
         <p> The table below shows salary projections as derived based on the above.</p>
         <p> It also contains CARE projections</p>
         
         
         <table style={{ whiteSpace: 'normal' }}> 
            <thead>
                <tr>
                    <th>Year Start</th>
                    <th>Year End</th>
                    <th>FTE Salary</th>
                    <th>Actual Pay</th>
                    <th>Possible Retirement Dates</th>
                    <th>Pay if Retire This Year</th>
                    <th>Comment</th>
                    <th>Annual CARE</th>
                    <th>Cumulative CARE</th>
                    <th>Annual CARE if Retire this year</th>
                    <th>Cumulative CARE if Retire this year</th>
                </tr>
            </thead>
            <tbody>
                {
                    JSON.parse(from_backend.current['both salaries'][scenario]).map((salary, index) => {
                      
                      return(
                      <tr key={index}>
                      <td>{salary.year_start}</td>
                      <td>{salary.year_end}</td>
                      <td>{Number(salary.fte_salary).toFixed(1)}</td>
                      <td>{Number(salary.actual_pay).toFixed(1)}</td>
                      <td>{salary.possible_retirement_dates}</td>
                      <td>{Number(salary.actual_pay_if_retire_this_year).toFixed(1)}</td>
                      <td>{salary.comment}</td>
                      <td>{Number(salary.annual_care).toFixed(1)}</td>
                      <td>{Number(salary.cumulative_care).toFixed(1)}</td>
                      <td>{Number(salary.annual_care_if_retire_this_year).toFixed(1)}</td>
                      <td style = {{
                        'background' : salary.comment !== '' ? 'green' : 'white', 'color' : salary.comment !== '' ? 'white' : 'black'
                        }}>{Number(salary.cumulative_care_if_retire_this_year).toFixed(1)}</td>
                  </tr>
                    )
                  }
                    )
                }
            </tbody>
        </table>

        <p> In the table above, the most complicated value to calculate is the actual pay in the partial retirement year.
            The table below therefore provides more detail about how this is derived: The actual pay in the 
            current year should be the sum product of the 'Fraction of year worked' and 'FTE pay' columns.
            The 'actual pay if retire this year' in the partial retirement year should be the product of the FTE pay before reshaping
            and the fraction of the year worked before reshaping.
        </p>

        <table style={{ whiteSpace: 'normal' }}> 
            <thead>
                <tr>
                    <th>Timing</th>
                    <th>Fraction of year (calendar)</th>
                    <th>Fraction of year (days worked)</th>
                    <th>FTE pay</th>
                </tr>
            </thead>
            <tbody>
                {
                    JSON.parse(from_backend.current['both reshaping checks'][scenario]).map((value, index) => (
                      <tr key={index}>
                      <td>{value.timing}</td>
                      <td>{value['year fraction (calendar)']}</td>
                      <td>{value['year fraction (worked)']}</td>
                      <td>{value['fte salary']}</td>
                  </tr>
                    ))
                }
            </tbody>
        </table>

      <p> The 'fraction of year worked' column should reconcile with the member's part-time inputs.</p>
      
{inputs.partTimeService.length === 0 | inputs.HasPT === 'No' ? 

<p> In our case, the individual had no pre-partial retirement part-time working;
  their post-partial retirement part-time percentage is {inputs.postReshapingPT}%. </p> 
:
<p> In our case, you can see the member's pre-partial retirement part-time history in the table below;
  their post-partial retirement part-time percentage is {inputs.postReshapingPT}%.
</p>
}


{inputs.partTimeService.length > 0 && inputs.HasPT === 'Yes' &&
  <table style={{ whiteSpace: 'normal' }}> 
            <thead>
                <tr>
                    <th>From</th>
                    <th>To</th>
                    <th>Proportion</th>
                </tr>
            </thead>
            <tbody>
                {inputs.partTimeService.map((value, index) => (
                      <tr key={index}>
                      <td>{value.from}</td>
                      <td>{value.to}</td>
                      <td>{value.proportion}</td>
                  </tr>
                    ))
                }
            </tbody>
        </table>
 }

 <p> The salaries table above also shows CARE accrual over time, including annual revaluation of 3.5%.
The Cumulative CARE if retire this year' column tells us the CARE pension (pre-ERF) the individual would have accrued if they
FULLY retired in the relevant year. It equals the 'Cumulative CARE' of the previous year multiplied by 1.035, plus
the 'Annual CARE if Retire this year' value'. In turn, the 'Annual CARE if retire this year' value equals the 'Pay if retire this year'
times the accrual rate. The 'pay if retire this year' reflects the actual pay the individual would receive if they were to fully retire on
the relevant retirement date (i.e., the date within the year in question with the same month and day as their partial retirement date;
if their partial retirement date is 29 February then the associated dates for other years that are not leap years would be 28 February ). 
 </p>

 <p> In other words, we can determine the accrued CARE pension (pre-ERF) for retirement in a given year by looking up that year in the 
'Cumulative CARE if retire this year' column.
The partial retirement date in our case is {from_backend.current['prd']}, so the care pension in our case would be the value highlighted in green: 

<span style = {{color : 'white', background: 'green'}}> {care_pension.toFixed(1)}</span>
 </p>

 <p style = {{background: near(care_pension, care_pesnion_at_prd_if_fully_retire_on_prd, 20) ? 'green' : 'red', 'color' : 'white'}}> 
  Sense check: the corresponding value directly from the backend is: {care_pesnion_at_prd_if_fully_retire_on_prd}
 </p>

<h2> Legacy Pension</h2>

<p> Now that we have shown how we calculate the CARE pension in the partial retirement year (and in all future years if the individual had never partially retired), 
  we can move on to calculating their legacy pension in the partial retirement year. We get this with salary x service x accrual_rate.
</p>
<p> by 2022: {actual_service_by_apr_2022} by 2015 : {actual_service_by_apr_2015}</p>

{inputs.schemeName === 'Mental Health Officer' ? 
<p>
  However, we need to adjust the accrual rate to account for double accrual, which can get complicated if the member has complicated patterns of part-time working history.
  The calculation of double accrual requires:
</p>
:
<Fragment>
<p> Since the member in question is not an MHO, we don't need to worry about double accrual. Nevertheless, the table below
  is still helpful for calculating their actual service (we can ignore the last row in this case) </p>

<table style={{ whiteSpace: 'normal' }}> 
<thead>
    <tr>
        <th>From</th>
        <th>To</th>
        <th>Part-Time Proportion</th>
        <th>Service subject to single accrual</th>
        <th>Service subject to double accrual</th>
        <th>Actual service accrued</th>
    </tr>
</thead>
<tbody>
    {
        double_and_single_accrual_table.map((value, index) => (
            <tr key={index}>
                <td>{value.from}</td>
                <td>{value.to}</td>
                <td>{Number(value.proportion).toFixed(1)}</td>
                <td>{Number(value.service_subject_to_single_accrual).toFixed(1)}</td>
                <td>{Number(value.service_subject_to_double_accrual).toFixed(1)}</td>
                <td
                >{Number(value.actual_service).toFixed(1)}</td>
            </tr>
        ))
    }
</tbody>
</table>
</Fragment>
}

{inputs.schemeName === 'Mental Health Officer' &&
<Fragment>
<ul>
  <li> An 'assumed post-legacy-scheme part-time proportion'. For the purposes of calculating double accrual, this is the fraction of the 12 months prior
    to the end of the legacy scheme (which in our case ends on {scenario}) for which the individual worked. Note that if the individual left and rejoined the scheme (
      indicated by a proportion of '0' at a relevant point in their part-time history), then the period during which they were not part of the scheme does not count towards
      the 12 months. For example, if a member transition to CARE on 1 April 2015, then we would normally calculate their 'assumed post-legacy-schemepart-time proportion' as
      the fraction of the 12 months to 1 April 2015 that they worked. However, if, say, they were not in the scheme at all (i.e., had a part-time proportion of 0) from 1 July 2014
      to 1 January 2015, then we would not count that 6 month period as part of the 12 months. So if they had been full-time from early 2013 to 1 July 2014, and from 1 January 2015 to 
      1 April 2014, their 'assumed post-legacy-scheme part-time proportion' would still be 100%.
    
  </li>
  <li> 'service subject to single accrual.' The service that the member would have accrued up to their 55th birthday that would have been accrued BEFORE 20 years after their joining date.
    For the purpose of this calculation, we assume that from the end of the legacy schene onwards, the member would have worked at their 'assumed post-legacy-scheme part-time proportion', 
    even if we know that in actual fact they had a different part-time proportion after the end of the legacy scheme.  </li>
    <li> 'service subject to double accrual.' The service that the member would have accrued up to their 55th birthday that would have been accrued AFTER 20 years after their joining date.
    For the purpose of this calculation, we assume that from the end of the legacy schene onwards, the member would have worked at their 'assumed post-legacy-scheme part-time proportion', 
    even if we know that in actual fact they had a different part-time proportion after the end of the legacy scheme.  </li>
</ul>

<p> We then calculate adjusted_accrual_rate = (('service subject to single accrual x accrual_rate') + ('service subject to double accrual x accrual_rate x 2')) / (
  service subject to single accrual + service subject to double accrual) </p>

  <p> The table below shows the calculation of the 'assumed post-legacy-scheme part-time proportion'.
    It segments the period prior to the end of the legacy scheme into chunks associated with different part-time working patterns, so if the individual was always full-time or
    always had the same part-time percentage prior to the end of the legacy scheme, it will only have one row.
    The 'Relevant worked days' column shows the number of days that are part of the 12-month window for which the individual worked over each period,
     and the 'Relevant calendar days' shows the number of calendar days in the period that are part of the 12 month window.
     So the sum of the 'relevant worked days' column divided by the sum of the 'relevant calendar days' column gives us the 'assumed post-legacy-scheme part-time proportion'.
     </p> 

     <table style={{ whiteSpace: 'normal' }}> 
            <thead>
                <tr>
                    <th>From</th>
                    <th>To</th>
                    <th>Proportion</th>
                    <th>Relevant Calendar Days</th>
                    <th>Relevant Worked Days</th>
                </tr>
            </thead>
            <tbody>
                {
                    derivation_of_pt.map((value, index) => (
                        <tr key={index}>
                            <td>{value.from}</td>
                            <td>{value.to}</td>
                            <td>{Number(value.proportion).toFixed(1)}</td>
                            <td>{Number(value.relevant_calendar_days).toFixed(1)}</td>
                            <td>{Number(value.relevant_worked_days).toFixed(1)}</td>
                        </tr>
                    ))
                }
            </tbody>
        </table>

     <p>
     In this case, the calculation is:
     </p>
     <p>
     {sum_worked} / {sum_calendar}
  </p>
  <p> Which is: </p>
  <p style = {{color: 'white', 'background' : 'purple', 'fontSize' : '22px',
  'border' : '2px solid black',
  'borderRadius' : '4px', 'height' : '60px', 'width' : '200px', padding: '20px', 'textAlign' : 'center'}}>
     {Number(applicable_prop).toFixed(3)}</p>
  <p>(we use the same color styling to refer to this figure below)</p>

  
  <h2> Service subject to double and single accrual</h2>
  <p> Now that we have a part-time proportion to use for projecting service accrual after the end of the legacy scheme, we can calculate the amount of 
    service that would be subject to single or double accrual up to the member's 55th birthday. Keep in mind that the member would be aged 55
     on {from_backend.current['date aged 55']} and would have passed 20 years since their joining date on {from_backend.current['date reached 20 years']}
    
     </p> 


    <table style={{ whiteSpace: 'normal' }}> 
            <thead>
                <tr>
                    <th>From</th>
                    <th>To</th>
                    <th>Part-Time Proportion</th>
                    <th>Service subject to single accrual</th>
                    <th>Service subject to double accrual</th>
                    <th>Actual service accrued</th>
                </tr>
            </thead>
            <tbody>
                {
                    double_and_single_accrual_table.map((value, index) => (
                        <tr key={index}>
                            <td>{value.from}</td>
                            <td>{value.to}</td>
                            <td
                            
                            style = {
                              index === (double_and_single_accrual_table.length - 1) ? 
                              {color: 'white', 'background' : 'purple', 'fontSize' : '22px',
  'border' : '2px solid black',
  'borderRadius' : '4px', 'textAlign' : 'center'}
                              :
                              
                            {}}
                            >{Number(value.proportion).toFixed(1)}</td>
                            <td>{Number(value.service_subject_to_single_accrual).toFixed(1)}</td>
                            <td>{Number(value.service_subject_to_double_accrual).toFixed(1)}</td>
                            <td
                            >{Number(value.actual_service).toFixed(1)}</td>
                        </tr>
                    ))
                }
            </tbody>
        </table>

  
<p> So, our service subject to single accrual is {single_accrual}</p>
<p> service subject to double accrual is {double_accrual}</p>
<p> Actual service accrued by the end of the legacy scheme is {actual_service}</p>
<p> so the adjusted accrual rate is {adjusted_accrual_rate} </p>
</Fragment>
}
<p> So we can calculate the final salary (pre-ERF) if the member had fully retired on the partial retirement date as:</p>
<p> {adjusted_accrual_rate} x fte salary at partial retirement date x actual service accruedin the legacy scheme by partial retirement date </p>

<p> Which is:</p>
<p> {adjusted_accrual_rate} x {salary} x {actual_service} </p>
<p> Which is {fs_pension} </p>


<h2> Safe percentage </h2>
<p>  we can calculate the safe percentage
  as:
</p>
<p> 
  actual_service_up_to_april_2015 / actual_service_up_to_april_2022
</p>

<p> Which is: </p>
<p> 
  {actual_service_by_apr_2015} / {actual_service_by_apr_2022}
</p>
<p> (These values should reconcile with the sum of the 'actual service accrued' column in the table above when the care or legacy scenario is selected)</p>
<p> Which is: </p>
<p> {actual_service_by_apr_2015 / actual_service_by_apr_2022} </p>


<p> A rounded to the nearest percentage and then minus 5% is {safe_perc}, which you should see on the 'Your Details' page </p>
<p> Note that so far, we have not used ERFs. So the safe percentage is calculated on the basis of pre-ERF values </p>


<h2> Percentage of legacy and CARE pension drawn down at partial retirement</h2>
<p> Now that we have calculated the safe percentage and we know the percentage of benefits that the member wants to draw down at
  partial retirement, we can calculate the percentage of legacy and CARE benefits that they would draw down. Again, we still use pre-ERF
  values at this point.
</p>
{inputs.take_safe_percentage === 'No' ? <Fragment>
  <p> Since the member answered 'No' to the question asking whether they wanted to take their safe percentage, the 
    partial retirement percentage that they chose reflects the percentage of their total benefits that they will take at partial retirement.
  </p>

  {inputs.only_take_legacy === 'Yes' && 
  <Fragment> 
  <p> The member only wants to take legacy pension. So their partial retirement percentage is: </p>
  <p> accrued_legacy_pension_if_choose_legacy / (accrued_legacy_pension_if_choose_legacy + accrued_care_pension_if_choose_legacy)</p>
  <p> Which is:</p>
  <p>{fs_pension_choose_legacy} / ({care_pension_choose_legacy} + {fs_pension_choose_legacy}) </p>
  
  <p> Which is: {fs_pension_choose_legacy / (care_pension_choose_legacy + fs_pension_choose_legacy)} </p>

  {scenario === '2022-04-01' ? 
  <p> The CARE partial retirement percentage is 0 an the legacy partial retirement percentage is 1 </p> :
  <p> The legacy partial retirement percentage is 1 and the CARE partial retirement percentage is 

{( Math.max(0,(fs_pension_choose_legacy / (care_pension_choose_legacy + fs_pension_choose_legacy)) - (fs_pension / (fs_pension + care_pension)) ) * (fs_pension + care_pension)) / care_pension}
  </p>
}
  </Fragment>
  }
{inputs.only_take_legacy === 'No' && <Fragment> 
<p> The user wants to take {chosen_pr_percentage}% of their total accrued pension (excluding added years and added pension - we will deal with those later)
at partial retirement. </p>
<p> The percentage of legacy pension drawn down equals:</p>
<p> 
min(legacy_pension, (legacy_pension + care_pension) * partial_retirement_percentage) / legacy_pension
</p>
<p> Which is: </p>
<p> min({fs_pension}, ({fs_pension} + {care_pension}) * {chosen_pr_percentage/100}) / {fs_pension} </p>
<p> Which is: </p>
<p> {Math.min(fs_pension, (fs_pension + care_pension) * (chosen_pr_percentage/100))/fs_pension } </p>
<p
style = {{'background' : 

Math.min(fs_pension, (fs_pension + care_pension) * (chosen_pr_percentage/100))/fs_pension  === lp_perc ?
'green' :
'red', color : 'white'
}}
> Sanity Check: This is calculated directly in the backend as: {lp_perc}</p>
</Fragment>}
</Fragment> : 
<Fragment>
<p> Since the member answered 'Yes' to the question asking whether they wanted to take their safe percentage, the 
    partial retirement percentage that they chose reflects the percentage of their 1995 benefits accrued up to 2022
    that they want to take at partial retirement.
  </p>

  <p>
    The percentage of legacy pension taken at partial retirement (the 'legacy percentage') is:
     </p>

     <p> 
     min(1, (partial_retirement_percentage * legacy_pension_at_partial_retirement_if_choose_legacy)/legacy_pension_at_partial_retirement_in_scenario_we_are_checking)
      </p>

      <p> Which is:</p>

      <p> 
        min(1, ({chosen_pr_percentage/100} * {fs_pension_choose_legacy}) / {fs_pension})
      </p>

      <p> Which is:</p>

      <p> 
        {Math.min(1, (chosen_pr_percentage/100 * fs_pension_choose_legacy) / fs_pension)}
      </p>

      <p
      style = {{'background' : 

      near(Math.min(1, (chosen_pr_percentage/100 * fs_pension_choose_legacy) / fs_pension), lp_perc, 0.01) ?
      'green' :
      'red', color : 'white'
      }}
      > Sanity Check: This is calculated directly in the backend as: {lp_perc}</p>
   </Fragment>
}


<h3> Percentage of CARE benefits drawn down</h3>

{inputs.take_safe_percentage === 'No' && inputs.only_take_legacy === 'No' && <Fragment>
<p> Since the member chose not to take their safe percentage, their partial retirement percentage expresses the 
  percentage of their total benefits that they want to take at partial retirement </p>
  <p> The percentage of CARE benefits taken at the partial retirement date is therefore given as: </p>

<p> (((legacy_pension + care_pension) * partial_retirement_percentage) - (legacy_percentage_we_just_calculated * legacy_pension)) / care_pension </p>

<p> Which is: </p>

<p>  ((({fs_pension} + {care_pension}) * {chosen_pr_percentage/100}) - ({lp_perc} * {fs_pension})) / {care_pension}</p>

<p> Which is: </p>

<p>  {(((fs_pension + care_pension) * chosen_pr_percentage/100) - (lp_perc * fs_pension)) / care_pension}</p>

<p
style = {{'background' : 

near((((fs_pension + care_pension) * chosen_pr_percentage/100) - (lp_perc * fs_pension)) / care_pension, care_perc, 0.01) ?
'green' :
'red', color : 'white'
}}
> Sanity Check: This is calculated directly in the backend as: {care_perc}</p>

</Fragment>}  
{inputs.take_safe_percentage === 'Yes' &&
<Fragment> 
<p> Since the member chose to take the safe percentage, their partial retirement percentage
  expresses the percentage of 1995 schemes benefits that would be accrued up to April 2022 that they
  want to take at partial retirement. This definition holds even in the 'choose reformed' scenario, where
  we therefore still need to use the legacy pension they would have accrued in the 'choose legacy' scenario in the calculation.
   </p> 
   <p> The percentage of care benefits taken at partial retirement is simply zero</p>


   

     <p
style = {{'background' : 

near(0, care_perc, 0.01) ?
'green' :
'red', color : 'white'
}}
> Sanity Check: This is calculated directly in the backend as: {care_perc}</p>
  </Fragment>
}

{
  inputs.only_take_legacy === 'Yes' && inputs.take_safe_percentage === 'No' && scenario === '2022-04-01' &&
  <p> The member only wants to take legacy pension at partial retirement, so the care percentage is 0</p>
}
{
  inputs.only_take_legacy === 'Yes' && inputs.take_safe_percentage === 'No' && scenario === '2015-04-01' &&
  <Fragment>
  <p> The member only wants to take legacy pension at partial retirement, 
    so the care percentage is calculated as:
    ( Math.max(0,(fs_pension_choose_legacy / (care_pension_choose_legacy + fs_pension_choose_legacy)) - (fs_pension / (fs_pension + care_pension)) ) * (fs_pension + care_pension)) / care_pension

    which is { ' '} </p>
    <p>
      Which is: ( max(0,({fs_pension_choose_legacy} / ({care_pension_choose_legacy} + {fs_pension_choose_legacy})) - ({fs_pension} / ({fs_pension} + {care_pension})) ) * ({fs_pension} + {care_pension})) / {care_pension}
      </p>
      <p>
        Which equals:
    {( Math.max(0,(fs_pension_choose_legacy / (care_pension_choose_legacy + fs_pension_choose_legacy)) - (fs_pension / (fs_pension + care_pension)) ) * (fs_pension + care_pension)) / care_pension}
    
    
  </p>
  </Fragment>
}

<p> So the member {lp_perc < 1 ? 'Can choose whether to take their added years' : 'Must take their added years'} at 
partial retirement</p>
<p> They {care_perc === 1 ? 'must also take their added pension.': (care_perc > 0 ? 'can choose whether to take their added pension.' :
 'cannot take their added pension.' )}</p>

<p> We will henceforth refer to these values as 'legacy_percentage' and 'care_percentage'</p>

<h2> Lump sum range </h2>

<h3> Standard lump sum</h3>

<p> To calculate the standard lump sum that the user receives, we need to start using ERFs. We don't have the ERF functionality
  ready in the backend yet; we just use an ERF/LRF of '1' in all cases for the time being. To calculate the standard lump sum, we do: </p>

  <p> ( (automatic_lump_sum_factor * legacy_pension_at_partial_retirement * legacy_percentage) +
        (added_years_used * final_salary_at_partial_retirement * accrual_rate * automatic_ls_factor)) * 
        legacy_lump_sum_erf </p>

  <p> Which is: </p>

  <p> ((3 * {fs_pension} * {lp_perc}) + ({added_years} * {salary} * 0.0125 * 3)) * {pr_legacy_erf_ls} </p>

  <p> Which is: </p>

<p> {locally_calculated_standard_ls} </p>

<p
style = {{background : near(locally_calculated_standard_ls, standard_ls, 10) ? 'green' : 'red', color : 'white'}}
> Sanity check: calculated directly in the backend as: {standard_ls }</p>

<p> We will henceforth refer to this as 'standard_lump_sum'</p>

<h3> Maximum lump sum</h3>

<p> The maximum lump sum is given by: </p>

<p> standard_lump_sum + (legacy_pension_at_partial_retirement * max_legacy_commutation_rate * legacy_commutation_factor * 
  legacy_percentage * legacy_pension_erf) + (care_pension_at_partial_retirement * care_pension_erf * care_percent * 
  max_care_commutation_rate * care_commutation_factor)</p>

  <p> Which is: </p>

  <p> {standard_ls}+ ({fs_pension} * 0.1964 * 12 * {lp_perc} * {pr_legacy_erf}) + ({care_pension} * {pr_care_erf} * {care_perc} *
   0.3571 * 12)</p>

   <p> Which is: </p>

  <p> {locally_calculated_max_ls}</p>

  <p
style = {{background : near(locally_calculated_max_ls, max_ls, 200) ? 'green' : 'red', color : 'white'}}
> Sanity check: calculated directly in the backend as: {max_ls }</p>

<p> Note: the actual lump sum the user has chosen to take is: {actual_ls === 0 ? standard_ls : actual_ls} </p>

<h2> How much gets commuted </h2>

<p> Now that we know how much lump sum the member gets when they partially retire, we can determine how much needs to be commuted from their 
  CARE and legacy pension pots.
</p>

<p> Amount of legacy pension commuted is: </p>

<p>  min( (actual_lump_sum - standard_lump_sum) / legacy_commutation_factor,
   max_legacy_commutation_rate * legacy_pension_at_partial_retirement * legacy_percentage * legacy_ERF) </p>

   <p> Which is: </p>

   <p> min(({actual_ls} - {standard_ls}) / 12, 0.1964 * {fs_pension} * {lp_perc} * {pr_legacy_erf}) </p>

   <p> Which is: </p>

   <p> {locally_calculated_commuted_from_legacy} </p>

   <p
   style = {{background : near(locally_calculated_commuted_from_legacy, commuted_from_legacy, 200) ? 'green' : 'red', color : 'white'}}
   > Sense check: the same value directly from the backend is {commuted_from_legacy}</p>

  <p> Amount of care pension commuted is: </p>

  <p> ((actual_ls - standard_ls) - (commuted_from_legacy * legacy_commutation_factor)) / care_commutation_factor </p>

  <p> Which is: </p>

  <p> (({actual_ls} - {standard_ls}) - ({commuted_from_legacy} * 12)) / 12 </p>

  <p> Which is: </p>

  <p> {locally_calculated_commuted_from_care}</p>

  <p
   style = {{background : near(locally_calculated_commuted_from_care, commuted_from_care, 200) ? 'green' : 'red', color : 'white'}}
   > Sense check: the same value directly from the backend is {commuted_from_care}</p>


<h2> Pension at partial retirement</h2>

<p> We now have enough information to calculate care and legacy pension taken at partial retirement.</p>

<p> Legacy pension taken at partial retirement is:</p>

<p> (legacy_pension_at_partial_retirement * legacy_pension_erf * legacy_percentage - amount_commuted_from_legacy) + 
  added_years_used_at_partial_retirement * fte_salary_at_partial_retirement * accrual_rate * legacy_ERF_at_partial_retirement
</p>

<p> Which is: </p>

<p> ({fs_pension} * {pr_legacy_erf} * {lp_perc} - {commuted_from_legacy}) + (
  {added_years} * {salary} * {0.0125} * {pr_legacy_erf}
) </p>

<p> Which is: </p>

<p> {locally_calculated_lp_at_pr} </p>

<p
   style = {{background : near(locally_calculated_lp_at_pr, lp_at_pr, 200) ? 'green' : 'red', color : 'white'}}
   > Sense check: the same value directly from the backend is {lp_at_pr}</p>

<p> CARE pension at partial retirement is: </p>

<p> (care_pension_at_partial_retirement * care_pension_erf * care_percentage - amount_commuted_from_care) + 
  (added_pension_used_at_partial_retirement * added_pension_ERF_at_partial_retirement)</p>

<p> Which is: </p>

<p> ({care_pension} * {pr_care_erf} * {care_perc} - {commuted_from_care}) + ({added_pension} * {pr_added_pension_erf})</p>

<p> Which is: </p>

<p> {locally_calculated_cp_at_pr}</p>

<p
   style = {{background : near(locally_calculated_cp_at_pr, cp_at_pr, 200) ? 'green' : 'red', color : 'white'}}
   > Sense check: the same value directly from the backend is {cp_at_pr}</p>


<h1> Pension and lump sum in future years </h1>

<p> We have now calculated the pension and lump sum that the individual would receive when they partially retire. 
  Now let's calculate the extra pension and lump sum they would get when they fully retire in a given year. First
  we need to choose an Nth year after partial retirement. The numbers shown presently are for year {chosen_year}, but
  you change change this using the input below.
</p>

<select style = {{'margin' : '50px', 'height' : '100px', 'width' : '100px', 'color' : 'white', 'background' : 'blue', fontSize : '20px'}}
      value={chosen_year}
      onChange={(e) => set_chosen_year(e.target.value)}
    >
      {possible_years.map(year => (
        <option key={year} value={year}>
          {'Year ' + String(year)}
        </option>
      ))}
    </select>


<h3> FTE salary for Year {chosen_year} </h3>

<p> We can get the FTE salary for year {chosen_year} by reading the relevant value off the salaries table
presented close to the top of this page. The full retirement date would be {chosen_date}, 
so we read the FTE salary from that table for the period in which this dates falls; this should give a value of {Number(future_salary).toFixed(1)}
 </p>

 <h3> Other variables needed to calculate legacy pension in Year {chosen_year} </h3>

<p> As an intermediary step to determining the member's legacy pension upon full retirement on {chosen_date}, 
we need to determine what their legacy pension would have been if they fully retire on this date without previously having partially retired. To do this,
we repeat the steps used to calculate the legacy pension that the individual would have received if they had fully retired on the partial retirement date, but allowing
for a different retirement date. </p>

{inputs.schemeName === 'Mental Health Officer' ? 
<p> Since our member is a Mental Health offiver, we need to recalculate the adjusted accrual rate. The relevant table for this new calculation is shown below; note that
the assumed part-time proportion for the period after the legacy scheme has ended should be no different to the one we used when calculating legacy pension in
the partial retirement year  </p>

:
<p> The member is not a mental health officer, so again, we don't need to worry about double accrual. Nevertheless, the table
  below helps to show the derivation of actual service.
</p>
      }
<table style={{ whiteSpace: 'normal' }}> 
            <thead>
                <tr>
                    <th>From</th>
                    <th>To</th>
                    <th>Part-Time Proportion</th>
                    <th>Service subject to single accrual</th>
                    <th>Service subject to double accrual</th>
                    <th>Actual service accrued</th>
                </tr>
            </thead>
            <tbody>
                {
                    future_double_and_single_accrual_table.map((value, index) => (
                        <tr key={index}>
                            <td>{value.from}</td>
                            <td>{value.to}</td>
                            <td>{Number(value.proportion).toFixed(1)}</td>
                            <td>{Number(value.service_subject_to_single_accrual).toFixed(1)}</td>
                            <td>{Number(value.service_subject_to_double_accrual).toFixed(1)}</td>
                            <td>{Number(value.actual_service).toFixed(1)}</td>
                        </tr>
                    ))
                }
            </tbody>
</table>

<h3> Legacy pension in Year {chosen_year} if no partial retirement had occurred </h3>

{inputs.schemeName === 'Mental Health Officer' ?
<Fragment>
<p> So, based on the above, our service subject to single accrual is {future_single_accrual}</p>
<p> service subject to double accrual is {future_double_accrual}</p>
<p> Actual service accrued by the end of the legacy scheme is {future_actual_service}</p>
<p> so the adjusted accrual rate is {future_adjusted_accrual_rate} </p>
</Fragment>
:
<p> Actual service accrued by the end of the legacy scheme is {future_actual_service} </p>
}

<p> So we can calculate the final salary (pre-ERF) if the member had fully retired in Year {chosen_year} date as:</p>
<p> {future_adjusted_accrual_rate} x fte salary in Year {chosen_year} x actual service accruedin the legacy scheme by Year {chosen_year} </p>

<p> Which is:</p>
<p> {future_adjusted_accrual_rate} x {future_salary} x {actual_service} </p>
<p> Which is {future_fs_pension} </p>
<p> So this gives us the legacy pension (excluding added years and ERF) the individual would have received if they 
  fully retired in year {chosen_year} without having previously partially retired.
</p>

<h3> CARE pension in Year {chosen_year} if no partial retirement had occurred </h3>

<p> The care pension that the individual would have received if they fully retired on {chosen_date} - excluding ERFs and added pension - 
 can be read straight from the salary table near the top of this page; it is the value in the 'Cumulative CARE if Retire this year' column for the year that 
includes {chosen_date}; i.e., {Number(future_care_pension).toFixed(1)}
</p>

<h2> CARE and legacy pension that are actually received on full retirement given partial retirement</h2>

<p> Now we can alter the legacy and care values calculated immediately above to reflect the fact that the individual did actually partially
  retire, the relevant ERFs, and added years and added pension
</p>

<h3> Added years and added pension</h3>

<p> The added years that the user will benefit from when they fully retire equals 
  added_years_available - added_years_used_at_partial_retirement
</p>

<p> Which is: </p>

<p> ({available_added_years} - {added_years})</p>

<p> Which is: </p>

<p> {added_years_at_full_retirement}</p>


<p> Likewise, the added pension that the member receives at full retirement equals
added_pension_available - added_pension_used_at_partial_retirement </p>

<p> Which is: </p>

<p> ({inputs.AddedPen} - {added_pension})</p>

<p> Which is: </p>

<p> {added_pension_at_full_retirement} </p>

<h3> Legacy pension received in year {chosen_year} </h3>

<p> The legacy pension received upon full retirement on {chosen_date} is: </p>

<p> (pre_erf_legacy_pension_that_would_have_been_received_if_no_partial_retirement_had_occurred  *
   (1 - legacy_percentage) + added_years_at_full_retirement * salary_at_full_retirement * 
  accrual_rate) * legacy_erf_at_full_retirement </p> 

  <p> Which is: </p>

  <p> ({future_fs_pension} * 
  (1 - {lp_perc}) + {added_years_at_full_retirement} * {future_salary} * 0.0125) * {fr_legacy_erf}
   </p>

   <p> Which is: </p>

   <p> {locally_calculated_future_lp}
   </p>

   <p
   style = {{background : near(locally_calculated_future_lp, actual_lp_at_fr, 200) ? 'green' : 'red', color : 'white'}}
   > Sense check: the same value directly from the backend is {actual_lp_at_fr}</p>

   <h3> Lump sum upon full retirement in year {chosen_year} </h3>

   <p> Lump sum upon full retirement in year {chosen_year} is: </p>

   <p> ((legacy_pension_received_on_full_retirement * automatic_lump_sum_factor) / full_retirement_legacy_erf) * full_retirement_legacy_erf_ls </p>
   
   <p> Which is: (({actual_lp_at_fr} * 3) /{fr_legacy_erf}) * {fr_legacy_erf_ls} </p>

   <p>Which is: </p>

   <p> {locally_calculated_lump_sum_at_fr} </p>

   <p
   style = {{background : near(locally_calculated_lump_sum_at_fr, actual_ls_at_fr, 200) ? 'green' : 'red', color : 'white'}}
   > Sense check: the same value directly from the backend is {actual_ls_at_fr}</p>

   <h3> CARE received on full retirement in year {chosen_year} </h3>

   <p> The CARE that the individual would have received on {chosen_date} if they had not previously partially retired was 
   calculated above as {future_care_pension} </p>

   <p> To determine the member's actual full retirement care pension, we cannot simply remove extra revaluation added on account of pension 
    that was withdrawn at partial retirement, multiply  by (1 - care_percentage), apply relevant ERFs and added any relevant added pension.
    Why? Because the (1 - care_percentage) multiplier fails to account for the fact that <span style = {{'fontWeight' : '900'}}>
      The member continued accruing care pension while partially retired. </span>
    
     </p> 
     <p> For example, consider what would happen if the individual had taken 100% of their benefits at partial retirement.
      Then, 1-care_percentage would be 0, in which case the calculation described in the paragraph above would only give us the 
      added pension, and nothing else, at full retirement - it would not include care pension accrued post-partial retirement. </p>

      <p> Therefore, to calculate the care pension that the member receives at full retirement, we first calculate the 
      <span style = {{'fontWeight' : '900'}}> pre-erf care accrued at partial retirement revalued to the full retirement year</span>
      </p>

      <p> If we subtract this from 'care pension at full retirement given no prior partial retirement' (i.e., from {future_care_pension}), 
      this will tell us how much care pension the individual would have left in their pot at full retirement, given that some was removed 
      at partial retirement. We then multiply by the relevant full retirmeent ERF, add any relevant added pension * added_pension_full_retirement_erf, 
      and this gives us the care pension that the member would receive at full retirement.
       </p>

      <p> As noted above, the pre-erf, pre-commutation care pension accrued by the time the member had partially retired can just be
        read off the table presented at the start of this checking section; it is: </p> 
       
       <p> {care_pension}</p>

       <p> We need to get the portion of this taken at partial retreiment and then revalue to the full retirement date,
        so we need to do {care_pension} * care_percentage * revaluation_factor ^ {chosen_year}</p>

       <p> which is {care_pension} * {care_perc} * {1.015} ^ {chosen_year} </p>

       <p> which is {locally_calculated_revalued_pr_care} </p>
       <p style = {{background : near(locally_calculated_revalued_pr_care, revalued_pr_care, 100) ? 'green' : 'red', color : 'white' }}>
        Sense check: The value taken directly from the backend is {revalued_pr_care}
         </p>

       <p> The 'care_pension_accrued_at_full_retirement_given_no_partial_retirement' can also be read off the table; it is: </p>

       <p> {future_care_pension} </p>

       <p> So now we need to do 'care_pension_accrued_at_full_retirement_given_no_partial_retirement' minus
        'pre-erf, pre-commutation care drawn down at partial retirement', which will give us the pre-erf, pre-commutation
        CARE amount available at full retirement. Then, we multiply by the erf at full retirement and add added pension available
        at full retirement (we don't do any care commutation at full retirement)
       </p>

       <p> This is:
       (pre_erf_care_pension_at_full_retirement_given_no_partial_retirement - 
       pre_erf_pre_commutation_care_pension_taken_at_partial_retirement_revalued_to_full_retirement_year) * 
       care_erf_at_full_retirement + (added_pension_at_full_retirement * added_pension_erf_at_full_retirement)

       </p>

        <p> This is:

        {future_care_pension} - {locally_calculated_revalued_pr_care} * {fr_care_erf}  + ({added_pension_at_full_retirement} * {fr_added_pension_erf})
        </p>

      <p> This is {locally_calculated_cp_at_fr}</p>

      <p> The corresponding value directly from the backend is {actual_cp_at_fr}</p>
      </Fragment>}

{info_modal !== '' && <InfoModal
modalHandler = {() => set_info_modal('')}
infoMessage = {modal_content[info_modal]}
/> }


   
    </div>
  );
}

export default ResultsPage;
