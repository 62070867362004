import React, { useContext, useEffect, useState, useRef } from "react";
import { InputDataContext } from "../../../shared/contexts/InputDataContext";
import classes from "./ServiceInputs.module.css";
import InfoModal from "../../general/InfoModal";
import FullDateDropdown from "./FullDateDropdown"
import { sanitisePercentString, processPercentDelete } from "../../../shared/utilities/Formatters";

const PartTimeServiceInput = (props) => {
  
  const {scrollPositions } = useContext(InputDataContext);

  console.log(props.part_time_service)
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [percentage, setPercentage] = useState("");
  const error = props.error
  const setError = props.setError
  const first_mount = useRef(true)

  function startOfDay(date) {
    // Assume date is a string in 'yyyy-mm-dd' format
    return new Date(Date.UTC(date.split('-')[0], date.split('-')[1] - 1, date.split('-')[2]));
  }

  useEffect(
    () => {
      if(first_mount.current) {
        first_mount.current = false
    } else {
      props.set_currently_being_answered('PT')
    }
      
    }, 
    [startDate, endDate, percentage]
  )

  //When we reset, we need to make sure that we are changing the end or start date from what is was 
  //before. So if it was previously at 'reset1' or 'reset2', we need to keep it at a string starting 'reset', 
  //but still change its value. Otherwise, the useEffect in FullDateDropdown.js won't be triggered
  //on the second reset.
  const reset_mapper = {
    'reset1' : 'reset2', 
    'reset2' : 'reset1'
  }

  const Reset = () => {
    setEndDate((prev) => ['reset1', 'reset2'].includes(prev) ? reset_mapper[prev] : 'reset1')
    setStartDate((prev) => ['reset1', 'reset2'].includes(prev) ? reset_mapper[prev] : 'reset1')
    setPercentage("")
    setError("")
  }

  const addService = () => {
    
    console.log('startDate', startDate)
    console.log('endDate', endDate)
    const start_date = startOfDay(startDate)
    const end_date = startOfDay(endDate)

    const newService = {
      from: start_date,
      to: end_date,
      proportion: percentage, 
    };


    if (start_date >= end_date) {
      
      setError("Start date must be before end date.");
      return;
    }

    
    if(
      end_date > startOfDay(props.inputsRef.current.DPR)
    ) {
      setError("You should only provide your part-time working pattern up to your partial retirement date (" + props.formatDate(props.inputsRef.current.DPR) + "),  " +
        "but your 'end date' is later than this. You can use the next question to specify your post-partial retirement part-time working pattern." + 
        "If you want to change your partial retirement date, click 'Submit Answer' and then click the 'Retirement Date' icon in the progress bar." +
        "Then, once you have changed your partial retirement date, navigate back to this question and click 'Submit Answer' again."   
      );
      return;
    }



    if (percentage < 0 || percentage > 100) {
      setError("Percentage must be between 0 and 100.");
      return;
    }

    if(percentage === "") {
      setError("Invalid percentage")
      return
    }

    if(start_date < startOfDay(props.inputsRef.current.DJS)) {
      setError(
        "Your start date cannot be before your joining date" + 
        "If you want to change your joining date, click 'Submit Answer' and then click the 'Joining Date' icon in the progress bar." +
        "Then, once you have changed your joining date, navigate back to this question and click 'Submit Answer' again."  
        ); 
      //this is the last error we check for. So we know that if it occurs, it is the only error present
      return;
    }

    props.set_part_time_service([
      ...props.part_time_service,
      { from: start_date, to: end_date, proportion: percentage },
    ]);

    setStartDate("");
    setEndDate("");
    setPercentage("");
    setError("");
  };
 
  const removeService = (index) => {
    props.set_part_time_service(props.part_time_service.filter((_, i) => i !== index));
  };

 

  
  return (
    <div className={classes.service_container}>
      <div className = {classes.pre_table}> 
        
        <section> 
        <h3>Tell us about your part-time working history</h3>
        
        </section>
        <div className={classes.subContainer}>
          <div className={classes.inputContainer}>
            <div className={classes.label_input}>
              <label className = {classes.date_label}>Start:</label>
              <FullDateDropdown
                    existing_value = {startDate}
                    onChange={(e) => setStartDate(e.target.value)}
                    scrollPositions = {scrollPositions['PTStart']}
                    min_year = {(new Date(props.inputsRef.current.DJS)).getFullYear()}
                    max_year = {2026}
                    go_red = {error !== ''}
                    set_currently_being_answered = {
                      () => props.set_currently_being_answered('PT')
                  }
                />
              
            </div>
            <div className={classes.label_input}>
              <label
              className = {classes.date_label}
              >End:</label>
              <FullDateDropdown
                    existing_value = {endDate}
                    onChange={(e) => setEndDate(e.target.value)}
                    scrollPositions = {scrollPositions['PTEnd']}
                    min_year = {(new Date(props.inputsRef.current.DJS)).getFullYear()}
                    max_year = {2026}
                    go_red = {error !== ''}
                    set_currently_being_answered = {
                      () => props.set_currently_being_answered('PT')
                  }
                />
            </div>
            <div className={classes.label_input} 
            style = {{
              //'position' : 'relative'
            }}
            >
              <label className = {classes.date_label}>Percent (%):</label>
              <input
                className = {classes.percent_input}
                type="text"
                value={(percentage === 0 || percentage ) ? percentage + '%' : ''}
                onChange={(e) => {

                  const old_val = percentage === '' ? '' : percentage + '%'
        
                  var new_val = processPercentDelete(e.target.value, old_val).replace('%', '')
        
                  new_val = sanitisePercentString(new_val)
        
                  setPercentage(sanitisePercentString(new_val))
        
                  }
                }
                min={0}
                step={1}
                max={99}
              />
            </div>
          </div>

        </div>
        <div className = {classes.add_service_container}> 
        <button onClick={addService}>Add Service</button>
        <button onClick={Reset}> Reset </button>
        </div>
        <p>
          Enter each period of part-time service using the fields above. 
          Click 'Explain' for more information about how to input part-time service.
        </p>
        
        
      </div>
      <div className = {classes.table_container}> 
        <table>
          <thead>
            <tr>
              <th className={classes.leftColumn}></th>
              <th>Start Date</th>
              <th>End Date</th>
              <th>Proportion</th>
              <th className={classes.rightColumn}></th>
            </tr>
          </thead>
          <tbody>
            {props.part_time_service.map((service, i) => (
              <tr key={i}>
                <td className={classes.leftColumn}>{i + 1}.</td>
                <td>{new Date(service.from).toLocaleDateString("en-GB")}</td>
                <td>{new Date(service.to).toLocaleDateString("en-GB")}</td>
                <td>{service.proportion}%</td>
                <td className={classes.rightColumn}>
                  <button onClick={() => removeService(i)}>X</button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
        {error && <p className = {classes.error}>{error}</p>}
      </div>
    </div>
  );
};

export default PartTimeServiceInput;
