import React, { Fragment, useState, useEffect, useContext, useRef } from "react";
import classes from './PartTimeSection.module.css'
import {formatDate} from "../../../shared/utilities/Formatters"
import PartTimeServiceInput from "./PartTimeServiceInput"

import InfoModal from "../../../components/results/switches/InfoModal"

const PartTimeSection = (props) => {

    const date_to_string = (x) => {
        console.log('date going in: ', x);
        try {
          // Extracting the date parts in UTC
          const year = x.getUTCFullYear();
          const month = x.getUTCMonth() + 1; // Adjust month
          const day = x.getUTCDate();
          const dateString = `${year}-${month.toString().padStart(2, '0')}-${day.toString().padStart(2, '0')}`;
      
          console.log('date being returned: ', dateString);
          return dateString;
        } catch (e) {
          console.log('error:', e);
          console.log('date being returned:', x);
          return x;
        }
      }

    const safe_replace = (x) => {
        try {
            return Number(x.proportion.replace('%', ''))
        }   catch {
            return Number(x)
        }
        
    }
    
    const pre_pt = props.inputsRef.current.PT
    if(pre_pt === 'None') {
        var this_pt = []
        var this_has_pt = 'N'
    } else {
        var this_pt = pre_pt === '' ? [] : pre_pt
        var this_has_pt = pre_pt.length > 0 ? 'Y' : ''
    }
    const [part_time_service, set_part_time_service] = useState(this_pt)
    const [has_pt, set_has_pt] = useState(this_has_pt)
    const [error, setError] = useState('')
    
    const [show_modal, set_show_modal] = useState(false)

    const check_validity = (x, y) => {
        if(x === 'N') {
            return ['valid', '']
        }

        if(y !== '') {
            return [
                'error', 
                y
            ]
        }

        return [
            'valid', 
            ''
        ]
    }
    const first_mount = useRef(true)

    useEffect(
        () => {
            if(first_mount.current) {
                first_mount.current = false
            } else {
                const is_valid = check_validity(has_pt, error)
                props.responsesRef.current = ['PT', is_valid[0]]

                if(has_pt === 'Y') {
                   
                    props.current_answer.current = part_time_service.map((x) => {
                        return(
                            {'from' : date_to_string(x.from),  
                            'to' : date_to_string(x.to), 
                            'proportion' : safe_replace(x.proportion)
                        }
                        )
                    })
                } else {
                    props.current_answer.current = 'None'
                }
            props.set_currently_being_answered('PT')
            }
        }, 
        [has_pt, part_time_service, error]
    )
    return(
        <div className = {classes.main_container}>
            <div className = {classes.first_question_container}
            style = {{
                'transform' : 'translateY(' + (has_pt === 'Y' ? '0' : '270%') + ')'
            }}
            >
                <h2> Have you ever worked part-time?</h2>
                <div className = {classes.options_container}>
                    <div className = {classes.yes_no_container}> 
                    <button
                    className = {(has_pt === 'Y' ? classes.pressed : classes.unpressed)}
                    onClick = {() => {set_has_pt('Y'); props.innerHasPTRef.current = 'Yes'}}
                    > Yes</button>
                    <button
                    className = {(has_pt === 'N' ? classes.pressed : classes.unpressed)}
                    onClick = {() => {set_has_pt('N'); props.innerHasPTRef.current = 'No'}}
                    > No </button>
                    </div>
                    <div className = {classes.explain_container}>
                    <button
                    onClick = {() => {set_show_modal(true)}}
                    > Explain
                    </button>
                    
                    {show_modal && 
                    <InfoModal
                    modalHandler = {() => set_show_modal(false)}
                    infoMessage = {props.modal_content}
                    />
                    }
                    </div>
                </div>
                
            </div>

            <div className = {classes.part_time_section_container} style = {{'zIndex' : (has_pt === 'Y' ? 1 : -100)}}> 
            <div className = {classes.part_time_section}
            style = {{'opacity' : (has_pt === 'Y' ? 1 : 0), 'zIndex' : (has_pt === 'Y' ? 1 : -10)}}
            > 
            <PartTimeServiceInput
                formatDate = {formatDate}
                part_time_service = {part_time_service}
                set_part_time_service = {set_part_time_service}
                error = {error}
                setError = {setError}
                inputsRef = {props.inputsRef}
                set_currently_being_answered = {props.set_currently_being_answered}
            />
            </div>
            </div>

        </div>
    )
}

export default PartTimeSection