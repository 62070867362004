import React, { Fragment, useState, useEffect, useContext, useRef } from "react";
import classes from './AddedPensionInput.module.css'
import PrettyTextInput from "./PrettyTextInput"
import { formatToPoundNoRounding } from "../../../shared/utilities/Formatters";
import InfoModal from "../../../components/results/switches/InfoModal"

const AddedPensionInput = (props) => {
    const [needs_added_pension, set_needs_added_pension] = useState(
        props.inputsRef.current.AddedPension === 'None' ?
        'N' :
        (
            props.inputsRef.current.AddedPension === '' ? 
            '' :
            'Y'
        )
        )
    const [added_pension, set_added_pension] = useState(
        props.inputsRef.current.AddedPension.replace('£', '').replace(',', '')
        )
    const [valid, set_valid] = useState('NA')
    const first_mount = useRef(true)
    const rerendered_after_chose_yes = useRef(-1)
    const [show_modal, set_show_modal] = useState(false)

    const modal_content = <Fragment>
    <h1> 2015 Scheme added pension </h1>
    <p>
    Additional pension is a way of increasing your pension benefits where you can choose to buy a set amount of annual pension and pay either a lump sum or regular contributions from your pay.
    </p>
    </Fragment>

    if(needs_added_pension === 'Y') {
        rerendered_after_chose_yes.current += 1
    }

    function removeCommasAndPounds(inputString) {
        return inputString.replace(/[,£]/g, '');
    }

    const check_validity = (x, y) => {
        if(
            (x === 'xxx'  || x === '' || x === '0' || x === 0 || x === '£0') &&
            y === 'Y'
        ) {
            return [
                'error', 
                'If you have purchased added pension, you must specify a number'
            ]
        }

        if(Number(removeCommasAndPounds(x)) >= 100000 && y === 'Y') {
            return [
                'error', 
                "The added pension you've entered is too high."
            ]
        }

        return [
            'valid', 
            ''
        ]
        
    }

    useEffect(
        () => {
            if(first_mount.current) {
                first_mount.current = false
            } else {
                const is_valid = check_validity(added_pension, needs_added_pension)
                props.responsesRef.current = ['AddedPension', is_valid[0]]
                set_valid(is_valid)

                if(needs_added_pension === 'N' || added_pension === 'xxx') {
                    props.current_answer.current = 'None'
                } else {
                    props.current_answer.current = formatToPoundNoRounding(added_pension || '')
                }
                props.set_currently_being_answered('AddedPension')
            }
        },
        [added_pension, needs_added_pension]
    )

    return(
        <div className = {classes.main_container}>
            <div className = {classes.first_question_container}>
                <h3> Have you ever purchased added pension?</h3>
                <div className = {classes.button_container}> 
                <button
                onClick = {() => set_needs_added_pension('Y')}
                className = {(needs_added_pension === 'Y' ? classes.pressed : classes.unpressed)}
                > Yes </button> 
                <button
                onClick = {() => set_needs_added_pension('N')}
                className = {(needs_added_pension=== 'N' ? classes.pressed : classes.unpressed)}
                > No </button>
                </div>
            </div>
            <div className = {classes.input_container }> 
                <div className = {classes.added_pension_input + ' ' + (needs_added_pension === 'Y' ? '' : classes.hidden)}> 
                    <PrettyTextInput
                        type = {'currency'}
                        label = {'Added Pension'}
                        value ={added_pension}
                        onChange = {(e) => {set_added_pension(e.target.value)}}
                        showError = {valid[0] === 'error' && rerendered_after_chose_yes.current > 1}
                        error = {valid[1]}
                        left_margin = {'10px'}
                    />
                </div>
            </div>
            <div className = {classes.explain_container}> 
            <button
            onClick = {() => {set_show_modal(true)}}
            > Explain what this question means
            </button>
            {show_modal && 
            <InfoModal
            modalHandler = {() => set_show_modal(false)}
            infoMessage = {modal_content}
            />
            }
            </div>
            {valid[0] === 'error' && rerendered_after_chose_yes.current > 1 && <p className = {classes.error}> {valid[1]}</p>}
        </div>
    )
}

export default AddedPensionInput