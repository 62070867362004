import React, { Fragment, useState, useEffect, useContext, useRef } from "react";
import classes from './QuestionSlider.module.css'
import OptionsInput from "./OptionsInput";
import DateInput from "./DateInput";
import NumberInput from "./NumberInput";
import AddedYearsInput from "./AddedYearsInput";
import AddedPensionInput from "./AddedPensionInput";
import PartTimeSection from "./PartTimeSection";
import PostReshapingPTInput from "./PostReshapingPTInput";
import RangeInput from "./RangeInput";
import { addNYears, startOfDay } from "../../../shared/utilities/Formatters";
import InfoModal from "../../../components/results/switches/InfoModal"
import PartTimeExplainer from "./PartTimeExplainer";
import CalculateButton from "../CalculateButton";

const validation_functions = {
    'DOB' : (x) => {
        const max_dob = addNYears(new Date(), -54) //we need their prd to be within one year of today. If they are < 54, this would entail retiremnet pre-age 55.

        const min_dob = startOfDay(new Date('1953-06-01'));// would have an SPA of about 65, making their SPA date about 2019, meaning that we can 
        //project full retirement as late as 2027 without going more than 9 years beyond their SPA. The key here is that we only have LRF's for CARE up to 9 years
        //after the SPA

        const dob = startOfDay(new Date(x))

        if(dob === 'Invalid Date') {
            return [
                'error', 
                'Enter a valid date'
            ]
        }

        if(dob < min_dob) {
            return [
                'error', 
                "Your date of birth cannot be before June 1953 because this illustrator cannot account for some of the special circumstances that would be applicable."
            ]
        }

        if(dob > max_dob) {
            return [
                'error', 
                "Your cannot use this illustrator if you are less than 54 years old."
            ]
        }
 
        return ['valid', '']
    }, 
    'DJS' : (DJS, DOB, Status) => {
        
        const dob = startOfDay(new Date(DOB))
       
        var min_djs = addNYears(dob, 16); // can't have joined before they were 16 years old.
        
        
        const djs = new Date(DJS)
        if(djs < min_djs) {
            return [
                'error', 
                "Your joining date can't be less than 16 years after your birthday. If you need to change your date of birth, click 'Submit Answer' and then navigate back to the D.O.B input by clicking its icon in the progress panel."
            ]
        }

        var max_djs;
        
        if (Status === "Mental Health Officer" || Status === "Special Class Officer" ) {
            max_djs = startOfDay(new Date('1995-03-06'));

            if(djs > max_djs) {
                return [
                    "error",
                    "We would expect MHO and Special Class members to have joined before 6 March 1995, as such please check you have entered the correct date of joining. If your joining date is correct, click 'Submit Answer' and then click on the 'Status' box in the progress bar and update your status to ‘Neither of these’ in order to progress further."
                ] 
            }
        }
        else {
            max_djs = startOfDay(new Date('2012-03-31')) // if joined after this date, not eligible for remedy

        }
        if(djs > max_djs) {
            return [
                "error",
                "Your joining date can't be after 1st April 2012 - if you joined after this date, you are not eligible for the McCloud remedy"
            ] 
        }

        return ['valid', '']
    }, 
    'DPR' : (DPR, DOB) => {
        const dob = startOfDay(new Date(DOB))
        const dpr = startOfDay(new Date(DPR))

        if(dpr < startOfDay(new Date())) {
            return [
                'error', 
                'Your partial retirement date must be today or in the future'
            ]
        }

        if(dpr < addNYears(dob, 55)) {
            
            return [
                'error', 
                'You cannot use this calculator if you are partially retiring before age 55'
            ]
        }

        if(dpr > addNYears(new Date(), 1)) {
            return [
                'error', 
                'Your partial retirement date cannot be more than 1 year after today'
            ]
        }

        return [
            'valid', 
            ''
        ]

    }
}

const calculateWorkingPercentage = (partTimeService, retDate) => {
    const MS_PER_DAY = 1000 * 60 * 60 * 24;

    // Helper to calculate days between dates, inclusive of start date
    const daysBetween = (startDate, endDate) => {
      return Math.max(
        (new Date(endDate) - new Date(startDate)) / MS_PER_DAY + 1,
        0
      );
    };

    // Get the start of the 12 month period
    let startOfPeriod = new Date(retDate);
    startOfPeriod.setFullYear(startOfPeriod.getFullYear() - 1);
    startOfPeriod.setDate(startOfPeriod.getDate() + 1);

    let totalDays = daysBetween(startOfPeriod, retDate);
    let daysNotWorked = 0;

    partTimeService
      .filter(
        (service) =>
          new Date(service.to) >= startOfPeriod &&
          new Date(service.from) <= new Date(retDate)
      )
      .forEach((service) => {
        let from =
          new Date(service.from) < startOfPeriod
            ? startOfPeriod
            : new Date(service.from);
        let to =
          new Date(service.to) > retDate ? retDate : new Date(service.to);

        let daysInService = daysBetween(from, to);
        let partTimeDaysNotWorked =
          daysInService * (1 - Number(service.proportion) / 100);

        daysNotWorked += partTimeDaysNotWorked;
      });

    return (1 - daysNotWorked / totalDays) * 100;
  };

const modalContent = {
    'SafePercentage' : <Fragment> 
       <h1>  What is the safe percentage? </h1> 
    <p> The safe percentage is the percentage of your pension that you could withdraw at partial retirement below which you can avoid the risk of needing to repay some of the payments you receive at a later date.
    </p>
    <p> 
    If you take more than the “safe percentage”, there is a risk that you may have overpayments to repay when you make your McCloud choice later on.
    </p>
    <p>
    You cannot make your McCloud choice yet. However, when you can make your McCloud choice you will be able to revisit the proportion of your benefits you have chosen to take, if this is within the safe percentage.
    </p>
    
    </Fragment>, 
    'OnlyLegacy' : <Fragment> 
        <h1> Only taking legacy benefits at partial retirement </h1>
    <p> 
    If you select 'yes' for this option, we will assume that if you pick the 'choose 1995 Section' option for the McCloud remedy, you will draw down on all of your legacy scheme benefits at partial retirement and none of your 2015 Scheme benefits.
    </p> 
    <p> 
    And when modelling your pension under the 'choose 2015 Scheme' option for the McCloud remedy, we will set the percentage benefits to be withdrawn at partial retirement to be the same percentage as in the 'choose 1995 Section' scenario. However, since you will have less legacy pension and more CARE pension available in the 'choose 2015 Scheme' scenario, this means that all of your legacy pension and some of your CARE pension will be drawn down at partial retirement in the 'choose 2015 Scheme' scenario.
    </p>
    </Fragment>, 
    'DJS' : <Fragment> 
        <h1> Joining date </h1>
        <p> This should be the date you joined the scheme. </p>

        <p> If you left and then joined again at a later date, put the earliest joining date.
        </p>
        <p> 
        Then, for any periods of time during which you were not part of the scheme, you can enter a part-time percentage of 0% in the question about part-time working that will appear below.
        </p>
    </Fragment>, 
    'CurrentSalary' : <Fragment> 
        <h1> Pensionable earnings at current date </h1>
        <p> This should be pensionable earnings and should be the Full-Time Equivalent amount. For example, if you work three days per week and earn £15,000 pa for those three days, then your FTE would be what you would earn if you worked five days, in this case £25,000. Please note that final pensionable pay used in this illustrator is based on gross pay at the point of retirement which differs from the actual final pensionable pay definitions that will be used at retirement.
        </p>
    </Fragment>, 
    'Salary2015' : <Fragment> 
        <h1> Pensionable earnings as at April 2015 </h1>
        <p> This should be your pensionable earnings and it should be the full-time equivalent amount. If you don’t know what your salary was on 1 April 2015, enter ‘0’ and the calculator will use assumptions to estimate your salary on this date.
        </p>
        </Fragment>, 
    'FutureSalary' : <Fragment>
       <h1>  New pensionable salary </h1>
        <p> 
            This should be your expected pensionable salary after partial retirement. To take partial retirement, members must reduce the annual rate of their pensionable pay by 10% or more of their pensionable pay received in the previous 12 months.
        </p>
    </Fragment>, 
    'PT' : <Fragment> 
    <h1> Part-time working history</h1>
    <p> Please enter the start and end dates for any periods of part-time working. The ‘percentage’ should be the proportion of whole-time equivalent hours worked over the period in question (so if whole-time hours per week for the role is 40, and you worked 20 hours per week, the percentage is 50%)</p>
    <p> You do not have to enter dates in chronological order, but the 'from' date cannot before the date you joined the pension scheme, and the 'to' date cannot be later than
        you partial retirement date.
    </p>
    <p> If you provide overlapping periods of service and this results in you having a proportion of more than 100% over a certain period, our calculations will adjust this to 100%</p>
  </Fragment>, 
    'BenefitsWithdrawn' : <Fragment>
    <h2> Percentage of benefits taken </h2>
    <p>
    You’ll be able to take from 20% to 100% of all your pension benefits in one or two drawdown payments, without having to leave your current job. If you have benefits in both the 1995/2008 Section and 2015 Scheme, the percentage of benefits you apply to take will be taken from your 1995/2008 Section benefits first. Then they’ll be taken from your 2015 Scheme benefits.
    </p>
    <p> 
    If you take more than the “safe percentage”, there is a risk that you may have overpayments to repay when you make your McCloud choice later on.
    </p>
    </Fragment>
}

const SafePercentageTextContent = (props) => {
    const [show_sp_modal, set_show_sp_modal]= useState(false)
    
    return(
        <Fragment>
        <p>
        <span> Would you like to take the safe percentage* at partial retirement? Using the information that you have provided above, we have calculated the safe percentage as <span style = {{'fontWeight' : 'bold'}}> {props.safe_percentage}% </span> in your case. If you select ‘no’, you will be able to choose a percentage of your total benefits between 20% and 100% at partial retirement. </span>
        </p>
        <div 
        onClick={() => set_show_sp_modal(true)}
        >
            <h2> What is the safe percentage? (click to enlarge)</h2>
        <p> *The "safe percentage" is the percentage of your pension that you could withdraw at partial retirement below which you can avoid the risk of needing to repay some of the payments you receive at a later date.
        If you take more than the “safe percentage”, there is a risk that you may have overpayments to repay when you make your McCloud choice later on.
        You cannot make your McCloud choice yet. However, when you can make your McCloud choice you will be able to revisit the proportion of your benefits you have chosen to take, if this is within the safe percentage.
        </p>
        {show_sp_modal  && <InfoModal 
        modalHandler = {(e) => {set_show_sp_modal(false); e.stopPropagation()}}
        infoMessage = {modalContent.SafePercentage}
        />}     
        </div>
    </Fragment>
    )
}

const QuestionSlider = (props) => {

    const nth_slide = props.nth_slide
    const set_nth_slide = props.set_nth_slide
    const current_answer = props.current_answer
    const [track_dob, set_track_dob] = useState('')
    const [track_status, set_track_status] = useState('')
    const [djs, set_track_djs] = useState('')
    const [safe_percentage, set_safe_percentage] = useState(props.safe_percentage_ref.current)
    const hasPTRef = useRef('No')
    const innerHasPTRef = useRef('No')
    const [show_salary_warning, set_show_salary_warning] = useState(false)
    const show_salary_warning_ref = useRef(true)

    const from_backend = props.from_backend

    const cleanPTRef = useRef(null);
    const [show_clean_pt, set_show_clean_pt] = useState(false);
    const PTAdjustedRef = useRef(false)

    useEffect(
        () => {
            props.safe_percentage_ref.current = safe_percentage
        }, 
        [safe_percentage]
    )

    useEffect(
        () => {
            //when we mount, we want current_answer.current to be incomplete
            //if we are mounting after having returned from the results page
            if(current_answer.current !== '') {
                current_answer.current = 'incomplete'
            }
        }, []
    )
  
    const cleanPTInputs = async () => {
      if (current_answer.current && current_answer.current !== 'incomplete') {
        try {
          const response = await fetch(window.HOST_URL + '/api/clean_dates', {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({ pt: current_answer.current }),
          });
          if (!response.ok) {
            throw new Error('Network response was not ok');
          }
          const result = await response.json();
          cleanPTRef.current = result['cleaned_dates']; // Update anotherRef with the result
          PTAdjustedRef.current = result['adjusted']
          set_show_clean_pt(true); // Update state to indicate fetch completion
        } catch (error) {
          console.error('There was a problem with the fetch operation:', error);
        }
      } else {
        console.log('current_answer.current is null');
      }
    }

    //function that calcultes the new safe percentage when it is possible to do so
    const safe_percentage_api = async (input_data) => {
        const data_being_sent_to_backend = {
            DOB : input_data.DOB,
            scheme : input_data.Status,
            DJS : input_data.DJS, 
            partial_retirement_date : input_data.DPR,
            salary_2015 : input_data.Salary2015.replace('£', '').replace(',', ''), 
            salary_today : input_data.CurrentSalary.replace('£', '').replace(',', ''),
            post_reshaping_salary : input_data.FutureSalary.replace('£', '').replace(',', ''),
            post_reshaping_pt : input_data.PostPT.replace('%', ''),
            pt : input_data.PT  === 'None' ? [] : input_data.PT,
            HasPT : hasPTRef.current
    }
    console.log('data being sent to backend', data_being_sent_to_backend)
        try {
            const response = await fetch(window.HOST_URL + '/api/safepercentage', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(
                    data_being_sent_to_backend
                ),
        });
        if (!response.ok) {
            throw new Error('Network response was not ok');
          }
          const data = await response.json();

          console.log('backend returned: ', data)
            from_backend.current['safe percentage'] = data['safe percentage']
            from_backend.current['true safe percentage'] = data['true safe percentage']
            from_backend.current['all results'] = data['all results']
            from_backend.current['both salaries'] = data['both salaries']
            from_backend.current['prd'] = data['prd']
            from_backend.current['all_ret_dates'] = data['all_ret_dates']
            from_backend.current['all_ret_dates_end'] = data['all_ret_dates_end']
            from_backend.current['both reshaping checks'] = data['both reshaping checks']
            from_backend.current['date aged 55'] = data['date aged 55']
            from_backend.current['date reached 20 years'] = data['date reached 20 years']
            from_backend.current['first year indexes'] = data['first year indexes']
            set_safe_percentage(data['true safe percentage']*100)
            from_backend.current['results populated'] = true
            from_backend.current['cleaned_pt'] = data['cleaned_pt'] ? data['cleaned_pt'] : (input_data.PT === 'None' ? [] : input_data.PT)

        } catch (error) {
            console.log('There was a problem with the safe percentage api')
        }
    }
    
    const n_remaining = 14 - Object.keys(props.responses).filter(
        key => (['valid', 'redundant'].includes(props.responses[key]) && (key !== 'undefined') )
        ).length;

    const show_calculate_button = safe_percentage !== '' && (n_remaining === 0) && props.currently_being_answered === ''

    
    return(
        <div className = {classes.question_slider}> 
            <div className = {classes.main_container}> 
                <div className = {classes.slider_container}> 
                    <div className = {classes.slider}
                     style = {{'transform' : 'translateX(calc( ' + -nth_slide + ' * (1 / 14) * 100% ))'}}
                     >
                        <div className = {classes.slide}> 
                        <OptionsInput
                        options = {['Mental Health Officer', 'Special Class Officer', 'Neither of these']}
                        question_text = "Let us know if you have Mental Health Officer or Special Class Status:"
                        tracker = 'Status'
                        responsesRef = {props.responsesRef}
                        current_answer = {current_answer}
                        set_track_status = {set_track_status}
                        inputsRef = {props.inputsRef}
                        set_currently_being_answered = {props.set_currently_being_answered}
                        />
                        </div>
                        <div className = {classes.slide}>
                            <DateInput
                            scrollPositions = {props.scrollPositions}
                            question_text = {'What is your date of birth?'}
                            tracker = 'DOB'
                            responsesRef = {props.responsesRef}
                            current_answer = {current_answer}
                            check_validity = {validation_functions.DOB}
                            min_year = {1953}
                            max_year = {1971}
                            set_track_dob = {set_track_dob}
                            inputsRef = {props.inputsRef}
                            set_currently_being_answered = {props.set_currently_being_answered}
                            />
                           
                        </div>
                        <div className = {classes.slide}> 
                            <DateInput
                            scrollPositions = {props.scrollPositions}
                            question_text = {'What date did you join the NHS Pension Scheme?'}
                            tracker = 'DJS'
                            responses = {props.responses}
                            set_responses = {props.set_responses}
                            responsesRef = {props.responsesRef}
                            current_answer = {current_answer}
                            check_validity = {
                                (DJS) => validation_functions.DJS(
                                    DJS, props.inputsRef.current.DOB, props.inputsRef.current.Status
                                )
                            }
                            min_year = {
                                1953
                            }
                            max_year = {2012}
                            set_track_djs = {set_track_djs}
                            dependencies = {[track_dob, track_status]}
                            inputsRef = {props.inputsRef}
                            modal_content = {modalContent.DJS}
                            set_currently_being_answered = {props.set_currently_being_answered}
                            />
                        </div>
                        <div className = {classes.slide}>
                            <DateInput
                            scrollPositions = {props.scrollPositions}
                            question_text = {'What date would you like to take partial retirement?'}
                            tracker = 'DPR'
                            responsesRef = {props.responsesRef}
                            set_responses = {props.set_responses}
                            current_answer = {current_answer}
                            min_year = {2024}
                            max_year = {2026}
                            inputsRef = {props.inputsRef}
                            dependencies = {[track_dob]}
                            check_validity = {
                                (DPR) => validation_functions.DPR(
                                    DPR, props.inputsRef.current.DOB
                                )
                            }
                            set_currently_being_answered = {props.set_currently_being_answered}
                            />
                        </div>
                        <div className = {classes.slide}> 
                            <NumberInput
                            question_text = {'What are your current full-time equivalent, pensionable earnings?'}
                            need_currency = {true}
                            label = {'Current earnings'}
                            tracker = 'CurrentSalary'
                            responsesRef = {props.responsesRef}
                            current_answer = {current_answer}
                            min_value = {1}
                            modal_content = {modalContent.CurrentSalary}
                            inputsRef = {props.inputsRef}
                            set_currently_being_answered = {props.set_currently_being_answered}
                            
                            />
                        </div>
                        <div className = {classes.slide}>
                            <NumberInput
                            question_text = {'What were your full-time equivalent pensionable earnings in April 2015? Enter 0 if you don’t know.'}
                            need_currency = {true}
                            label = {'Earnings in 2015'}
                            tracker = 'Salary2015'
                            responsesRef = {props.responsesRef}
                            current_answer = {current_answer}
                            modal_content = {modalContent.Salary2015}
                            inputsRef = {props.inputsRef}
                            set_currently_being_answered = {props.set_currently_being_answered}
                            />
                             </div>
                        <div className = {classes.slide}> 
                            <AddedYearsInput
                            responsesRef = {props.responsesRef}
                            current_answer = {current_answer}
                            inputsRef = {props.inputsRef}
                            set_currently_being_answered = {props.set_currently_being_answered}
                            />
                        </div>
                        <div className = {classes.slide}> 
                            <AddedPensionInput
                            responsesRef = {props.responsesRef}
                            current_answer = {current_answer}
                            inputsRef = {props.inputsRef}
                            set_currently_being_answered = {props.set_currently_being_answered}
                            />
                        </div>
                        <div className = {classes.slide}> 
                            <NumberInput
                            question_text = {'How much will your full time equivalent pensionable salary be, after your job re-shaping for partial retirement?'}
                            need_currency = {true}
                            label = {'Post-reshaping earnings'}
                            responsesRef = {props.responsesRef}
                            tracker = 'FutureSalary'
                            current_answer = {current_answer}
                            modal_content = {modalContent.FutureSalary}
                            inputsRef = {props.inputsRef}
                            set_currently_being_answered = {props.set_currently_being_answered}
                            />
                        </div>
                        <div className = {classes.slide}> 
                        <PartTimeSection
                        responsesRef = {props.responsesRef}
                        current_answer = {current_answer}
                        inputsRef = {props.inputsRef}
                        modal_content = {modalContent.PT}
                        innerHasPTRef = {innerHasPTRef}
                        set_currently_being_answered = {props.set_currently_being_answered}
                        
                        />
                        </div>
                        <div className = {classes.slide}>
                            <PostReshapingPTInput
                            responsesRef = {props.responsesRef}
                            current_answer = {current_answer}
                            inputsRef = {props.inputsRef}
                            set_currently_being_answered = {props.set_currently_being_answered}
                            />
                             </div>
                        <div className = {classes.slide}> 
                            <OptionsInput
                            options = {['Yes', 'No']}
                            question_text = {'Safe Percentage'}
                            body_text = {
                                <SafePercentageTextContent
                                safe_percentage = {safe_percentage}
                                />
                            }
                            responsesRef = {props.responsesRef}
                            tracker = 'SafePercentage'
                            current_answer = {current_answer} 
                            safe_percentage = {safe_percentage}
                            inputsRef = {props.inputsRef}
                            set_currently_being_answered = {props.set_currently_being_answered}
                            />
                        </div>
                        <div className = {classes.slide}> 
                            <OptionsInput
                            options = {['Yes', 'No']}
                            question_text = {'Only withdraw legacy benefits at partial retirement'}
                            body_text = {"Would you like to take all of your 1995 section benefits and none of your 2015 Scheme benefits at partial retirement?"}
                            responsesRef = {props.responsesRef}
                            tracker = 'OnlyLegacy'
                            current_answer = {current_answer}
                            modal_content = {modalContent.OnlyLegacy}
                            inputsRef = {props.inputsRef}
                            set_currently_being_answered = {props.set_currently_being_answered}
                            />
                        </div>
                        <div className = {classes.slide}> 
                            <RangeInput
                            responsesRef = {props.responsesRef}
                            current_answer = {current_answer}
                            inputsRef = {props.inputsRef}
                            modal_content = {modalContent.BenefitsWithdrawn}
                            set_currently_being_answered = {props.set_currently_being_answered}
                            />
                        </div>
                    </div>
                </div>
            </div>
            <div 
            className = {classes.submit_container + ' ' + (show_calculate_button ? classes.calculate_button_revealed : '')}
            > 

            <button onClick = {
                () => {
                        
                        const previous_slide = Math.max(0, nth_slide - 1)
                        const all_items = Object.keys(props.responses)
                        const previous_item = all_items[previous_slide]
                        const hasError = Object.values(props.responses).some(value => value === 'error')
                        
                    if((props.currently_being_answered === '') || hasError) {
                        
                        set_nth_slide(previous_slide)
                        props.responsesRef.current = [previous_item, props.responses[previous_item]]
                        current_answer.current = 'incomplete'
                    }
                    
                }
                }> Back</button>

            {
                show_calculate_button ? 
                    <CalculateButton
                    /> 
                    : <button
                    className = {classes.dummy_button}
                    > </button>
            }

            <button
            onClick = {() => { 
                const next_slide = Math.min(13, nth_slide + 1)
                const all_items = Object.keys(props.responses)
                const this_slide = all_items[nth_slide]
                const next_item = all_items[next_slide]

                var need_next_slide = props.responses[next_item] !== 'valid' 
                var need_to_jump = true
                var new_responses

                if(this_slide === 'PT') {
                    
                    if(current_answer.current.length > 1 && current_answer.current !== 'None') {
                        cleanPTInputs()
                    } else {
                        cleanPTRef.current = current_answer.current
                    }
                }

                if(this_slide === 'SafePercentage' || this_slide === 'OnlyLegacy') {
                    if(this_slide === 'SafePercentage') {
                        if(
                            current_answer.current === 'Yes' || 
                            (props.inputsRef.current.SafePercentage === 'Yes' && current_answer.current === 'incomplete')
                            ) {
                            new_responses = {...props.responses, 
                                'OnlyLegacy' : 'redundant', 
                                'BenefitsWithdrawn' : 'redundant', 
                                'SafePercentage' : 'valid'
                                }
                            props.set_responses(
                                new_responses
                            )
                            need_next_slide = false
                            need_to_jump = false
                        } 

                        if(current_answer.current === 'No') {
                            new_responses = {...props.responses, 
                                'OnlyLegacy' : props.inputsRef.current.OnlyLegacy ? 'valid' : 'NA', 
                                'BenefitsWithdrawn' : props.inputsRef.current.BenefitsWithdrawn ? 'valid' : 'NA', 
                                'SafePercentage' : 'valid'
                                }
                            props.set_responses(
                                new_responses
                            )
                        }
                    } else {
                        if(current_answer.current === 'Yes'  ||
                        (props.inputsRef.current.OnlyLegacy === 'Yes' && current_answer.current === 'incomplete')
                        ) {
                            need_next_slide = false
                            need_to_jump = false
                            new_responses = {...props.responses, 
                                'OnlyLegacy' : 'valid', 
                                'BenefitsWithdrawn' : 'redundant'
                                }
                            props.set_responses(
                                new_responses
                            )
                        } 

                        if(current_answer.current === 'No') {
                            new_responses = {...props.responses, 
                                'OnlyLegacy' : 'valid', 
                                'BenefitsWithdrawn' : props.inputsRef.current.BenefitsWithdrawn ? 'valid' : 'NA',
                                }
                            props.set_responses(
                                new_responses
                            )
                        }
                    }
                } else {
                    if(this_slide === 'PT') {
                        hasPTRef.current = innerHasPTRef.current
                    }

                    new_responses = {...props.responses, [props.responsesRef.current[0]] : props.responsesRef.current[1]}

                    const can_calculate_safe_percentage = Object.keys(new_responses).filter(
                        (key, index) => (['valid', 'redundant'].includes(new_responses[key]) && (key !== 'undefined') && (index < 11) )
                    ).length;
                    if(can_calculate_safe_percentage === 11) {
                        if(current_answer.current !== 'incomplete') {
                            safe_percentage_api(
                                {...props.inputsRef.current, [this_slide] : current_answer.current}
                            )
                        } else {
                            safe_percentage_api(props.inputsRef.current)
                        }
                        
                    } 

                    props.set_responses(
                        new_responses
                    )
                    
                }
                
                if(current_answer.current !== 'incomplete') {
                    var new_input_ref = {
                        ...props.inputsRef.current, [this_slide] : current_answer.current
                    }
                    props.inputsRef.current = new_input_ref
                    
                    if(props.responsesRef.current[1] === 'valid') {
                        if(['PostPT', 'PT', 'FutureSalary', 'CurrentSalary', 'DPR'].includes(this_slide) && show_salary_warning_ref.current) {
                            if(new_input_ref.PT !== 'None' && new_input_ref.PT !== '' && new_input_ref.PT.length > 0) {
                                var pt_over_12_months_to_ret_date = calculateWorkingPercentage(
                                new_input_ref.PT, new_input_ref.DPR
                              ) / 100;
                              } else {
                                var pt_over_12_months_to_ret_date = 1
                              }
                              var post_pt = new_input_ref.PostPT.replace('%', '')
                              var future_sal = new_input_ref.FutureSalary.replace('£', '').replace(',', '')
                              var current_sal = new_input_ref.CurrentSalary.replace('£', '').replace(',', '')
                          
                              if(
                                ((Number(post_pt)/100 * Number(future_sal)) / (Number(current_sal) * pt_over_12_months_to_ret_date)) > 0.9 
                                && show_salary_warning_ref.current
                                ) {
                                set_show_salary_warning(true)
                                show_salary_warning_ref.current = false
                              }
                          }
                    }

                    
                }

                if(props.responsesRef.current[1] === 'valid') {
                    props.set_currently_being_answered('')

                    if(need_next_slide) {
                        set_nth_slide(next_slide)
                        current_answer.current = 'incomplete'
                        console.log('setting responses ref in qs')
                        props.responsesRef.current = [next_item, new_responses[next_item]]
                    } else if(need_to_jump) {
                        const jump_to = all_items.findIndex(key => ['NA', 'error'].includes(new_responses[key]) );

                        if(jump_to !== -1) {
                            set_nth_slide(jump_to)
                            const next_item = all_items[jump_to]
                            current_answer.current = 'incomplete'
                            console.log('setting responses ref in qs')
                            props.responsesRef.current = [next_item, new_responses[next_item]]
                        }
                    }
                }

                
                }
            }
            > Submit Answer</button>
            
            
            </div>
           {show_clean_pt && PTAdjustedRef.current && <InfoModal 
            modalHandler = {() => set_show_clean_pt(false)}
            infoMessage = {<PartTimeExplainer
                            inputsRef= {props.inputsRef}
                            cleanPTRef = {cleanPTRef}
                            set_show_clean_pt = {set_show_clean_pt}
                            />}
            />}
            {show_salary_warning &&
            <InfoModal 
                modalHandler = {() => set_show_salary_warning(false)}
                infoMessage = {<Fragment> 
                <h1> Post-reshaping earnings more than 90% of pre-shaping earnings</h1>
                <p> Your post-partial retirement earnings are more
                    than 90% of your pre-partial retirement earnings (after accounting for part-time working),
                    which is not permitted. </p>
                <p> Once you close this window, it won't show again. </p>
                </Fragment>}
            />
            }
        </div>
    )
}

export default QuestionSlider