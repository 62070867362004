import React, { Fragment } from "react";
import { Link } from "react-router-dom";
import params from "../../shared/utilities/global_parameters";

export const TCText = [
  {
    id: "T&Cs",
    text: (
      <Fragment>
        <h1>Terms and Conditions</h1>
        <p>
          By using the {params.app_name_short}, you agree you have read and 
          understood the following Terms and Conditions.
        </p>
        <h2>1. Use of this {params.app_name_short}</h2>
        <p>
          The {params.app_name_short} calculator is for guidance and it only 
          provides estimates. It is not intended to provide financial advice. 
          If you need financial advice, speak to an independent financial 
          adviser – you can find one at unbiased.co.uk. The Government Actuary’s 
          Department (GAD), your employer and your pension scheme administrators 
          do not accept any liability for financial decisions taken based on the 
          information provided by this {params.app_name_short}.
        </p>
        <p>
        The figures you get are an indication of the benefits you may receive. 
        They are not guaranteed, and you should not enter into financial 
        commitments based on them.
        </p>
        <p>
          The {params.app_name_short} does not override the scheme rules or
          pension legislation.
        </p>
        <h2>2. How your pension and lump sum have been estimated</h2>
        <h3>
          2.1. Comparison of 1995 Section and 2015 Scheme benefits
        </h3>
        <p>
        The results section shows a comparison of pension and lump sum benefits 
        that might be received if you choose either 1995 Section or 2015 Scheme 
        benefits for the remedy period, which is from 1 April 2015 to 31 March 
        2022. Member contributions are not included in the figures.
        </p>
        <h3>2.2. Assumptions</h3>
        <p>
        The pension and lump sum estimates are based on the following assumptions:
        </p>
        <ul style = {{'fontSize' : '17px'}}>
          <li>Consumer Price Index (CPI) is assumed to be 2% a year</li>
          <li>
          Pensions in payment are assumed to increase at a rate of 2% a year
          </li>
          <li>
          Your total 2015 pension is adjusted in line with prices (CPI) +1.5% every year that you are an active member of the 2015 Scheme.
          </li>
           <li>Salaries are assumed to increase at a rate of 2% a year</li> 
        </ul>
        <h3>2.3. Adjustments for early or late payment of pension</h3>
        <p>The amount of pension you receive depends on when you retire. </p>
        <p>
        Early retirement may result in a reduction in pension benefits to account for the fact that the benefits will be paid over a longer period of time. This calculator applies adjustments when pension and lump sum are taken earlier or later than the relevant scheme’s Normal Pension Age, in line with the scheme rules. 
        </p>
        <p>
        The early and late retirement adjustment factors used in this calculator are based on the factors currently in force. Late retirement factors do not apply to 1995 Section membership. These will be reviewed from time to time and may increase or decrease. Your benefits at retirement will depend on any adjustment factors at the time.
        </p>
        <h3>2.4. Cash lump sum</h3>
        <p>
        The 1995 Section provides an automatic lump sum of three times your pension. Members are also able to exchange some of their pension for a larger lump sum. This is called commutation. You are eligible to commute pension from both your 1995 Section and 2015 Scheme benefits, subject to terms and conditions.  
        </p>
        <p>
        This calculator allows you to select an amount of lump sum between the minimum and maximum amounts available. The figures shown for the lump sum amount received at full retirement, assume that no additional commutation of pension has taken place.
        </p>
        <h2>3. Taxation</h2>
        <p>
          The illustrations do not allow for taxation. Your benefits will be
          subject to the various tax rates and limits in force.
        </p>

        <h2>4. Other </h2>
        <p>The results do not include any money purchase Additional Voluntary Contributions (AVCs) benefits which you may have purchased, pension debits or other special arrangements within the schemes.</p>

        <p>The calculator does not show pension from other sources, for example the state pension or other private pensions you may have.</p>

        <p>Your scheme provides survivor benefits payable in the event of your death, which are not shown here. See your scheme guide for details. Your benefit statement may also include more about this.</p>

        <p>No allowance has been made for guaranteed minimum pension (GMP) and any impact that GMP has on when you can take your pension benefits.</p>
        <p> 
        For Mental Health Officers, the calculator makes allowance for double accrual and Uniform Accrual Formulae in a necessarily approximate way and may not fully reflect all circumstances. For members whose projected calendar length exceeds 40 years before age 55, no allowance has been made for any restrictions of membership that may apply in calculating these benefits and an estimate should be requested from NHS Pensions.
        </p>
        <p>
        No allowance has been made for scenarios where the added years or additional pension is due to come into payment at a different age from the relevant scheme’s normal retirement age. It has been assumed that added years and additional pension is due to come into payment in line with the relevant scheme’s normal retirement age. 
        </p>
        <p> 
        It has been assumed the normal retirement age for Mental Health Officers is age 55. There are some cases where Mental Health Officers may have a normal retirement age of 60 but no allowance has been made for such cases in this calculator.
        </p>
        <p> 
        Members who have a mixture of non-MHO and MHO posts are excluded from the calculator and use of this modeller may overstate the benefits they are eligible to receive.
        </p>
        

      </Fragment>
    ),
  },
];

export const SmallPrintText = [
  {
    id: "SP",
    text: (
      <Fragment>
        <h2>How to use this calculator</h2>
        {/* <p>
          The {params.app_name_long} is currently available as a pilot.
        </p> */}
        <p>
        It will not check your information against pension or other systems,
        so if you enter inaccurate information, the figures you get will be 
        of limited value. No data you enter into this calculator is stored 
        or processed.
        </p>
        <p>
          By using this {params.app_name_short}, you agree that you have read
          and understood the Terms & Conditions as set out in the{" "}
          <Link to="/T&Cs">T&Cs' tab</Link>.
        </p>
        <p>
          This {params.app_name_short} is for guidance and illustration - 
          it will give you a basic illustration of your projected benefits at 
          partial retirement based on both 1995 Section and 2015 Scheme benefits. 
          Things such as tax debits are not taken into account. If you have these, 
          or other additional options, the figures you get may be less accurate, 
          but still helpful. The outputs of the calculator are not financial 
          advice - if you’d like to speak to an independent financial adviser 
          you can find one at <a href="https://www.unbiased.co.uk/">unbiased.co.uk</a>.
        </p>
        <p>The assumptions we have used are:</p>
        <table>
          <tbody>
            <tr>
              <td>Consumer Price Index</td>
              <td>2% per year</td>
            </tr>
            <tr>
              <td>Earnings inflation</td>
              <td>2% per year</td>
            </tr>
            {/* <tr>
              <td>General pay increases</td>
              <td>2% per year</td>
            </tr> */}
          </tbody>
        </table>
        <p>
        The figures output by the calculator are projected benefits and will not
         be the same as those in your Annual Benefit Statement.
        </p>
        <p>
        The projected benefits in this calculator are in “today's money” – 
        this means the values are adjusted for inflation and represent what 
        the pension would be worth today, so you can compare it with your 
        current earnings and the amount you think you’ll need in retirement.
        </p>
      </Fragment>
    ),
  },
];

export const IntroText = [
  {
    id: "IT",
    text: (
      <Fragment>
        <h1>Welcome to the NHS (Scotland) Partial Retirement Calculator</h1>
        
        <p> Please note this calculator only allows members to take partial retirement from age 55 onwards due to additional complexities with the Pension Increase Act. However, members of the 1995 section with service pre 2006 can apply from age 50. Eligible members who apply from age 50 should be aware they will need to take 100% of their pension if they retire before they are 55. Special Class or Mental Health Officer members with a protected normal pension age of 55, who retire before age 55, will lose their protected benefit status and their normal pension age would revert to age 60 instead of age 55.</p>
          <p>From 1 October 2023, the regulations changed so members of the 1995 Section could take partial retirement and all members of the NHS Pension Scheme can claim up to 100% of their pension benefits when they partially retire. You can find more about partial retirement at 
          <a href="https://pensions.gov.scot/nhs/ready-retirement/partial-retirement">  https://pensions.gov.scot/nhs/ready-retirement/partial-retirement </a>
          </p>

          <p>If you have membership in the 1995 Section, this calculator enables you to see the effect partial retirement could have on your salary and pension income. It will give you a basic illustration of your projected benefits at partial retirement based on both 1995 Section and 2015 Scheme benefits. It allows you to see an approximate comparison based on the information you input and uses certain assumptions about what will happen in future in most, but not all, circumstances.</p>

          <p>This calculator does not provide illustrations for members in the 2008 Section or those who only have service in the 2015 Scheme.</p>
          
          {/* <p style = {{'fontWeight' : 'bold'}}>This calculator does not cover members in the 2008 scheme or those with service only in the 2015 scheme.</p> */}

          <h2>Questions you may want to ask</h2>

          
      </Fragment>
    ),
  },
];
