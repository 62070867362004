import React from "react";
import NavBar from "./NavBar";
import SPPA_LOGO from "../../assets/images/SPPA_LOGO.png";
import params from "../../shared/utilities/global_parameters";
import classes from "./Header.module.css";

const Header = () => {
  return (
    <header className={classes.headerContainer}>
      <div className={classes.imgContainer}>
        {true && <img
          src={SPPA_LOGO}
          className={classes.img}
          alt="Government Actuary's Department Logo"
        />}
      </div>
      <div className={classes.mainHeader}>
        <h1 className={classes.pageTitle}>{params.app_name_long}</h1>
        <NavBar />
      </div>
      
    </header>
  );
};

export default Header;
