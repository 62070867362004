import React, { Fragment } from "react";

function TextBlock(props) {
  return (
    <Fragment>
      {props.blocks.map((block) => (
        <section key={block.id}>{block.text}</section>
      ))}
    </Fragment>
  );
}

export default TextBlock;
