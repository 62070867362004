import React from "react";
import classes from './PartTimeExplainer.module.css'
import { formatDate } from "../../../shared/utilities/Formatters";

const PartTimeExplainer = (props) => {
    return(
        <div className = {classes.main_container}> 
        <h1> Part-time input warning</h1>
        <p> When combining the periods of part-time service that you provided, 
            it looks like there are some periods where you were working more than
            100% of full-time equivalent hours. We have adjusted this so that in our
            calculations, we assume that you would only have worked a maximum of 100%
            of full-time equivalent hours during any period.
        </p>
        <div className = {classes.inner_container}>
            <div className = {classes.first_container} >
                <h1>Service you inputted</h1>
                <table>
                    <thead>
                    <tr>
                        
                        <th>Start Date</th>
                        <th>End Date</th>
                        <th>Proportion</th>
                        
                    </tr>
                    </thead>
                    <tbody>
                    {props.inputsRef.current.PT.map((service, i) => (
                        <tr key={i}>
                        <td>{formatDate(service.from, 'short')}</td>
                        <td>{formatDate(service.to, 'short')}</td>
                        <td>{service.proportion}%</td>
                        
                        </tr>
                    ))}
                    </tbody>
            </table>
            </div>
            <div className = {classes.second_container}> 
                <h1>How we will use it in our calculations</h1>
                <table>
                <thead>
                <tr>
                    
                    <th>Start Date</th>
                    <th>End Date</th>
                    <th>Proportion based on your inputs</th>
                    <th>Adjusted proportion used in calculations</th>
                    
                </tr>
                </thead>
                <tbody>
                {props.cleanPTRef.current.map((service, i) => (
                    <tr key={i}>
                    <td>{formatDate(service.from, 'short')}</td>
                    <td>{formatDate(service.to, 'short')}</td>
                    <td>{service.proportion}%</td>
                    <td>{service.adjusted_proportion}%</td>
                    
                    </tr>
                ))}
                </tbody>
            </table>
            </div>
        </div> 
        </div>
    )
}

export default PartTimeExplainer