import React, { Fragment, useState } from "react";
import { useLocation } from "react-router-dom";
import Header from "../components/layout/Header";
import TermsModal from "../components/layout/TermsModal";

function Layout(props) {
  const location = useLocation();

  const RESULTS_PATHNAME = "/results";

  const [showModal, setShowModal] = useState(true);

  const closeModalHandler = () => {
    setShowModal(false);
  };

  return (
    <Fragment>
      <Header />
      <main>{props.children}</main>
      {showModal && <TermsModal onClick={closeModalHandler} />}
    </Fragment>
  );
}

export default Layout;
