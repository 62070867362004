import React, { Fragment, useState, useEffect, useContext, useRef } from "react";
import classes from './RangeInput.module.css'
import FancySlider from "../../results/switches/FancySlider";
import InfoModal from "../../../components/results/switches/InfoModal"

const RangeInput = (props) => {
    const [value, set_value] = useState(
        Math.max(20, Number(props.inputsRef.current.BenefitsWithdrawn.replace('%', '')))
        )
    const [show_modal, set_show_modal] = useState(false)
    const check_validity = (x) => {
        const ret = x === '' ? 'NA' : 'valid'
        return(ret)
    }
    const first_mount = useRef(true)
    
    useEffect(
        () => {
            if(first_mount.current) {
                first_mount.current = false
            } else {
                const is_valid = check_validity(value)
                props.responsesRef.current = ['BenefitsWithdrawn', is_valid]
                props.current_answer.current = value + '%'
                props.set_currently_being_answered('BenefitsWithdrawn')
            }
        }, 
        [value]
    )
    return(
        <div className = {classes.main_container}>
            <div className = {classes.title_container}> 
                <h2> Percentage of benefits to withdraw at partial retirement</h2>
            </div>
            
            <div className = {classes.fs_container}> 
                <FancySlider 
                    value = {value === '' ? 20 : value}
                    setValue = {(x) => set_value(x) }
                    safe_percentage = {63}
                />
                <div className = {classes.explain_container}>
                    <button
                    onClick = {() => set_show_modal(true)}
                    > Explain this question</button> 

                </div>
            </div>
            {show_modal && 
                    <InfoModal
                    modalHandler = {() => set_show_modal(false)}
                    infoMessage = {props.modal_content}
                    />
                }
        </div>
    )
}

export default RangeInput