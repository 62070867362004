import React, { Fragment, useState, useEffect, useContext, useRef } from "react";
import classes from './PrettyTextInput.module.css'
import { formatToPoundNoRounding } from "../../../shared/utilities/Formatters";

const PrettyTextInput = (props) => {

  const [focused, set_focused] = useState(false)

  const border_color = (
      (props.showError && props.error) ? 'red' :
      (props.value === 'xxx' ? 'var(--main-color-light)' :  'var(--main-color)')
      )

  const text_color = (((props.value !== '') | props.showError | focused))? border_color : 'black'
  return(
      <div className={classes.mahi_holder}
      style = {
        props.left_margin ? {'--left-margin' : props.left_margin}: {}
      }
      >

              
              <div className={classes['col-3'] + ' ' + classes['input-effect']}
              style = {{
                  '--top-margin' : ((focused | (props.value !== ''))? '0' :'-3.5px'), 
                  '--side-margin' : ((focused | (props.value !== '' )) ? '0' : '-4px'), 
                  '--border-color' : border_color, 
                  '--text-color' : text_color

              }}
              
              >
                  <input 
                  className={classes["effect-19"] +  ' ' + (props.value !== 'xxx' ? classes['has-content'] : '')}
                  type= {props.type === 'currency' ? 'text' : 'number'}
                    placeholder=""
                    onChange = {props.onChange}
                    value = {props.type === 'currency' ? formatToPoundNoRounding(props.value || '') : (props.value || '')}
                    onFocus = {
                      () => set_focused(true)
                    }
                    onBlur = {
                      () => set_focused(false)
                    }
                    />
                  <label>{props.label}</label>
                  <span className= {classes["focus-border"]}>
                      <i></i>
                  </span>
              </div>
          

      </div>
      )
}

export default PrettyTextInput

