import React, { Fragment, useState, useEffect, useContext, useRef } from "react";
import classes from './OptionsInput.module.css'
import InfoModal from "../../../components/results/switches/InfoModal"


const OptionsInput = (props) => {
    const [selected, set_selected] = useState(props.inputsRef.current[props.tracker])  
    const [show_modal, set_show_modal] = useState(false)
    

    
    return(
        <div className = {classes.overall_container}>
            
            <h3> {props.question_text}</h3>
            {props.body_text &&
            <div className = {classes.body_text}>
                {props.body_text}
            </div>
            }
            <div className = {classes.options_container}>
            {
            props.options.map(
                (x) => {
                    return(
                    <button 
                        key = {x} 
                        className = {classes.options_button + ' ' + (x === selected ? classes.pressed : classes.unpressed)}
                        onClick = {
                            () => {
                                if(props.set_track_status) {
                                    props.set_track_status(x)
                                }
                                set_selected(x)
                                props.responsesRef.current = [props.tracker, 'valid']
                                props.current_answer.current = x
                                props.set_currently_being_answered(props.tracker)
                            }
                        }
                        > {x} </button>
                    )
                }
            )
        }
         </div>
         <div className = {classes.explain_container}> 
         {props.modal_content && <button
         onClick = {() => {set_show_modal(true)}}
         > Explain what this question means
         </button>
        }
         </div>
         {show_modal && 
         <InfoModal
         modalHandler = {() => set_show_modal(false)}
         infoMessage = {props.modal_content}
         />
         }
        </div>
    )
}

export default OptionsInput