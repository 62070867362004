import React, { useState } from "react";
import QuestionSelectorItem from "./QuestionSelectorItem";
import classes from "./QuestionSelector.module.css";

const QuestionSelector = (props) => {
  const [selected, setSelected] = useState(props.items[0]);

  function handleChangeSelected(item) {
    setSelected(item);
  }

  const questionButtons = props.items.map((item) => {
    // if (item.id === selected.id) return null;
    return (
      <button
        className={`${classes.button} ${
          item.id === selected.id ? classes.active : ""
        }`}
        onClick={handleChangeSelected.bind(null, item)}
        key={item.id}
        id={item.id}
      >
        {item.question}
      </button>
    );
  });

  return (
    <section className={classes.mainContainer}>
      <div>
        <div className={classes.questions}>{questionButtons}</div>
        <QuestionSelectorItem
          id={selected.id}
          question={selected.question}
          answer={selected.answer}
        />
      </div>
    </section>
  );
};

export default QuestionSelector;
