import React, { Fragment, useState, useEffect, useContext, useRef } from "react";
import classes from './PostReshapingPTInput.module.css'
import { sanitisePercentString, processPercentDelete } from "../../../shared/utilities/Formatters";

const PostReshapingPTInput = (props) => {
    const [post_pt, set_post_pt] = useState(props.inputsRef.current.PostPT.replace('%', ''))
    const [valid, set_valid] = useState('')
    const first_mount = useRef(true)

    function removePercentagesAndCommas(inputString) {
        return inputString.replace(/[%\,]/g, '');
    }
    
    const check_validity = (x) => {
        const num  = Number(removePercentagesAndCommas(x))

        if(num > 100 || num <= 0 ) {
            return [
                'error', 
                'The proportion you have entered is not between 0% and 100%'
            ]
        }

        return [
            'valid', 
            ''
        ]
    }

    useEffect(
        () => {
            if(first_mount.current) {
                first_mount.current = false
            } else {
                const is_valid = check_validity(post_pt)
                set_valid(is_valid)
                props.responsesRef.current = ['PostPT', is_valid[0]]
                props.current_answer.current = (post_pt === 0 || post_pt ) ? post_pt + '%' : ''
                props.set_currently_being_answered('PostPT')
            }
        }, 
        [post_pt]
    )

    return(
        <div className = {classes.overall_container}> 
            <div className = {classes.title_container}>
                <h2> Part-time working after partial retirement</h2>
            </div>

            <div className = {classes.text_container}> 
                <p> 
                What will your part time percentage be, after you take partial retirement? This value should be between 1% and 100%. It should reflect the percentage of full time equivalent hours that you will work after partial retirement. For example, if you will work 28 hours per week when the full time equivalent is 35 hours per week, your part-time proportion would be 80%, in which case you should write '80%'.
                </p>
                <p>
                A member doesn't have to be part time when starting their partial retirement. The 10% reduction in pensionable earnings can come from a reduction in hours, a change in grade/Job role, forgoing superannuation payments on extra such as extra programmed activity etc.
                </p>

                {valid[0] === 'error' && <p className = {classes.error}> {valid[1]}</p>} 
            </div>
            <div className = {classes.input_container}> 
            <input
            type = 'text'
            value={(post_pt === 0 || post_pt ) ? post_pt + '%' : ''}
            onChange={(e) => {

                const old_val = post_pt === '' ? '' : post_pt + '%'
    
                var new_val = processPercentDelete(e.target.value, old_val).replace('%', '')
    
                new_val = sanitisePercentString(new_val)
    
                set_post_pt(new_val)
    
                }
            }
            />
            
            </div>

        </div>
    )
}

export default PostReshapingPTInput