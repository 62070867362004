import React from "react";
import classes from "./QuestionSelector.module.css";

const QuestionSelectorItem = (props) => {
  return (
    <div className={classes.textbox}>
      <h2 className={classes.question}>{props.question}</h2>
      <div className={classes.answer}>{props.answer}</div>
    </div>
  );
};

export default QuestionSelectorItem;
