import React, { Fragment, useEffect, useState } from "react";
import ReactDOM from "react-dom";

import classes from "./InfoModal.module.css";
import classes2 from "./ChartModal.module.css"

import FancyButton from "./FancyButton";

import { Bar } from 'react-chartjs-2';
import { Chart, BarController, LineController, CategoryScale, LinearScale, PointElement, LineElement, ScatterController } from 'chart.js';
import annotationPlugin from 'chartjs-plugin-annotation';
Chart.register(BarController, LineController, CategoryScale, LinearScale, PointElement, LineElement, ScatterController, annotationPlugin);



const Backdrop = (props) => {
  return <div className={classes.background} onClick={props.onClick} />;
};

const ModalOverlay = (props) => {
  function deepClone(obj) {
    return JSON.parse(JSON.stringify(obj));
 }

 function customLowerCase(str) {
  return str.replace(/CARE/g, '_PLACEHOLDER_').toLowerCase().replace(/_placeholder_/g, 'CARE');
}
  let copy_data = props.data
  copy_data.datasets[0].barPercentage = 0.2
  copy_data.datasets[1].barPercentage = 0.2
  const [this_data, set_this_data] = useState(deepClone(copy_data))
  const [chartKey, setChartKey] = useState(0);
  const [clicked, set_clicked] = useState(1000)
  const [show_general_explanation, set_show_general_explanation] = useState(false)
  function formatToPound(number) {
    return new Intl.NumberFormat('en-GB', {
        style: 'currency',
        currency: 'GBP',
        minimumFractionDigits: 0
    }).format(number);
  }

  const general_explanations = {
    'Lump Sum' :      'When you partially retire, you will receive an automatic lump sum that usually equals your legacy pension from partial retirement multiplied by 3. ' +
                    'You can also increase your lump sum at partial retirement by commuting some of the pension benefits you draw down on. ' +
                    'If you still have legacy benefits left at full retirement, you will also get another automatic lump sum when you fully retire. ' +
                    'However, if you use up all your legacy benefits at partial retirement, you will not get an automatic lump sum when you fully retire unless you commute ' +
                    'some of your CARE benefits, which - for simplicity - this illustrator assumes you will not do. ' +
                    'The chart shows how these principles affect the lump sum you would be expected to receive when you partially retire (dots) in the different scenarios, ' +
                    'as well as the lump sum you would get if you fully retired in a given year (indicated by the bars, which will not be visible if there is no expected full retirement lump sum) in the different scenarios.', 
    'Total pension' : "The chart above shows the 'Total Pension' (meaning CARE pension plus legacy pension) that you might start receiving at partial and full retirement in the different scenarios. " + 
                      "The dots show the pension that you are expected to start receiving when you partially retire, and continue receiving (with an annual increase for inflation) thereafter. " + 
                      "The bars show the pension that you would be expected to start receiving if you fully retired in a given year - the later you fully retire, the higher your annual full retirement pension will be. ",
    'CARE Pension' : "The chart above shows the CARE pension that you might start receiving at partial and full retirement in the different scenarios. " + 
                      'The dots show the CARE pension that you will start receiving at partial retirement; ' + 
                      'The bars show the CARE pension that you will start receiving at full retirement if you fully retire in the relevant year ' +
                      ' - the later you fully retire, the higher your annual CARE pension from full retirement onwards will be. ' +
                                              (((!props.no_ls_reformed) || (!props.no_ls_legacy) ) ? 
                                              "In some cases, the dots may be at £0 if you are not projected to draw down on any CARE pension at partial retirement in the relevant scenario." : ""
                                              ),
    'Legacy Pension' : "The chart above shows the Leacy pension that you might start receiving at partial and full retirement in the different scenarios. " + 
                        'The dots show the legacy pension that you will start receiving at partial retirement; ' +
                        'the bars show the legacy pension that you will start receiving at full retirement if you fully retire in the relevant year ' +
                        ' - the later you fully retire, the higher your annual legacy pension from full retirement onwards will be.' +
                            ((props.no_ls_reformed || props.no_ls_legacy) ? ''
                            :
                            "In some cases, the bars may be at £0 if you are projected to use up all your legacy pension at partial retirement, meaning you will only get CARE pension from full retirement."
                            )
                  
  }
  const text = [
                <Fragment key = {0}>
                  <p>  {((props.variable === 'Lump Sum' || props.variable === 'Total pension') ? 'Normally, blue bars would' : 'The blue bars') + ' show the ' + (props.variable === 'Total pension' ? 'combined CARE and legacy pension' : customLowerCase(props.variable) ) + ' that you will receive ' + (props.variable === 'Lump Sum' ? 'as a one off payment at full retirement' : 'every year from full retirement onwards if you fully retire in the relevant year,') +
                   ' assuming you choose to receive reformed scheme benefits over the remedy period.' + (((props.variable === 'Lump Sum' || props.vriable === 'Total pension') && props.no_ls_reformed) ? '' :
                    ' Note that the blue bar for a given year only shows the full retirement ' + customLowerCase(props.variable) + 
                   (' that you receive if you fully retire in that year.' ))} </p>
                  {props.variable === 'Total pension' && <p>
                    By 'Total pension', we mean the 1995 scheme pension plus the CARE pension that you get from full retirement - so the bars don't
                    include the pension you get from partial retirement onwards, which is represented by the dots.
                  </p>}
                  {props.variable === 'Lump Sum' ? 

                   (props.no_ls_reformed ?
                   <p> However, since you are projected to use up all your legacy benefits at partial retirement in the 'choose reformed'
                    scenario, you are projected to only receive CARE pension at full retirement; since the CARE scheme does not have an
                    automatic lump sum, this means we have projected zero lump sum for you at full retirement. However, in practice you
                    could choose to commute some of your CARE pension at full retirement in exchange for a lump sum at full retirement - 
                    the results shown are based on the assumption that you will not do this.

                   </p>
                   :<p> For example, if you choose the reformed scheme benefits and fully retire 
                    3 years after your partial retirement date, you will receive a one off payment at full retirement
                     of {formatToPound(props.data.datasets[0].data[3]['y'])}
                    . You won't receive any other lump sums after you fully retire. </p>) :
                   <Fragment> 
                  <p>  {'This amount is in addition to the amount that you will receive annually from partial retirement onwards; both amounts increase with inflation (assumed to be 2%) every year from whenever you start receiving them'} </p> 
                  
                   </Fragment>
                  }
                 
                  </Fragment>, //end of fragment
                  <Fragment key = {1}>
                  <p>  {((props.variable === 'Lump Sum' || props.vriable === 'Total pension') ? 'Normally, grey bars would' : 'The grey bars') + ' show the ' + (props.variable === 'Total pension' ? 'combined CARE and legacy pension' : customLowerCase(props.variable)) + ' that you will receive ' + (props.variable === 'Lump Sum' ? 'as a one off payment at full retirement' : 'every year from full retirement onwards if you fully retire in the relevant year,') +
                   ' assuming you choose to receive legacy scheme benefits over the remedy period.' + (((props.variable === 'Lump Sum' || props.vriable === 'Total pension') && props.no_ls_legacy) ? '' :
                    ' Note that the grey bar for a given year only shows the full retirement ' + customLowerCase(props.variable) + 
                   (' that you receive if you fully retire in that year.' ))} </p>
                  {props.variable === 'Total pension' && <p>
                    By 'Total pension', we mean the 1995 scheme pension plus the CARE pension that you get from full retirement - so the bars don't
                    include the pension you get from partial retirement onwards, which is represented by the dots.
                  </p>}
                  {props.variable === 'Lump Sum' ? 

                   (props.no_ls_legacy ?
                   <p> However, since you are projected to use up all your legacy benefits at partial retirement in the 'choose legacy'
                    scenario, you are projected to only receive CARE pension at full retirement; since the CARE scheme does not have an
                    automatic lump sum, this means we have projected zero lump sum for you at full retirement. However, in practice you
                    could choose to commute some of your CARE pension at full retirement in exchange for a lump sum at full retirement - 
                    the results shown are based on the assumption that you will not do this.

                   </p>
                   :<p> For example, if you choose the legacy scheme benefits and fully retire 
                    3 years after your partial retirement date, you will receive a one off payment at full retirement
                     of {formatToPound(props.data.datasets[1].data[3]['y'])}
                    . You won't receive any other lump sums after you fully retire. </p>) :
                   <Fragment> 
                  <p>  {'This amount is in addition to the amount that you will receive annually from partial retirement onwards; both amounts increase with inflation (which we have assumed to be 2%) every year'} </p> 
                  
                   </Fragment>
                  }
                 
                  </Fragment>, //end of fragment
                  <Fragment key = {2}>
                  <p>  {'The blue ' + (props.variable === 'Lump Sum' ? 'dot shows' : 'dots show') + ' the ' + (props.variable !== 'Total pension' ? customLowerCase(props.variable) : 'total pension') + ' that you would receive ' + 
                  (props.variable === 'Lump Sum' ? 'at' : 'from')+ 
                  ' partial retirement if you choose ' + 
                  'to receive reformed scheme benefits over the remedy period' + (props.variable === 'Lump Sum' ? ':' : '. This amount is: ')} {formatToPound(props.data.datasets[2].data[0]['y'])}
                   </p>
                   {props.variable === 'Total pension' && <p>
                    By 'Total pension', in this case we mean the 1995 scheme pension plus the CARE pension that you get from partial retirement - so the dots don't
                    include the 'Total pension' you get from full retirement onwards, which instead is represented by the bars.
                  </p>}
                  {props.variable === 'Lump Sum' ? 
                  <p> You won't receive any lump sums after that
                    {props.no_ls_reformed ? 'based on these projections, because all your legacy benefits will be used up at partial retirement.' : ', except when you fully retire.'} 
                      </p> :
                  <Fragment> 
                  <p key = {0}>  {'You will continue to receive this amount every year after partial retirement, increasing in line with inflation (which we have assumed to be 2%) every year'} </p> 
                  
                  </Fragment>
                  }
                
                  </Fragment>, ///end of fragment
                  <Fragment key = {3}>
                  <p>  {'The dark grey ' + (props.variable === 'Lump Sum' ? 'dot shows' : 'dots show') + ' the ' + (props.variable !== 'Total pension' ? customLowerCase(props.variable) : 'total pension') + ' that you would receive at partial retirement if you choose ' + 
                  'to receive legacy scheme benefits over the remedy period:'} {formatToPound(props.data.datasets[3].data[0]['y'])} </p>
                  {props.variable === 'Lump Sum' ? 
                   <p> You won't receive any lump sums after that
                     {props.no_ls_legacy ? 'based on these projections, because all your legacy benefits will be used up at partial retirement.' : ', except when you fully retire.'}
                      </p> :
                   <Fragment> 
                  <p>  {'You will continue to receive this amount every year after partial retirement, inceasing in line with inflation (which we have assumed to be 2%) every year'} </p> 
                  
                   </Fragment>
                  }
                  {props.variable === 'Total pension' && <p>
                    By 'Total pension' in this context, we mean the 1995 scheme pension plus the CARE pension that you get from partial retirement onwards - so the dots don't
                    include the 'Total pension' that you get from full retirement onwards, which instead is represented by the bars.
                  </p>}
                 
                  </Fragment>, ///end of fragment

                ]
  return (
    <div className={classes2.modal}>
      <div className = {classes2.text_and_chart_container}> 
      <div className = {classes2.chart_container}>
      <div style = {{'minHeight' : '500px'}}> 
      <Bar 
      key = {chartKey}
      data = {this_data}
      options = {props.options}> </Bar>
      </div>
      <div className = {
        classes2.general_explanation + ' ' + (show_general_explanation ? classes2.general_explanation_variables : '')
      
      }
      onClick = {() => {set_show_general_explanation(true); set_clicked(1000)}}
      > 
      <h2>  General explanation </h2>


        {show_general_explanation && <p> {general_explanations[props.variable]} </p> }
        {!show_general_explanation && <Fragment> <p>
          This chart shows the {props.variable} that you could get at full and partial retirement in the different scenarios
           </p>
           <p> Click to expand</p>
           </Fragment>
           }

      </div>
      </div>
      <div className = {classes2.text_container}>
        {Array.from({ length: 4 }, (_, i) => i ).map(
          (i) => {return(
        <div style = {{
          background: props.data.datasets[i].backgroundColor, 
          maxHeight : (i === clicked ? '700px' : '50px')
        }} 
        className = {classes2.single_explanation + ' ' + (this_data.datasets[i].type === 'scatter' ? classes2.dot : '')}
        key = {i}
        onClick = {(e) => {

          let temp = deepClone(copy_data)
          if(temp.datasets[i].type === 'scatter') {
            temp.datasets[i].pointRadius = 20
            set_this_data(temp)
            setChartKey(prev => prev+1)
          } else {
            temp.datasets[i].barPercentage = 1
            set_this_data(temp)
            setChartKey(prev => prev+1)
          }
          
          set_clicked(i)
          set_show_general_explanation(false)
          e.stopPropagation()
        
        }}
        >
          <h2> {props.data.datasets[i].label.replace('received', props.variable).replace('Received', props.variable)}</h2>
          {clicked !== i && <p style = {{'width' : '100%', 'textAlign' : 'center'}}> click for explanation</p>}
          <div style = {{'opacity' : (clicked === i ? 1 : 0), 'fontWeight' : '900'}} > {text[i]} </div>
        </div>)}
        )
      }
      </div>
      </div>

      <div style = {{'width' : '50%', 'display' : 'flex', 'justifyContent' : 'center'}}> 
      <FancyButton onClick={props.onClick}
      content = {'Close this window'}
      >    
      </FancyButton>
      </div>
    </div>
  );
};

const ChartModal = (props) => {

  
  useEffect(() => {
    const close = (event) => {
      if (event.keyCode === 27) {
        props.modalHandler();
      }
    };
    window.addEventListener("keydown", close);
    return () => window.removeEventListener("keydown", close);
  });

  return (
    <Fragment>
      {ReactDOM.createPortal(
        <ModalOverlay
          onClick={props.modalHandler}
          data = {props.data}
          options = {props.options}
          variable = {props.variable}
          no_ls_legacy = {props.no_ls_legacy}
          no_ls_reformed = {props.no_ls_reformed}
        />,
        document.getElementById("modal-root")
      )}
      {ReactDOM.createPortal(
        <Backdrop onClick={props.modalHandler} />,
        document.getElementById("backdrop-root")
      )}
    </Fragment>
  );
};

export default ChartModal;