import React, { Fragment, useEffect, useState } from "react";
import ReactDOM from "react-dom";

import classes from "./InfoModal.module.css";
import classes2 from "./ChartModal.module.css"

import FancyButton from "./FancyButton";

import { Bar } from 'react-chartjs-2';
import { Chart, BarController, LineController, CategoryScale, LinearScale, PointElement, LineElement, ScatterController } from 'chart.js';
import annotationPlugin from 'chartjs-plugin-annotation';
Chart.register(BarController, LineController, CategoryScale, LinearScale, PointElement, LineElement, ScatterController, annotationPlugin);



const Backdrop = (props) => {
  return <div className={classes.background} onClick={props.onClick} />;
};

const ModalOverlay = (props) => {
  
  const [clicked, set_clicked] = useState('')
  const [selected_colour, set_selected_colour] = useState('')
  const [show_general_explanation, set_show_general_explanation] = useState(false)

  function invertObject(obj) {
    let inverted = {};
    for (let key in obj) {
        if (obj.hasOwnProperty(key)) {
            inverted[obj[key]] = key;
        }
    }
    return inverted;
}
  const colour_key = invertObject(props.variable_colours)
 
  return (
    <div className={classes2.modal}>
      <div className = {classes2.text_and_chart_container}> 
      <div className = {classes2.chart_container + ' ' + classes2.chart_container_income}
      style = {{'maxWidth' : show_general_explanation ? '100%' : '50%', 'height' :'70%'}}
      >
      {React.createElement(props.chart, { title: props.title, high_perc : colour_key[selected_colour], default_perc : (1/3) })}
      <div className = {classes2.general_explanation + ' ' + classes2.general_explanation_income}
      onClick = {() => set_show_general_explanation(!show_general_explanation)
    
    }
      > <h2>  General explanation {!show_general_explanation ? '(click to enlarge)' : '(click to minimise)'}</h2>
      <p> The chart above shows the total income (from both pension and earnings)
        that you are projected to receive during partial retirement (rectangle on the left), 
        and once you fully retire (rectangle on the right), assuming you opt to receive {props.choice} {' '}
        benefits over the remedy period. 
        </p> 
        {show_general_explanation &&
        <Fragment> 
        <p>
        During partial retirement, your income is made up of the pension that you start receiving when you partially retire,
        plus the earnings that you receive because you are still working. Once you fully retire, you still get your partial retirement pension, and you also get the rest of your pension
        that you did not draw down on when you partially retired. However, you no longer receive earnings because you are no
        longer working.
      </p>
      <p> Separately, you also receive a one-off lump sum at partial retirement.
    {props.no_legacy ?
     'You are not projected to get a one-off lump sum at full retirement because you will have used up all your legacy benefits at partial retirement' : 
     'You will also receive a one-off lump sum at full retirement.'
     }
        

      </p>
      <p> 
        Each set of bars refers to income over a 12-month period. For example, where 'years after partial retirement' is
        0, it refers to income over the 12 months starting at the partial retirement date - in your case, this is from {props.date_1}
        
         ; Similarly, where 'years after partial retirement' is {Math.min(props.n_years - 1, 3)}, it refers to income over the 12 months starting exactly {Math.min(props.n_years - 1, 3)} years after the partial retirement 
         date - in your case, this is from {props.date_2}
      </p>
      </Fragment>
}

      </div>
      </div>
      {!show_general_explanation && <div className = {classes2.text_container}>
      <h1> Click on a box to see what the colour represents in the chart. </h1>
        
            <div className = {classes2.row_div}> 
                    <div 
                    className = {classes2.single_explanation} 
                    style = {{'background' : props.variable_colours['actual_pay']}}
                    onClick = {() => {set_clicked(
                        <Fragment> 
                        <p> This is the pay that you will continue to receive from employment while you are partially retired</p>
                        <p> In 'year 0' (which means the year-long period beginning at your partial retirement date), 
                            we have set this to equal your post-reshaping salary multiplied by your post-partial retirement
                            part-time percentage. We then assume a standard salary increase every year after that. You only
                            stop receiving this when you fully retire  </p>
                        </Fragment>
                    ); set_selected_colour(props.variable_colours['actual_pay'])}}
                    > 
                    <h2> Earnings during partial retirement</h2>
                        <p> click for explanation</p>
                    </div>
                    <div className = {classes2.single_explanation} 
                    style = {{'background' : props.variable_colours['LP at partial retirement']}}
                    onClick = {() => {set_clicked(
                        <Fragment> 
                        <p> This is the legacy scheme pension you start to receive once you have partially retired.
                            You continue to receive it every year even after you have fully retired.
                        </p>
                        
                        </Fragment>
                    ); set_selected_colour(props.variable_colours['LP at partial retirement'])}}
                    > 
                    <h2> Legacy pension received from partial retirement onwards </h2>
                        <p> click for explanation</p>
                    </div>
                    <div className = {classes2.single_explanation} 
                    style = {{'background' : props.variable_colours['CP at partial retirement'], 'color' : 'black'}} 
                    onClick = {() => {set_clicked(
                        <Fragment> 
                        <p> This is the CARE scheme pension you start to receive once you have partially retired.
                            You continue to receive it every year even after you have fully retired.
                        </p>
                        {props.no_care  && <p> However, in your case, this amount is £0 because the percentage of benefits you
                            have selected to receive at partial retirement is low enough that you only need to draw down on your legacy
                            scheme benefits at partial retirement.
                             </p>}
                        
                        </Fragment>
                    ); set_selected_colour(props.variable_colours['CP at partial retirement'])}}
                    >
                    <h2> CARE pension received from partial retirement onwards</h2>
                        <p> click for explanation</p>
                    </div>
            
            </div>

            <div className = {classes2.row_div}> 
                    <div 
                    className = {classes2.single_explanation} 
                    style = {{'background' : props.variable_colours['LP'], 'color' : 'white'}}
                    onClick = {() => {set_clicked(
                        <Fragment> 
                        <p> This is the legacy pension that you start receiving when you fully retire. </p>
                        {props.no_legacy && <p> In your case this amount is £0 because you will withdraw all legacy benefits at partial retirmeent;
                            you will only have CARE scheme benefits left when you fully retire.
                            </p>}
                        </Fragment>
                    ); set_selected_colour(props.variable_colours['LP'])}}
                    > 
                    <h2> Legacy pension received from full retirement onwards </h2>
                        <p> click for explanation</p>
                    </div>

                    <div className = {classes2.single_explanation} 
                    style = {{'background' : props.variable_colours['CP']}}
                    onClick = {() => {set_clicked(
                        <p> This is the CARE pension that you start receiving when you fully retire. </p>
                    ); set_selected_colour(props.variable_colours['CP'])}}
                    > 
                    <h2> CARE pension received from full retirement onwards </h2>
                        <p> click for explanation</p>
                    </div>

                    <div className = {classes2.single_explanation} 
                    style = {{'background' : props.variable_colours['LS at partial retirement'], 'color' : 'black'}}
                    onClick = {() => {set_clicked(
                        <Fragment> 
                        <p> This is the one off lump sum that you receive when you partially retire.
                        </p>
                        
                        </Fragment>
                    ); set_selected_colour(props.variable_colours['LS at partial retirement'])}}
                    >
                    <h2> One-off lump sum received at partial retirement</h2>
                        <p> click for explanation</p>
                    </div>
            </div>
            

            <div className = {classes2.row_div}> 
                    <div 
                    className = {classes2.single_explanation} 
                    style = {{'background' : props.variable_colours['LS']}}
                    onClick = {() => {set_clicked(
                        <Fragment> 
                        <p> This is the one-off lump sum that you receive when you fully retire. </p>
                        {props.no_legacy && 
                        <Fragment> 
                        <p> In your case this amount is £0 because you will withdraw all legacy benefits at partial retirmeent;
                            you will therefore only have CARE scheme benefits left when you fully retire. Only the legacy scheme carries
                             an automatic lump sum, so if you only start receiving CARE scheme benefits at full retirement, there will be no automatic lump sum.
                            </p>
                          <p> In practice, you would have the option to commute CARE scheme pension at full retirement in exchange for a lump sum
                            at full retirement, but for simplicitly we have assumed that the full retirement lump sum involves no commutation. </p>
                        </Fragment>  
                            }
                        </Fragment>
                    ); set_selected_colour(props.variable_colours['LS'])}}
                    >
                        <h2> One-off lump sum received at full retirement</h2>
                        <p> click for explanation</p>
                
            </div>

          

      
 
      
      </div>

      {clicked!== '' &&
      <div 
      className = {classes2.full_explanation_div_income}
      style = {{
        background: clicked === '' ? 'white' : selected_colour, 
        color : (
            [
                props.variable_colours['LS'],
                props.variable_colours['LP']
        ].includes(selected_colour) ? 'white': 'black')}}
      > {clicked} </div>
        }
      </div>}


      

    </div>
    <div > 
    <button  onClick={props.onClick}
    style = {{'position' : 'fixed', 'bottom' : (show_general_explanation ? '20px' : '50px'), 'right' : '80px',
            'borderRadius' : '10px', 'width' :'400px', 'height' : '50px'
  }}
  className = {classes2.close_button_income}
      >
        close
      </button>
      </div>
    </div>
  );
};

const ChartModalScenarioView = (props) => {

  
  useEffect(() => {
    const close = (event) => {
      if (event.keyCode === 27) {
        props.modalHandler();
      }
    };
    window.addEventListener("keydown", close);
    return () => window.removeEventListener("keydown", close);
  });

  return (
    <Fragment>
      {ReactDOM.createPortal(
        <ModalOverlay
          onClick={props.modalHandler}
          choice = {props.choice}
          variable_colours = {props.variable_colours}
          chart = {props.chart}
          title = {props.title}
          no_care = {props.no_care}
          no_legacy = {props.no_legacy}
          date_1 = {props.date_1}
          date_2 = {props.date_2}
          n_years = {props.n_years}
        />,
        document.getElementById("modal-root")
      )}
      {ReactDOM.createPortal(
        <Backdrop onClick={props.modalHandler} />,
        document.getElementById("backdrop-root")
      )}
    </Fragment>
  );
};

export default ChartModalScenarioView;