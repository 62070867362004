import React, { Fragment, useState, useEffect, useContext, useRef } from "react";
import classes from './AddedYearsInput.module.css'
import PrettyTextInput from "./PrettyTextInput"
import InfoModal from "../../../components/results/switches/InfoModal"
import { processAddedYears } from "../../../shared/utilities/Formatters";

const AddedYearsInput = (props) => {
    const iay = props.inputsRef.current.AddedYears
    if(iay === 'None' || iay === '') {
        var real_added_years = ['xxx', 'xxx']
        var nay = iay === 'None' ? 'N' : ''
    } else {
        var real_added_years = processAddedYears(iay)
        var nay = 'Y'
    }
    
    const [needs_added_years, set_needs_added_years] = useState(nay)
    const [years, set_years] = useState(real_added_years[0])
    const [days, set_days] = useState(real_added_years[1])
    const [valid, set_valid] = useState('NA')
    const first_mount = useRef(true)
    const rerendered_after_chose_yes = useRef(-1)
    const [show_modal, set_show_modal] = useState(false)

    const modal_content = <Fragment>
        <h1> 1995 Scheme added years </h1>
        <p>
        Added years were a way of allowing 1995 Section members who could not reach 40 years of Scheme membership by their Normal Pension Age to increase their Scheme benefits by buying additional years of membership.
        </p>
    </Fragment>

    const check_validity = (x, y, z) => {
        if(
            (x === 'xxx'  || x === '' || x === '0' || x === 0) &&
            (y === 'xxx'  || y === '' || y === '0' || y === 0) &&
            z === 'Y'
        ) {
            return [
                'error', 
                'If you have purchased added years, you cannot leave both years and days blank'
            ]
        }

        if(Number(x) >= 100 && z === 'Y') {
            return [
                'error', 
                "The added years you've entered is too high."
            ]
        }

        if(Number(y) >= 365 && z === 'Y') {
            return [
                'error', 
                "The added days you've entered is too high."
            ]
        }

        return [
            'valid', 
            ''
        ]
        
    }

    if(needs_added_years === 'Y') {
        rerendered_after_chose_yes.current += 1
    }

    useEffect(
        () => {
            if(first_mount.current) {
                first_mount.current = false
            } else {
                const is_valid = check_validity(years, days, needs_added_years)
                props.responsesRef.current = ['AddedYears', is_valid[0]]
                set_valid(is_valid)
                if(needs_added_years === 'Y' && (years !== 'xxx' || days !== 'xxx')) {
                    const extra1 = years === '1' ? '' : 's'
                    const extra2 = days === '1' ? '' : 's'
                    props.current_answer.current = (['xxx', ''].includes(years) ? 0 : years) + ' year' + extra1 + '; ' + (['xxx', ''].includes(days) ? 0 : days) + ' day' + extra2
                } else {
                    props.current_answer.current = 'None'
                }
                props.set_currently_being_answered('AddedYears')
            }
        },
        [years, days, needs_added_years]
    )

    return(
        <div className = {classes.main_container}>
            <div className = {classes.first_question_container}>
                <h3> Have you ever purchased added years?</h3>
                <div className = {classes.button_container}> 
                <button
                onClick = {() => set_needs_added_years('Y')}
                className = {(needs_added_years === 'Y' ? classes.pressed : classes.unpressed)}
                > Yes </button> 
                <button
                onClick = {() => set_needs_added_years('N')}
                className = {(needs_added_years === 'N' ? classes.pressed : classes.unpressed)}
                > No </button>
                </div>
            </div>
            <div className = {classes.input_container }> 
                <div className = {classes.years_input + ' ' + (needs_added_years === 'Y' ? '' : classes.hidden)}> 
                    <PrettyTextInput
                        type = {'number'}
                        label = {'Years'}
                        value ={years}
                        onChange = {(e) => {set_years(e.target.value)}}
                        showError = {valid[0] === 'error' && rerendered_after_chose_yes.current > 1}
                        error = {valid[1]}
                        left_margin = {'10px'}
                    />
                </div>
                <div className = {classes.days_input + ' ' + (needs_added_years === 'Y' ? '' : classes.hidden)}> 
                <PrettyTextInput
                    type = {'number'}
                    label = {'Days'}
                    value ={days}
                    onChange = {(e) => {set_days(e.target.value)}}
                    showError = {valid[0] === 'error' && rerendered_after_chose_yes.current > 1}
                    error = {valid[1]}
                    left_margin = {'10px'}
                />
                </div>
            </div>
            <div className = {classes.explain_container}> 
             <button
            onClick = {() => {set_show_modal(true)}}
            > Explain what this question means
            </button>
            {show_modal && 
            <InfoModal
            modalHandler = {() => set_show_modal(false)}
            infoMessage = {modal_content}
            />
            }
            </div>
            {valid[0] === 'error' && (rerendered_after_chose_yes.current > 1) && <p className = {classes.error}> {valid[1]}</p>}
        </div>
    )
}

export default AddedYearsInput