import React, { useState, useEffect, useRef } from 'react';
import classes from './FulDateDropdown.module.css'
import DateDropdown from './DateDropdown';

const FullDateDropdown = (props) => {
    
  const initial_mount = useRef(true)
    let months = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];

    const min_month = props.min_month === undefined ? 0 : props.min_month


    function isLeapYear(year) {
      return (year % 4 === 0 && year % 100 !== 0) || year % 400 === 0;
    }
    function get_max_possible_day(year, month) {
      if (month === "February") {
        return isLeapYear(year) ? 29 : 28;
      } else if (["April", "June", "September", "November"].includes(month)) {
        return 30;
      } else {
        return 31;
      }
    }
    


  
    if(props.existing_value !== '') {
        const ev = props.existing_value
        var existing_year = ev.slice(0,4)
        var existing_month = ev.slice(5,7)
        existing_month = months[Number(existing_month) - 1]
        var existing_day = ev.slice(8,10)
        existing_day = Number(existing_day)
        
    } else {
        var existing_year = 'Year'
        var existing_month = 'Month'
        var existing_day = 'Day'

    }
    const [year, set_year] = useState(existing_year)
    const [month, set_month] = useState(existing_month)
    const [day, set_day] = useState(existing_day)

    useEffect(
      () => {
        if((props.existing_value === '' && !initial_mount.current) || ['reset1', 'reset2'].includes(props.existing_value)) {
          
          set_year('Year')
          set_month('Month')
          set_day('Day')
        }
        
      },
      [props.existing_value]
    )

    const dayRef = useRef(day)
    const monthRef = useRef(month)
    useEffect(
        () => {
          if(!initial_mount.current) {
            props.set_currently_being_answered()
          }
          initial_mount.current = false
            if(
                (year !== 'Year') && (month !== 'Month') && (day !== 'Day') &&
                ((String(year) + '-' + String(month) + '-' + String(day)) !== props.existing_value)
                ) {
                  
                if(String(day).length === 1) {
                  
                  var formatted_day = '0' + day
                } else {
                  var formatted_day = day
                }
                var formatted_month = months.indexOf(month) + 1

                if(String(formatted_month).length === 1) {
                  formatted_month = '0' + formatted_month
                }
                
                props.onChange(
                    {'target' : {'value' : String(year) + '-' + String(formatted_month) + '-' + String(formatted_day)}}
                )
            }
            if(dayRef.current === day) {
              const max_possible_day = get_max_possible_day(year, month)
              if(max_possible_day < day) {
                set_day(max_possible_day)
              }
            }

            if(monthRef.current === month && (month !== 'Month')) {
              const min_possible_month = (year === props.min_year ? min_month : 0)
              if(min_possible_month > months.indexOf(month)) {
                set_month(months[min_possible_month])
              }
            }

            dayRef.current = day
            monthRef.current = month
        }, 
        [day, month, year]
    )
    let years = [];
    for (let y = props.min_year; y <= props.max_year; y++) {
    years.push(y);
    }

  // Function to set the correct number of days based on month and year
  function setDays(m, y) {
    let days = [];
    let daysInMonth = get_max_possible_day(y, m)

    for (let d = 1; d <= daysInMonth; d++) {
      days.push(d);
    }

    return days;
  }

  // Set the days array based on the user's choice
  let days = setDays(month, year);




return (
    <div className = {classes.dropdowns_container}> 
<DateDropdown options = {days} value = {day} set_value = {set_day} name = {'day'} scrollPositions = {props.scrollPositions} go_red = {props.go_red}/>
<DateDropdown options = {
          months.slice(
            props.min_year === year ? min_month : 0,
            12)
        } 
        value = {month} 
        set_value = {
        (x) => {
          var month_index = months.indexOf(x)
          if(year === props.min_year) {
            month_index = Math.max(min_month, month_index)
          }
          set_month(months[month_index])
        }
        } 
        name = {'month'} 
        scrollPositions = {props.scrollPositions} 
        go_red = {props.go_red}/>
<DateDropdown options = {years} value = {year} set_value = {set_year} name = {'year'} scrollPositions = {props.scrollPositions} go_red = {props.go_red}/>
</div>

)
}

export default FullDateDropdown