import React, { Fragment, useEffect, useState } from "react";

import { Bar } from 'react-chartjs-2';
import { Chart, BarController, LineController, CategoryScale, LinearScale, PointElement, LineElement, ScatterController } from 'chart.js';
import annotationPlugin from 'chartjs-plugin-annotation';
Chart.register(BarController, LineController, CategoryScale, LinearScale, PointElement, LineElement, ScatterController, annotationPlugin);

const IncomeChart = (props) => {
    
  let lump_sum_pr_array = new Array(props.n_years + 4).fill(null)
  lump_sum_pr_array[1] = props.lump_sum_pr
  const label_array = [' '].concat(Array.from({ length: props.n_years }, (_, i) => String(i))).concat(['  '])
  //const label_array = [" ", "0", "1", "2", "3", "4", "5", "6", "7", "8", "  "]

    function insertSpacesAfterYear(arr, chosen_year) {
        let index = arr.indexOf(String(chosen_year));
        
        if (index === -1) {
            console.error("Year not found in array");
            return arr;
        }
        
        arr.splice(index, 0, "   ", "    ");
        return arr;
    }

    const used_label_array = insertSpacesAfterYear(label_array, props.chosen_year)

    function createArray(chosen_year, value) {

        let arr = new Array(props.n_years + 4).fill(null);
        
        // Set the value of lump_sum_fr at the correct position
        arr[chosen_year + 3] = value;
    
        return arr;
    }

    function createRevaluedArray(value, revaluation_factor) {
        return Array.from({ length: 13 }, (_, index) => {
            let valid = (Number(used_label_array[index]) > 0) || index === 1
            if(!valid) {
                return null
            } else {
                
                return value * Math.pow(revaluation_factor, Number(used_label_array[index]))
            }
        });
    }

    function createfrArray(value, chosen_year, revaluation_factor) {
        return Array.from({ length: props.n_years + 4 }, (_, index) => {
            if ((index < (chosen_year + 3)) || index ===  (props.n_years+3) ) return null;
            return value * Math.pow(revaluation_factor, index - 4);
        });
    }

    function createSalaryAdjustedArray(value, chosen_year, salary_increase) {
      
        return Array.from({ length: props.n_years + 4 }, (_, index) => {
            let numeric_year = Number(used_label_array[index])

            if((numeric_year < chosen_year) && (numeric_year > 0) || (index === 1)){

                return value * Math.pow(salary_increase, numeric_year)
                
            } else {
                return null
            }
        });
    }

    

const data = {
    datasets: [
        {
            backgroundColor: "rgba(0,48,135, 0.55)",
            type : 'scatter',
            pointRadius : props.high_perc === 'LS at partial retirement' ? 18 : props.default_perc * 18,
            pointHoverRadius : props.high_perc === 'LS' ? 18 : props.default_perc * 18,
            data: lump_sum_pr_array,
            label: "LS from partial retirement",
            
        },
        {
            backgroundColor: "rgb(0, 48, 135)",
            data: createArray(props.chosen_year, props.lump_sum_fr),
            label: "LS",
            type : 'scatter',
            pointRadius : props.high_perc === 'LS' ? 18 : props.default_perc * 18, 
            pointHoverRadius : props.high_perc === 'LS' ? 18 : props.default_perc * 18
        },
        {
            backgroundColor: "rgba(89,167,175,0.5)",
            data: createRevaluedArray(props.cp_pr, props.revaluation_factor),
            label: "CP from partial retirement",
            stack: "combined",
            barPercentage : props.high_perc === "CP at partial retirement" ? 1 : props.default_perc
        },
        {
            backgroundColor: "rgba(166,199,201, 0.5)",
            data: createRevaluedArray(props.lp_pr, props.revaluation_factor),
            label: "LP from partial retirement",
            stack: "combined",
            barPercentage : props.high_perc === "LP at partial retirement" ? 1 : props.default_perc
        },
        {
            backgroundColor: "rgba(86,167,175)",
            data: createfrArray(props.cp_fr, props.chosen_year, props.revaluation_factor),
            label: "CP",
            stack: "combined",
            barPercentage : props.high_perc === "CP" ? 1 : props.default_perc
        },
        {
            backgroundColor: "rgb(166,199,201)",
            data: createfrArray(props.lp_fr, props.chosen_year, props.revaluation_factor),
            label: "LP",
            stack: "combined",
            barPercentage : props.high_perc === "LP" ? 1 : props.default_perc
        },
        {
            backgroundColor: "rgba(0, 164, 153)",
            data: createSalaryAdjustedArray(props.actual_pay_first_year, props.chosen_year, props.salary_increase),
            label: "actual_pay",
            stack: "combined",
            barPercentage : props.high_perc === "actual_pay" ? 1 : props.default_perc
        },
        /*{
            backgroundColor: "rgba(0,164,153,0.5)",
            data: [null, props.fte_salary_pr, null, null, null, null, null, null, null, null, null, null, null, null],
            label: "Pensionable pay at partial retirement",
            stack: "dont_stack_me"
        }*/
    ],
    labels: used_label_array
};


const chartOptions = {
    animation: {
      duration: 0
    },
    plugins: {
      legend: {
        display: false,
      },
      title: {
        display: true,
        text: [props.choice], 
        align: 'start',
        font: {
          size: 30,
          color: 'black'
        }
      },
      annotation: {
        annotations: [{
            type: 'box',
            xScaleID: 'x',
            yScaleID: 'y',
            xMin: ' ',
            xMax: "   ",
            yMin: 0,
            backgroundColor: 'transparent',
            borderColor: props.pr_colour,
            borderWidth: '10'
        }, 
        {
            type: 'box',
            xScaleID: 'x',
            yScaleID: 'y',
            xMin: '    ',
            xMax: '  ',
            yMin: 0,
            backgroundColor: 'transparent', 
            borderColor: props.fr_colour,
            borderWidth: '10'
        }
    
    ]
    }, 
    tooltip : props.tooltip
    },
    scales: {
      y: {
        min: 0,
        id : 'y',
        stacked: true,
        grid: {
          display: false
        },
        title: {  // Use title instead of scaleLabel
          display: false,
          text: 'Value',
          font: {
            size: 24  // Adjust this value for the x-axis title font size
          }
        },
        ticks: {
          callback: function(value, index, values) {
            return '£' + value.toLocaleString();
          },
          font: {
            size: 20  // Adjust this value for the y-axis tick labels' font size
          }, 
        },   // Suggest a minimum value for the y-axis (useful in cases where Chart.js might decide to go below 0)
      },
      x: {
        id : 'x',
        grid: {
          display: false
        },
        title: {  // Use title instead of scaleLabel
          display: true,
          text: 'Years after partial retirement',
          font: {
            size: 24  // Adjust this value for the x-axis title font size
          }
        },
        ticks: {
          font: {
            size: 20  // Adjust this value for the x-axis tick labels' font size
          }
        }
      }
    },
    maintainAspectRatio: false
  };

return (
    <Bar data = {data} options = {chartOptions} > </Bar>
)
}
export default IncomeChart