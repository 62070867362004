import { createContext } from "react";

export const InputDataContext = createContext({
  inputs: {
    inReceipt: null,
    hasLeft: null,
    dateLeftScheme: null,
    legacyScheme: null,
    dob: null,
    spa: null,
    dateOfJoining: null,
    salaryCurrent: null,
    salary2015: null,
    retirementAge: null,
    hasPT: null,
    hasSS: null,
    partTimeService: [],
    specialService: [],
    salaryIncrease: 0,
  },
  setInputs: () => {},
  valid: {
    inReceipt: false,
    hasLeft: false,
    dateLeftScheme: false,
    legacyScheme: false,
    dob: false,
    dateOfJoining: false,
    salaryCurrent: false,
    salary2015: false,
    retirementAge: false,
    hasPT: false,
    hasSS: false,
  },
  setValid: () => {},
});
