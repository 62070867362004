import React, { Fragment, useEffect, useState } from "react";
import ReactDOM from "react-dom";

import classes from "./InfoModal.module.css";
import classes2 from "./ChartModal.module.css"

import FancyButton from "./FancyButton";

import { Bar } from 'react-chartjs-2';
import { Chart, BarController, LineController, CategoryScale, LinearScale, PointElement, LineElement, ScatterController } from 'chart.js';
import annotationPlugin from 'chartjs-plugin-annotation';
Chart.register(BarController, LineController, CategoryScale, LinearScale, PointElement, LineElement, ScatterController, annotationPlugin);



const Backdrop = (props) => {
  return <div className={classes.background} onClick={props.onClick} />;
};

const ModalOverlay = (props) => {
  function deepClone(obj) {
    return JSON.parse(JSON.stringify(obj));
 }

  let copy_data = props.data
  for (let i = 0; i <= 6; i++) {
    copy_data.datasets[i].barPercentage = 0.2;
  }
  const [this_data, set_this_data] = useState(deepClone(copy_data))
  const [chartKey, setChartKey] = useState(0);
  const [clicked, set_clicked] = useState(1000)
  const [show_general_explanation, set_show_general_explanation] = useState(false)

  
  function round10(n) {
    return Math.round(n / 10) * 10;
  }

  function formatToPound(number) {
    return new Intl.NumberFormat('en-GB', {
        style: 'currency',
        currency: 'GBP',
        minimumFractionDigits: 0
    }).format(round10(number));
  }


  const text = [
                <Fragment key = {0}>
                  <p>The faded dark blue bar in the light pink rectangle shows the one off lump that you will receive when you partially retire, if you choose the {props.choice} scheme over the remedy 
                  period: {formatToPound(this_data.datasets[0].data[1])} </p>
                 </Fragment>, //end of fragment
                 <p key = {4}>
                 The dark blue bars on the main part of the chart outside the pink rectangle show the one-off lump sum that we project you will receive if you fully
                 retire in the relevant year, assuming you choose the {props.choice} scheme. 
                 
                 {props.no_legacy ? ' However, you are not projected to receive a lump sum at full retirement in the "choose ' + props.choice + '" scenario, because you will have used all of your legacy benefits at full retirement.' 
                 :  ' For example, if you fully retire ' + props.example_year + ' years after partial retirement, ' +
                 'we expect you will receive a one of lump sum at full retirement of ' +  formatToPound(this_data.datasets[1].data[props.example_year + 2])}.
               </p>
               ,
                  
                  <Fragment key = {2}>
                  <p>  The faded light blue bar within the pink rectangle represents the CARE pension that you would start receiving when you partially retire.</p>
                  {(props.no_legacy) ? <p> 
                    This amount is: {formatToPound(this_data.datasets[2].data[1])}
                  </p> : <p> However,  you won't receive any CARE pension at partial retirement in the 'choose {props.choice}' scenario, 
                  so there is no bar of this description in the chart.</p>}
                  </Fragment>, 
                    <Fragment key = {6}> 
                    <p >
                        The light blue bars on the main part of the chart outside the pink rectangle show the amount of CARE pension that you would start receiving from full retirement
                         if you were to fully retire in the relevant year in the 'choose {props.choice}' scenario. 
                     </p>  
                     <p>    
                         For example, if you fully retire {props.example_year} years after partial retirement, 
                        you will start receiving an annual CARE pension of {formatToPound(this_data.datasets[3].data[props.example_year + 2])}.
                    </p> 
                    <p> 
                        The total pension you receive once you fully retire is your CARE pension from full retirement, plus your legacy pension from full retirement,
                        plus the legacy pension and any CARE pension that would have already been receiving from partial retirement. All these amounts increase with inflation (assumed to be 2%) once they start being paid.
                    </p>
                    </Fragment>
                    ,//end of fragment
                    <Fragment key = {1}>
                  <p>The faded grey bar in the light pink rectangle shows the legacy pension that you would start receiving from partial retirement onwards if you choose the
                      {' '}{props.choice } scheme: {formatToPound(this_data.datasets[4].data[1])} </p>
                  <p> Once you partially retire, you will keep receiving this amount of legacy pension every year (in addition to other parts of your pension
                    and earnings), increasing in line with inflation (assumed to be 2%).</p>
                  <p> These subsequent payments of the legacy pension from partial retirement are denoted by the light grey bars that appear after the pink rectangle.</p>
                 </Fragment>, 
                 ///end of fragment
                    <Fragment> 
                 <p key = {7}>
                 The dark grey bars on the main part of the chart outside the pink rectangle show the amount of legacy pension that you would start receiving from full retirement if you
                 were to fully retire in the relevant year in the 'choose {props.choice}' scenario. 
                 </p>
                 {props.no_legacy ? 
                 <p>
                 For example, if you fully retire {props.example_year} years after partial retirement, 
                 you will start receiving an annual legacy pension of {formatToPound(this_data.datasets[5].data[props.example_year + 2])}.
                 </p>: 
                 <p> However, since you are projected to use up all your legacy pension at partial retirement in this scenario, you are not expected to receive any
                  legacy pension at full retirement.
                 </p>
                  }
                 <p> 
                 The total pension you receive once you fully retire is your CARE pension from full retirement, plus your legacy pension from full retirement,
                     plus the legacy pension and any CARE pension that would have already been receiving from partial retirement. All these amounts increase with inflation (which we have assumed to be 2%) once they start being paid.
                 </p>
             </Fragment>,
                    
                    
                  
                  
                 <p key = {5}> The green bars on the main part of the chart outside the pink rectangle show the annualised pay that we project you will be earning in the relevant
                 year after partial retirement. Annualised pay means the pay that you would receive if you were to work for the whole of the relevant financial year - if you fully
                 retire in the middle of the year, you won't earn this much because you will stop receiving earnings (and start receiving pension) as soon as you fully retire.
             </p>,/* 

                 <p key = {3}>  The faded light green bar in the pink rectangle shows the full-time-equivalent pay that we have (based on the inputs you provided) assumed 
                   you will be earning in the financial year in which your partial retirement date falls: {formatToPound(this_data.datasets[7].data[1])}
                 </p>*/


                ]
  return (
    <div className={classes2.modal}>
      <div className = {classes2.text_and_chart_container}> 
      <div className = {classes2.chart_container}
      style = {{'width' : '100%', 'height' :'70%'}}
      >
      <Bar 
      key = {chartKey}
      data = {this_data}
      options = {props.options}> </Bar>
      <div 
      className = {classes2.general_explanation + ' ' + (show_general_explanation ? (classes2.general_explanation_variables + ' ' + classes2.general_explanation_extra) : '')}
      onClick = {() => {set_show_general_explanation(prev => !prev); set_clicked(1000)}}
      > <h2>  General explanation {!show_general_explanation ? '(Click to expand)' : '(Click to shrink)'} </h2>
      <p> This chart shows projected earnings, pension and lump sum in the {props.choice} scenario. </p>
      
      {show_general_explanation && <Fragment>
      <p> 
        It shows the CARE and legacy pension that you are expected to start receiving when you partially retire, 
        as well as the CARE and legacy pension that you could start receiving annually upon full retirement, depending on when
        you fully retire - the later you fully retire, the higher your annual pension from full retirement.
      </p>

    {props.no_legacy ? 
    <p> You are expected to draw down all of your legacy benefits when you partially retire in this scenario, 
      meaning you would only receive CARE benefits from full retirement (which is why there are no black grey bars in the chart).
    </p> :
    <p>
      You are expected to draw down only legacy benefits when you partially retire in this scenario, 
      meaning you would not receive CARE benefits from partial retirement, which is why there are no light blue bars in the chart.
       </p>
  }

    <p> The pension from partial retirement (i.e., {props.no_legacy ? 'the light grey bar' : 'the light grey and light blue bars'})
       plus earnings that you would receive if you had not yet fully retired (the green bars) reflect your income during partial
       retirement. </p>
       <p> 
        Once you fully retire, you would stop receiving earnings (the green bar), but you would keep receiving your pension from partial 
        retirement ({props.no_legacy ? 'the light grey bar' : 'the light grey and light blue bars'}) and you would start receiving
        your full retirement pension (i.e., {props.no_legacy ? 'the blue bar' : 'the blue plus dark grey bars'} from whichever year you fully retire
        in).
       </p>

       </Fragment>}
      </div>
      </div>
      <div className = {classes2.text_container}>
      <h1> Click on a box to see what the colour represents in the chart. </h1>
      <div className = {classes2.text_label_container}> 
      <div className = {classes2.text_subcontainer}>
        
         <div className = {classes2.subcontainer_variable_neat + ' ' + (show_general_explanation ? classes2.remove_margin : '')}> 
        {Array.from({ length: 3 }, (_, i) => i ).map(
          (i) => {return(
        <div style = {{
          background: props.data.datasets[i].backgroundColor,
          color : ([1,5].includes(i) ? 'white': 'black'),
          '--guide' : String((2 - i) * 100 + 50) + 'px'
          
        }} 
        className = {classes2.single_explanation_neat + ' ' + ((i === clicked) ? classes2.shift_position : (clicked === 1000 ? '' : classes2.shrink))}
        key = {i}
        onClick = {(e) => {

          let temp = deepClone(copy_data)
          
            temp.datasets[i].barPercentage = 1
            set_this_data(temp)
            setChartKey(prev => prev+1)
          
          set_clicked(i)
          set_show_general_explanation(false)
          e.stopPropagation()
        
        }}
        >

          <h3> {props.datalabel_key[props.data.datasets[i].label]}</h3>
          <p> click for explanation</p>

          
        </div>)}
        )
      }
      </div>
      <div className =  {classes2.subcontainer_variable_neat + ' ' + (show_general_explanation ? classes2.remove_margin : '')}> 
        {Array.from({ length: 3 }, (_, i) => i+3 ).map(
          (i) => {return(
        <div style = {{
          background: props.data.datasets[i].backgroundColor,
          color : ([1,5].includes(i) ? 'white': 'black'),
          '--guide' : String((5 - i) * 100 + 50) + 'px'
        }} 
        className = {classes2.single_explanation_neat + ' ' + ((i === clicked) ? classes2.shift_position : (clicked === 1000 ? '' : classes2.shrink))}
        key = {i}
        onClick = {(e) => {

          let temp = deepClone(copy_data)
          
            temp.datasets[i].barPercentage = 1
            set_this_data(temp)
            setChartKey(prev => prev+1)
          
          set_clicked(i)
          set_show_general_explanation(false)
          e.stopPropagation()
        
        }}
        >

          <h3> {props.datalabel_key[props.data.datasets[i].label]}</h3>
          <p> click for explanation</p>

          
        </div>)}
        )
      }
      </div>

      <div  className = {classes2.subcontainer_variable_neat + ' ' + (show_general_explanation ? classes2.remove_margin : '')}> 

        <div style = {{
          background: props.data.datasets[6].backgroundColor,
          color : 'black',
        '--guide' :  '150px'
        }} 
        className = {classes2.single_explanation_neat + ' ' + ((6 === clicked) ? classes2.shift_position : (clicked === 1000 ? '' : classes2.shrink))}
        key = {6}
        onClick = {(e) => {

          let temp = deepClone(copy_data)
          
            temp.datasets[6].barPercentage = 1
            set_this_data(temp)
            setChartKey(prev => prev+1)
          
          set_clicked(6)
          set_show_general_explanation(false)
          e.stopPropagation()
        
        }}
        >

          <h3> {props.datalabel_key[props.data.datasets[6].label]}</h3>
          <p> click for explanation</p>

          
        </div>
      
      </div>
      
      </div>
      </div>
      {clicked < 10 &&
      <div 
      className = {classes2.full_explanation_div}
      style = {{
        background: clicked > 10 ? 'white' : props.data.datasets[clicked].backgroundColor, 
        color : ([1,5].includes(clicked) ? 'white': 'black')
      }}
      > {text[clicked]} </div>
}
      </div>
      </div>


      <button  onClick={props.onClick}
    style = {{'position' : 'fixed', 'bottom' : (show_general_explanation ? '20px' : '50px'), 'left' : '100px',
            'borderRadius' : '10px', 'width' :'400px', 'height' : '50px'
  }}
  className = {classes2.close_button_income}
      >
        close
      </button>

    </div>
  );
};

const ChartModalScenarioView = (props) => {

  
  useEffect(() => {
    const close = (event) => {
      if (event.keyCode === 27) {
        props.modalHandler();
      }
    };
    window.addEventListener("keydown", close);
    return () => window.removeEventListener("keydown", close);
  });

  return (
    <Fragment>
      {ReactDOM.createPortal(
        <ModalOverlay
          onClick={props.modalHandler}
          data = {props.data}
          options = {props.options}
          variable = {props.variable}
          choice = {props.choice}
          datalabel_key = {props.datalabel_key}
          example_year = {props.example_year}
          no_legacy = {props.no_legacy}
        />,
        document.getElementById("modal-root")
      )}
      {ReactDOM.createPortal(
        <Backdrop onClick={props.modalHandler} />,
        document.getElementById("backdrop-root")
      )}
    </Fragment>
  );
};

export default ChartModalScenarioView;