import React from "react";

import QuestionSelector from "../components/home/QuestionSelector";
import TextBlock from "../components/general/text/TextBlock";
import Footer from "../components/home/Footer";

import FrontPageQuestionSelectorText from "../assets/data/FrontPageQuestionSelectorText";
import { IntroText, SmallPrintText } from "../assets/data/TextBlocks";

function HomePage() {
  return (
    <div id="Home_Page">
      <TextBlock blocks={IntroText} />
      <QuestionSelector items={FrontPageQuestionSelectorText} />
      <TextBlock blocks={SmallPrintText} />
      <Footer />
    </div>
  );
}

export default HomePage;
