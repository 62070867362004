export const currenyFormatter = (value) => {
  return new Intl.NumberFormat("en-UK", {
    style: "currency",
    currency: "GBP",
    maximumFractionDigits: 0,
    roundingMode: "halfExpand",
  }).format(value);
};

export const capitaliseFirstLetter = (string) => {
  return string.charAt(0).toUpperCase() + string.slice(1);
};

export const sumValues = (obj) => Object.values(obj).reduce((a, b) => a + b);

export function formatDate(inputDate, version = 'long') {
  // Split the date string
  const parts = inputDate.split('-');
  const year = parts[0];
  const month = parseInt(parts[1], 10);
  const day = parseInt(parts[2], 10);

  // Determine the ordinal suffix
  let ordinal = 'th';
  if (day === 1 || (day > 20 && day % 10 === 1)) {
      ordinal = 'st';
  } else if (day === 2 || (day > 20 && day % 10 === 2)) {
      ordinal = 'nd';
  } else if (day === 3 || (day > 20 && day % 10 === 3)) {
      ordinal = 'rd';
  }

  // Map month number to month name

  const months = [
      'January', 'February', 'March', 'April', 'May', 'June',
      'July', 'August', 'September', 'October', 'November', 'December'
  ];
  const short_months = [
    'Jan', 'Feb', 'Mar', 'Apr', 'May', 'June', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'
  ]
  
  // Ensure the date is valid (this covers the leap years too)
  if (new Date(inputDate).toISOString().slice(0,10) !== inputDate) {
      throw new Error('invalid date');
  }

  return `${day}${ordinal} ${version === 'long' ? months[month - 1] : short_months[month - 1]} ${year}`;
}

export function startOfDay(date) {
  return new Date(date.setHours(0, 0, 0, 0));
}

export function addNYears(dateInput, n) {
  let date = startOfDay(new Date(dateInput)); // Ensure the input is a Date object
  let originalDay = date.getDate();
  let originalMonth = date.getMonth();

  // Temporarily set date to March 1st to avoid leap year issues with setFullYear
  if (originalMonth === 1 && originalDay === 29) {
      date.setMonth(2);
      date.setDate(1);
  }

  date.setFullYear(date.getFullYear() + n);

  // If original date was February 29 and resulting year is a leap year
  if (originalMonth === 1 && originalDay === 29 && (date.getFullYear() % 4 === 0) && (date.getFullYear() % 100 !== 0 || date.getFullYear() % 400 === 0)) {
      date.setMonth(1);
      date.setDate(29);
  } else if (originalMonth === 1 && originalDay === 29) {
      date.setMonth(1);
      date.setDate(28);
  } else {
      date.setMonth(originalMonth);
      date.setDate(originalDay);
  }


  return startOfDay(date);
}


export function sanitisePercentString(str) {
  // 1. Remove any character that is not an arabic numeral, a decimal point, or percentage
  let cleaned = str.replace(/[^0-9.%.]/g, '');

  // 2. Handle decimal points
  if (cleaned.charAt(0) === '.') { // Remove if the first character is a decimal point
      cleaned = cleaned.substring(1);
  }

  let firstDecimalPointIndex = cleaned.indexOf('.');
  if (firstDecimalPointIndex !== -1) {
      // Remove any subsequent decimal points
      cleaned = cleaned.substring(0, firstDecimalPointIndex + 1) +
                cleaned.substring(firstDecimalPointIndex + 1).replace(/\./g, '');
  }

  // 3. Handle percentage signs
  if (cleaned.charAt(cleaned.length - 1) === '%') {
      cleaned = cleaned.substring(0, cleaned.length - 1).replace(/%/g, '') + '%';
  } else {
      cleaned = cleaned.replace(/%/g, '');
  }

  // 4. Ensure no more than two characters after the decimal point
  firstDecimalPointIndex = cleaned.indexOf('.');
  if (firstDecimalPointIndex !== -1 && (firstDecimalPointIndex + 3) < cleaned.length) {
      cleaned = cleaned.substring(0, firstDecimalPointIndex + 3) +
                (cleaned.endsWith('%') ? '%' : '');
  }
  return cleaned;
}

export function processPercentDelete(x, y) {
  // Check if y is the same as x with a '%' appended
  if (y === x + '%') {
      // Return y with its penultimate character removed
      return y.slice(0, -2) + y.slice(-1);
  }
  // If the condition is not met, return x
  return x;
}

function remove_pound(x) {
  if (x.startsWith('£')) {
    return x.slice(1);
  }
  return x;
}

function removeCommas(x) {
  return x.replace(/,/g, '');
}

export function formatToPoundNoRounding(number) {
  if(String(number).endsWith('.')) {
    var suffix = '.'
  } else {
    if(String(number).endsWith('.0')) {
      var suffix = '.0'
    } else {
      var suffix = ''
    }
  }

  
  const ret = new Intl.NumberFormat('en-GB', {
    style: 'currency',
    currency: 'GBP',
    minimumFractionDigits: 0
  }).format(remove_pound(removeCommas(number)));

  
  //xxx signals that the user has not supplied an input yet
  if(String(number) === 'xxx') {
    return('')
  }
  if(isNaN(remove_pound(removeCommas(number))) || String(number) === '£0' || String(number) === '' || String(number) === '0') {
   
    return '£0'
  } else {
    return ret + suffix
  }
}

export function processAddedYears(input) {
  // Remove unwanted characters
  const cleanedInput = input.replace(/[^0-9.;]/g, '');
  
  // Split the string at the semi-colon
  const parts = cleanedInput.split(';');
  
  // Convert the parts to numbers, ensuring there is exactly one semi-colon
  if (parts.length === 2) {
      const num1 = Number(parts[0]);
      const num2 = Number(parts[1]);
      return [num1, num2];
  } else {
      throw new Error('Input must contain exactly one semi-colon.');
  }
}