import React, { useState, useEffect, useContext } from "react";
import { Link, useLocation } from "react-router-dom";
import classes from "./NavBar.module.css";
import { InputDataContext } from "../../shared/contexts/InputDataContext"; 

const NavBar = () => {
  const {from_backend} = useContext(InputDataContext);
  const location = useLocation();

  const HOME_PATHNAME = "/";
  const YOUR_DETAILS_PATHNAME = "/yourdetails";
  const RESULTS_PATHNAME = "/results";
  const TCS_PATHNAME = "/T&Cs";

  const [accessHome, setAccessHome] = useState(false);
  const [accessYourDetails, setAccessYourDetails] = useState(false);
  const [accessResults, setAccessResults] = useState(false);

  useEffect(() => {
    if (location.pathname === HOME_PATHNAME) {
      setAccessHome(true);
    }
  }, [location.pathname]);

  useEffect(() => {
    if (location.pathname === YOUR_DETAILS_PATHNAME && accessHome === true) {
      setAccessYourDetails(true);
    }
  }, [location.pathname, accessHome]);

  useEffect(() => {
    if (location.pathname === RESULTS_PATHNAME && accessHome === true) {
      setAccessResults(true);
    }
  }, [location.pathname, accessHome]);

  return (
    <nav className={classes.pageTabs}>
      <Link
        className={`${classes.pageTab} ${
          location.pathname === HOME_PATHNAME ? classes.pageTab_active : ""
        }`}
        to={HOME_PATHNAME}
      >
        Home
      </Link>
      {accessYourDetails ? (
        <Link
          className={`${classes.pageTab} ${
            location.pathname === YOUR_DETAILS_PATHNAME
              ? classes.pageTab_active
              : ""
          }`}
          to={YOUR_DETAILS_PATHNAME}
        >
          Your Details
        </Link>
      ) : (
        <p className={`${classes.pageTab} ${classes.pageTab_inactive}`}>
          Your Details
        </p>
      )}
      {accessResults ? (
        <Link
          className={`${classes.pageTab} ${
            location.pathname === RESULTS_PATHNAME ? classes.pageTab_active : ""
          }`}
          to={RESULTS_PATHNAME}
          onClick = {(e) => {
            e.preventDefault()
      
              if (from_backend.current['results populated']) {
                from_backend.current.handle_submit_function('navigate'); // execute function immediately
            } else {
                setTimeout(() => {
                  from_backend.current.handle_submit_function('navigate'); // execute the function after waiting for 500ms
                }, 450);
            }
          
          }
          }
        >
          Results 
        </Link>
      ) : (
        <p className={`${classes.pageTab} ${classes.pageTab_inactive}`}>
          Results
        </p>
      )}
      <Link
        className={`${classes.pageTab} ${
          location.pathname === TCS_PATHNAME ? classes.pageTab_active : ""
        }`}
        to={TCS_PATHNAME}
      >
        T&Cs
      </Link>
    </nav>
  );
};

export default NavBar;
