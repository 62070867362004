import React, { Fragment, useState, useEffect, useContext, useRef } from "react";
import classes from './ProgressIndicator.module.css'
import Crayon from "../../../assets/images/crayon.png"
import Tick from "../../../assets/images/tick.png"
import Cross from "../../../assets/images/cross.png"
import { formatDate } from "../../../shared/utilities/Formatters";

const answer_transformer = {
    'Special Class Officer' : 'SCO', 
    'Mental Health Officer' : 'MHO', 
    'Neither of these' : 'None'
}

const ProgressItem = (props) => {
    

    return(
        <div className = {classes.grid_item + ' ' + classes[props.validity] + ' ' + (
            (props.being_answered && props.validity !== 'error') ? classes.being_answered : ''
        )
        
        } key = {props.name}>

        
        {props.validity === 'redundant' && !props.being_answered &&
        <div className = {classes.redundant}> 
            <p> Not needed</p>
        </div> 
        }
        {props.validity !== 'redundant' &&
        <Fragment> 
            <div className = {classes.title_container}> 
                <p style = {{'fontSize' : '1rem'}}> {props.name}</p>   
                {
                props.validity === 'valid' && !props.being_answered &&
                <img 
                className = {classes.tick}
                src = {Tick} 
                alt = {
                    'tick showing that this question has been answered with a valid response'
                    }
                />
                }

                {
                props.validity === 'error' && 
                <img 
                className = {classes.cross}
                src = {Cross} 
                alt = {
                    'cross showing that this question has been answered with an invalid response'
                    }
                />
                }

            {props.being_answered && props.validity !== 'error' &&
            <img 
            className = {classes.big_crayon}
            src = {Crayon} 
            alt = {
                'Crayon item showing that this is being edited by the user'
                }
            />

            }
            </div>
            {
            (props.being_answered && props.validity !== 'error') ? 
            <p className = {classes.being_edited_p}>
                Editing...
                 </p> :

            <button
            onClick = {
                () => {
                    const hasError = Object.values(props.responses).some(value => value === 'error')
                    const all_items = Object.keys(props.responses)
                    
                    if(
                         props.validity !== 'NA' && (
                            !props.any_being_answered || hasError
                            )
                        ) {
                        const to_slide = props.idx - 1
                        
                        const new_item = all_items[to_slide]
                        props.set_nth_slide(to_slide)
                        props.responsesRef.current = [new_item, props.responses[new_item]]
                        props.current_answer.current = 'incomplete'
                    }
                }
            }
            > {(props.answer === '' | props.answer === 'xxx') ? 'Not answered' : props.answer}
                {
                    (props.validity === 'valid' || props.validity === 'error') &&
                    <img src = {Crayon} alt = {'Crayon item showing that this can be edited by the user'}/>
                }
            </button>
            }
        </Fragment>    
    }
        
      
        </div>
    )
}

const ProgressIndicator = (props) => {

    const n_renders = useRef(0)
    console.log('n_renders_pi', n_renders.current)
    n_renders.current = n_renders.current + 1

    const names = [
        'Status', 
        'D.O.B', 
        'Joining Date', 
        'Retirement Date', 
        'Current Pay', 
        'Pay in 2015', 
        'Added Years', 
        'Added Pension', 
        'Future pay', 
        'Hours', 
        'Future hours', 
        'Safe %', 
        'Benefit option', 
        'Benefit %'
    ]

    const n_remaining = 14 - Object.keys(props.responses).filter(
        key => (['valid', 'redundant'].includes(props.responses[key]) && (key !== 'undefined') )
        ).length;
 

    return(
        <div className = {classes.main_container}>
            <h1 className = {classes.title_bar}> Progress: {n_remaining} question{n_remaining === 1 ? '' : 's'} remaining 
            <button onClick = {() => {props.set_show_progress_indicator(false)}}> Hide </button>
            </h1>
            <div className = {classes.grid_container}>
                {
                [1,2,3,4,5,6,7,8,9,10,11,12, 13, 14].map(
                    (x) => {
                        const keys = Object.keys(props.responses)
                        const this_key = keys[x-1]
                        const any_being_answered = props.currently_being_answered !== ''
                        const being_answered = this_key === props.currently_being_answered
                        const validity = props.responses[this_key]
                        const name = names[x-1]
                        var answer = props.inputsRef.current[this_key]
                        if (Object.keys(answer_transformer).includes(answer)) {
                            answer = answer_transformer[answer]
                        }
                        try {
                            answer = formatDate(answer, 'short');
                        } catch (error) {
                        }

                        if(this_key === 'PT' && Array.isArray(answer)) {
                            answer = '...'
                        }

                        return(
                            <ProgressItem 
                                key = {name} 
                                validity = {validity} 
                                name = {name} 
                                answer = {answer} 
                                idx = {x}
                                set_nth_slide ={props.set_nth_slide}
                                responses = {props.responses}
                                responsesRef = {props.responsesRef}
                                current_answer = {props.current_answer}
                                being_answered = {being_answered}
                                any_being_answered = {any_being_answered}
                                />
                        )
                    }
                )
                }
                
            </div>
        </div>
    )
}

export default ProgressIndicator