import React, { createContext, useState, useRef } from "react";
import { Routes, Route } from "react-router-dom";
import Layout from "./pages/0-Layout";
import HomePage from "./pages/1-Home";
import YourDetailsPage from "./pages/2-YourDetails";
import ResultsPage from "./pages/3-Results";
import TACPage from "./pages/4-TAC";
import params from "./shared/utilities/global_parameters";
import { InputDataContext } from "./shared/contexts/InputDataContext";

export const OutputDataContext = createContext({});

function App() {
  Object.freeze(params); /* make it impossible to modify parameters' */
  const [inputs, setInputs] = useState({
    inReceipt: null,
    //legacyScheme: null,
    djs: '',
    dob: null,
    npa: null,
    salaryCurrent: null,
    partTimeService: [],
    salaryIncrease: 0,
    ['commutation percentage'] : 'None', 
    'BenefitWithdrawn' : 20, 
    'parAge' : '', 
    'HasPT' : '',
    take_safe_percentage : ''
  });

  const scrollPositions = {
    'DOB' : useRef({}), 
    'DJS' : useRef({}), 
    'DPR' : useRef({}),
    'PTStart' : useRef({}), 
    'PTEnd' : useRef({})
  }

  const inputsRef = useRef(
    {
      'Status' : '', 
      'DOB' : '', 
      'DJS' : '',
      'DPR' : '', 
      'CurrentSalary' : 'xxx', 
      'Salary2015' : 'xxx', 
      'AddedYears' : '', 
      'AddedPension' : '', 
      'FutureSalary' : 'xxx', 
      'PT' : '', 
      'PostPT' : '', 
      'SafePercentage' : '', 
      'OnlyLegacy' : '', 
      'BenefitsWithdrawn' : ''
  }
  )

  const responsesRef = useRef(
    ['Status', 'NA']
  )

  const current_answer = useRef('')

  const currently_being_answered_ref = useRef('')

  const full_responses_ref = useRef(
    {
      'Status' : 'NA', 
      'DOB' : 'NA', 
      'DJS' : 'NA',
      'DPR' : 'NA', 
      'CurrentSalary' : 'NA', 
      'Salary2015' : 'NA', 
      'AddedYears' : 'NA', 
      'AddedPension' : 'NA', 
      'FutureSalary' : 'NA', 
      'PT' : 'NA', 
      'PostPT' : 'NA', 
      'SafePercentage' : 'NA', 
      'OnlyLegacy' : 'NA', 
      'BenefitsWithdrawn' : 'NA'
  }
  )

  const from_backend = useRef({})

  const safe_percentage_ref = useRef('')

  const [valid, setValid] = useState({
    inReceipt: false,
    //legacyScheme: false,
    dob: false,
    salaryCurrent: false,
    HasPT: false
  });

  const max_ret_date = useRef(null)
  const isScrollingRef = useRef(false)

  const [outputData, setOutputData] = useState([]);
  const which_backend_trigger = useRef({})
  const show_salary_warning_ref = useRef(true)
  return (
    <InputDataContext.Provider value={{ inputs, setInputs, valid, setValid, from_backend, 
    which_backend_trigger, show_salary_warning_ref, max_ret_date, isScrollingRef, scrollPositions, 
    inputsRef, current_answer, responsesRef, full_responses_ref, 
    safe_percentage_ref, currently_being_answered_ref }}>
      <OutputDataContext.Provider value={{ outputData, setOutputData }}>
        <Layout>
          <Routes>
            <Route path="/" exact element={<HomePage />} />
            <Route path="/yourdetails" element={<YourDetailsPage />} />
            <Route path="/results" element={<ResultsPage />} />
            <Route path="/T&Cs" element={<TACPage />} />
          </Routes>
        </Layout>
      </OutputDataContext.Provider>
    </InputDataContext.Provider>
  );
}

export default App;
