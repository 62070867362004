import React from "react";

import TextBlock from "../components/general/text/TextBlock";
import { TCText } from "../assets/data/TextBlocks";

function TACPage() {
  return (
    <div id="T&Cs_Page">
      <TextBlock blocks={TCText}></TextBlock>
    </div>
  );
}

export default TACPage;
