import React, { Fragment, useState, useEffect, useContext, useRef } from "react";
import classes from './DateInput.module.css'
import InfoModal from "../../../components/results/switches/InfoModal"

import FullDateDropdown from "./FullDateDropdown"

const DateInput = (props) => {
    const [valid, set_valid] = useState(['NA'])
    const [value, set_value] = useState(props.inputsRef.current[props.tracker])
    const [show_modal, set_show_modal] = useState(false)


    const check_validity = props.check_validity ? 
                            props.check_validity : 
                            (x) => {return(['valid', ''])}

    if(props.dependencies && (check_validity(value)[0] !== valid[0]) && value !== '') {
        const is_valid = check_validity(value)
        set_valid(is_valid)


        props.set_responses(prev => {
            return(
                {...prev, [props.tracker] : is_valid[0]}
            )
        })

        console.log('setting responses ref in dateinput')
        console.log(is_valid[0])
        props.responsesRef.current = [props.tracker, is_valid[0]]

    }

    return(
        <div className = {classes.overall_container}> 
            <h3> {props.question_text}</h3>
            <div className = {classes.date_container}> 
                <FullDateDropdown
                    existing_value = {value}
                    onChange = {
                        (e) => {
                            const response = e.target.value
                            const is_valid = check_validity(response)
                            set_valid(is_valid)
                            set_value(response)
                            props.responsesRef.current = [props.tracker, is_valid[0]]
                            props.current_answer.current = response
                            if(props.set_track_dob) {
                                props.set_track_dob(response)
                            }
                            if(props.set_track_djs) {
                                props.set_track_djs(response)
                            }
                            
                            
                        }
                    }
                    scrollPositions = {props.scrollPositions[props.tracker]}
                    min_year = {props.min_year}
                    max_year = {props.max_year}
                    go_red = { valid[0] === 'error'}
                    set_currently_being_answered = {
                        () => props.set_currently_being_answered(props.tracker)
                    }
                />
            </div>
           
            <div className = {classes.explain_container}> 
        {props.modal_content && <button
            onClick = {() => {set_show_modal(true)}}
            > Explain what this question means
            </button>
        }
        {show_modal && 
         <InfoModal
         modalHandler = {() => set_show_modal(false)}
         infoMessage = {props.modal_content}
         />
         }
         </div>
         {valid[0] ==='error' && <p className = {classes.error}> {valid[1]} </p>}
        </div>
    )
}

export default DateInput