import React, { Fragment, useState, useEffect, useContext, useRef } from "react";

import ProgressIndicator from "../components/yourdetails/inputs/ProgressIndicator"
import QuestionSlider from "../components/yourdetails/inputs/QuestionSlider";

import { InputDataContext } from "../shared/contexts/InputDataContext";
import classes from "./2-YourDetails.module.css"



function YourDetailsPage() {
  const { from_backend, 
    show_salary_warning_ref, max_ret_date, isScrollingRef, scrollPositions, inputsRef,
     current_answer, responsesRef, full_responses_ref, safe_percentage_ref, 
     currently_being_answered_ref
    } = useContext(InputDataContext);
  const [nth_slide, set_nth_slide] = useState(0)
  const [show_progress_indicator, set_show_progress_indicator] = useState(true)

  const [currently_being_answered, set_currently_being_answered] = useState(
    currently_being_answered_ref.current
  )
  

  const scrollTimeout = useRef(null)
  

  const [responses, set_responses] = useState(
    full_responses_ref.current
    ) 

  useEffect(
    () => {full_responses_ref.current = responses}, [responses]
  )
 
  return (
    <div id="Your_Details_Page"
    style = {{
      '--extra-height' : (show_progress_indicator ? '0px' : '200px')
    }}>
      
      <div 
      className={classes.progress_indicator_container +  ' ' + (show_progress_indicator ? '' : classes.invisible)}>
        <ProgressIndicator
        responses = {responses}
        inputsRef = {inputsRef}
        set_nth_slide = {set_nth_slide}
        responsesRef = {responsesRef}
        current_answer = {current_answer}
        currently_being_answered = {currently_being_answered}
        set_currently_being_answered = {set_currently_being_answered}
        set_show_progress_indicator = {set_show_progress_indicator}
        />
      </div>
      <button 
      onClick = {() => {set_show_progress_indicator(true)}}
      className={classes.progress_indicator_button +  ' ' + (show_progress_indicator ? classes.invisible : '')}
      > Show progress indicator </button>

       <QuestionSlider  
       scrollPositions = {scrollPositions}
       responses = {responses}
       set_responses = {set_responses}
       responsesRef = {responsesRef}
       inputsRef = {inputsRef}
       set_nth_slide = {set_nth_slide}
       nth_slide = {nth_slide}
       current_answer = {current_answer}
       from_backend = {from_backend}
       safe_percentage_ref = {safe_percentage_ref}
       currently_being_answered = {currently_being_answered}
       set_currently_being_answered = {set_currently_being_answered}
       />

      
    </div> 
  );
}


export default YourDetailsPage;
