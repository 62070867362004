import React from "react";
import { Link } from "react-router-dom";
import classes from "./PageButton.module.css";

const PageButton = (props) => {
  return (
    <Link to={props.link}>
      <button className={classes.pagebutton} id={props.id}>
        {props.text}
      </button>
    </Link>
  );
};

export default PageButton;
