import React, { useState, useEffect, useRef } from 'react';
import classes from './DateDropdown.module.css'


const DateDropdown = (props) => {
  const [expanded, setExpanded] = useState(false);
  const scrollableElementRef = useRef(null);
  const value = props.value

  const main_color = props.go_red ? 'lightcoral' : (['Year', 'Month', 'Day'].includes(value) ? 'none' : 'var(--main-color)')

  const shadow_color = props.go_red ? 'red' : (['Year', 'Month', 'Day'].includes(value) ? 'var(--legacy-curtailed)' : 'none')
  

  const preventBodyScroll = (event) => {
    // Check if the target is inside the scrollableRef element
    if (!scrollableElementRef.current.contains(event.target)) {
      event.preventDefault();
    }
  };

  useEffect(() => {
    if (expanded) {
      // Add event listener to document to prevent scrolling outside the scrollableRef
      document.addEventListener('wheel', preventBodyScroll, { passive: false });
      document.addEventListener('touchmove', preventBodyScroll, { passive: false });
    } else {
      // Remove the event listener when scrolling should be enabled
      document.removeEventListener('wheel', preventBodyScroll, { passive: false });
      document.removeEventListener('touchmove', preventBodyScroll, { passive: false });
    }

    // Cleanup function to remove event listener
    return () => {
      document.removeEventListener('wheel', preventBodyScroll, { passive: false });
      document.removeEventListener('touchmove', preventBodyScroll, { passive: false });
    };
  }, [expanded]); 



  const handleDropdownClick = (e) => {
    setExpanded(!expanded);
  };

  const handleOptionClick = (x) => {
    if(expanded) {
      props.set_value(x);
      setExpanded(false);
    }
  };

  // Close the dropdown when clicking outside of it
  useEffect(() => {
    const handleOutsideClick = (event) => {

        setExpanded(false);

    };

    if (expanded) {
      document.addEventListener('click', handleOutsideClick);
    }

    return () => {
      document.removeEventListener('click', handleOutsideClick);
    };
  }, [expanded]);


  useEffect(() => {
    const scrollableElement = scrollableElementRef.current;

    // Function to update the scroll position
    const handleScroll = () => {
 
      
      props.scrollPositions.current[props.name] = scrollableElement.scrollTop;

    };

    // Add scroll event listener
    scrollableElement.addEventListener('scroll', handleScroll);

    // Remove event listener on cleanup
    return () => {
      scrollableElement.removeEventListener('scroll', handleScroll);
    };
  }, []);

  useEffect(() => {
    // Set the scroll position when the component mounts
    const scrollableElement = scrollableElementRef.current;
    if (scrollableElement && expanded) {
      if(props.scrollPositions.current[props.name]) {
          scrollableElement.scrollTop = props.scrollPositions.current[props.name];
      }
    }
  }, [expanded]);

  return (
    <div className = {classes.dropdown_container}>
      {!expanded && <p> {value} </p>}
    <div 
    className={classes["dropdown-el"] + ' ' + (expanded ? classes.expanded : '')} 
    onClick={handleDropdownClick} 
    ref = {scrollableElementRef}
    style = {{'--color' : main_color, '--shadow-color' : shadow_color}}
    >
        <div className = {classes.sub_container + ' ' + (expanded ? classes.visible : classes.invisible)}>

          {props.options.map((option, index) => (
            <div key={option} 
            style = {{pointerEvents : (expanded ? 'all' : 'none')}}
            onClick={
              (e) => {
                if(expanded) {
                handleOptionClick(option)
                }
              }
              }>
              <input
                type="radio"
                name="sortType"
                value={option}
                checked={value === option}
                readOnly
              />
              <label>{option}</label>
              
            </div>
          ))}
          
        </div>
        
    </div>
    
    </div>
  );
};

export default DateDropdown;
